import React, { memo, useState, useEffect } from "react";
import { RangeSliderProps } from "../../../helpers/types";

const RangeSlider = memo(
  ({
    classes,
    id,
    label,
    onChange,
    value,
    name,
    sliderVal,
    setSliderVal,
    ...sliderProps
  }: RangeSliderProps) => {
    const [mouseState, setMouseState] = useState(null);

    useEffect(() => {
      setSliderVal(value);
    }, [value]);

    const changeCallback = (e) => {
      setSliderVal(e.target.value);
    };

    useEffect(() => {
      if (mouseState === "up") {
        onChange(sliderVal);
      }
    }, [mouseState]);
    return (
      <div>
        <input
          type="range"
          value={sliderVal}
          name={name}
          {...sliderProps}
          className={`slider ${classes}`}
          id={id}
          onChange={changeCallback}
          onMouseDown={() => setMouseState(null)}
          onMouseUp={() => setMouseState(null)}
        />
      </div>
    );
  },
);

export default RangeSlider;
