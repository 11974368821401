import React from "react";
import InKindFFinner from "./InKindFFinner";
import InKindTiers from "./InKindTiers";
import StatyTuned from "./StatyTuned";
import SoldOut from "./SoldOut";
import GiftTabs from "./GiftTabs";
import BusinessLink from "./BusinessLink";

const inKindHousePanel = ({
  tb,
  mobile = false,
  second = false,
  businessUrl = "",
}) => {
  let [friendsAndFamily, setFriendsAndFamily] = React.useState(
    tb.open_amount_options,
  );
  let [ffOptions, setFFoptions] = React.useState(tb.open_amount_options);
  const gift_options = tb.standard_gcos?.length > 0 ? true : false;
  let [gift, setGift] = React.useState(
    gift_options && tb.gcos.length === 0 && !friendsAndFamily,
  );
  const utm_values = location.search;
  const isPass = window.location.host.split(".")[0] === "pass";
  const isGive = window.location.host.split(".")[0] === "give";
  const isSoldOut =
    (tb.gcos.length === 0 && tb.standard_gcos === 0) || !tb.raising;
  const isUshg = tb.project_id === 716;
  const params = new URLSearchParams(window.location.search);
  const isCampaignSlug = params.has("code");
  return tb.planning ? (
    <StatyTuned mobile={mobile} second={second} projectId={tb.project_id} />
  ) : !!isSoldOut ? (
    <SoldOut mobile={mobile} projectId={tb.project_id} second={second} />
  ) : (
    <div
      className={`${
        mobile ? "panel-mobile" : !!isPass ? "" : "panel-desktop"
      } ${
        second && !mobile
          ? "second-offer-container  modal-padding"
          : "right-panel"
      } ${friendsAndFamily ? "inkind-gift" : ""}`}
    >
      {gift_options && tb.gcos.length > 0 && !friendsAndFamily && (
        <GiftTabs gift={gift} setGift={setGift} />
      )}
      <div
        className="panel-wrap"
        style={{
          color: tb.tier_text_color,
          paddingBottom:
            !!isGive || !!isPass || !!isSoldOut || !!isUshg ? "34px" : "0px",
        }}
      >
        {friendsAndFamily ? (
          <InKindFFinner
            tb={tb}
            setFriendsAndFamily={setFriendsAndFamily}
            second={second}
            utm_values={utm_values}
          />
        ) : (
          <InKindTiers
            tb={tb}
            setFriendsAndFamily={setFriendsAndFamily}
            second={second}
            utm_values={utm_values}
            gift={gift}
            ffOptions={ffOptions}
          />
        )}
        {!isCampaignSlug && !isPass && !isGive && !isSoldOut && !isUshg ? (
          <BusinessLink businessUrl={businessUrl} />
        ) : (
          <div style={{ paddingBottom: "16px" }} />
        )}
      </div>
    </div>
  );
};

export default inKindHousePanel;
