import React from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

const RadioField = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid #e7e7e7;
  padding: 10px 10px;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  box-shadow: 0 0 2px transparent;

  &.checked {
    border-color: var(--color-blue);
    border-width: 2px;
    box-shadow: 0 0 2px var(--color-blue);
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + .label:before,
  [type="radio"]:not(:checked) + .label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 22px;
    height: 22px;
    border: 2px solid var(--color-medium-gray);
    border-radius: 100%;
    background: #fff;
    transform: translateY(-50%);
  }
  [type="radio"]:checked + .label:after,
  [type="radio"]:not(:checked) + .label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: var(--color-blue);
    position: absolute;
    top: 50%;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(-50%);
  }
  [type="radio"]:not(:checked) + .label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: translateY(-50%) scale(0);
  }
  [type="radio"]:checked + .label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: translateY(-50%) scale(1);
  }
  [type="radio"]:checked + .label:before {
    border-color: var(--color-blue);
  }

  strong {
    white-space: nowrap;
  }

  .label {
    padding: 0;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 20px;
    display: block;
    color: var(--color-dark-gray);
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0;
    white-space: normal;
    text-align: left;
    width: 100%;
  }
`;

const FormRadio = ({
  field,
  form: { touched, errors },
  title,
  className,
  labelClassName,
  fieldClassName,
  inValidClassName,
  errorClassName,
  checked,
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];

  return (
    <>
      <RadioField className={classNames({ checked: checked == true })}>
        <input
          type="radio"
          {...checked}
          {...field}
          {...props}
          checked={checked == true}
        />
        <div className={classNames(labelClassName, "label")}>{title}</div>
        {isError && <div className={errorClassName}>{errors[field.name]}</div>}
      </RadioField>
    </>
  );
};

FormRadio.defaultProps = {
  fieldClassName: "form-control",
  inValidClassName: "is-invalid",
  errorClassName: "invalid-feedback",
};

export default FormRadio;
