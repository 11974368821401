import { configureStore } from "@reduxjs/toolkit";
import partnerReducer, { partnerApi } from "./partnerSlice";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    partner: partnerReducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }).concat(partnerApi.middleware),
});

export type IRootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
