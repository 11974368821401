import React from "react";
import GreenTick from "../../../../assets/images/green-tick.png";
import { useSelector } from "react-redux";
import GetOurApp from "./GetOurApp";
import SetPassword from "./SetPassword";
import numeral from "numeral";
import InKindPassLogo from "../../../../assets/images/icons/inkind-pass-logo.svg";

const Confirmation = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useSelector((state) => state.nav);
  const ikPass = useSelector((state) => state.nav.ikPass);
  const ikPassSingle = useSelector((state) => state.nav.ikPassSingle);
  const isSubscription = useSelector((state) => state.nav.subscription);
  const curSubscriptionTier = useSelector(
    (state) => state.nav.curSubscriptionTier,
  );
  const path = !!ikPassSingle ? "transaction" : "purchase";
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const userInfo = user.user && JSON.parse(user.user);
  const [needsPassword, setNeedsPassword] = React.useState(
    nav.purchaseResponse.new_user || false,
  );
  const credit_to_give = !!isSubscription
    ? null
    : !ikPass
      ? numeral(nav.purchaseResponse[path].credit_to_give).format("$0,0[.]00")
      : numeral(nav.purchaseResponse.amount_credited).format("$0,0[.]00");
  const purchase_amount = !!isSubscription
    ? numeral(nav?.purchaseResponse?.purchase.amount_to_charge).format(
        "$0,0[.]00",
      )
    : !ikPass
      ? numeral(
          nav.purchaseResponse[path].amount ??
            nav.purchaseResponse[path].amount_to_charge,
        ).format("$0,0[.]00")
      : numeral(nav.purchaseResponse.amount_charged).format("$0,0[.]00");

  const credit_balance = numeral(nav.purchaseResponse.credit_balance).format(
    "$0,0[.]00",
  );

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const bonus_to_keep =
    nav.purchaseResponse.keep_gift_bonus &&
    numeral(
      nav.purchaseResponse[path].credit_to_give -
        nav.purchaseResponse[path].amount_to_charge,
    ).format("$0,0[.]00");

  const keep_bonus_amount = nav?.purchaseResponse?.keep_gift_bonus == "true";
  return (
    nav.purchaseResponse && (
      <div className="confirmation-col">
        <div className="inner-col">
          <div className="checkout-head">Purchase Successful</div>

          <div className="confirmation-left checkout-base-font">
            <img src={GreenTick} className="greentick" alt="√" />
            <div>
              {!!isSubscription ? (
                <span>
                  <span className="font-bold">{purchase_amount}</span>{" "}
                  {nav?.purchaseResponse?.purchase?.billing_interval ===
                  "monthly"
                    ? "Monthly"
                    : "Yearly"}{" "}
                  Subscription
                </span>
              ) : keep_bonus_amount ? (
                <span>
                  Gift <span className="font-bold">{purchase_amount}</span>,
                  Keep <span className="font-bold">{bonus_to_keep}</span> to{" "}
                  {company.presenter.project_name || "yourself"}
                </span>
              ) : purchase_amount === credit_to_give ? (
                <span className="font-bold">
                  {credit_to_give}
                  {!!ikPass ? " Charged" : " E-Gift Card"}
                </span>
              ) : (
                <span>
                  Pay <span className="font-bold">{purchase_amount}</span>
                  {!!ikPass && "/mo"}, {nav.gift ? "Gift" : "Get"}{" "}
                  <span className="font-bold">{credit_to_give}</span>
                  {!!ikPass && "/mo"} to{" "}
                  {!!ikPassSingle
                    ? "the inKind network"
                    : !!company?.presenter?.display_map_siblings
                      ? company.presenter.project_name
                      : company.presenter.brand_name}
                </span>
              )}
            </div>
          </div>
          <div className=" sent-to checkout-base-font">
            {!!ikPass && (
              <div style={{ paddingBottom: "16px" }}>
                You will be billed monthly on the{" "}
                {nav?.purchaseResponse?.monthly_billing_day}
                {nth(Number(nav?.purchaseResponse?.monthly_billing_day))} of the
                month.
              </div>
            )}
            A confirmation has been sent to your email{" "}
            <span className=" font-bold">
              {userInfo
                ? userInfo.email
                : !!ikPassSingle
                  ? nav.purchaseResponse.user_email
                  : !!ikPass
                    ? nav.purchaseResponse.user
                    : nav.purchaseResponse.user_email}
            </span>
            {keep_bonus_amount && (
              <>
                <br />
                <br />
                <span>
                  Your
                  <span className="font-bold">{bonus_to_keep} bonus</span> has
                  been added to your account.
                </span>
              </>
            )}
          </div>
          {!!isSubscription ? null : (
            <div className="mobile-confirmation checkout-mobile  ">
              <div className="flexer">
                <div className="confirm-grid">
                  {company.presenter.project_logo_white_path ? (
                    <img
                      className="confirm-mobile-img"
                      src={`${company.presenter.cloudinary_path}/h_100/${company.presenter.project_logo_white_path}`}
                    />
                  ) : company.presenter.is_inkind_cash ? (
                    <img
                      src={InKindPassLogo}
                      className="disclosure-inkind-logo"
                    />
                  ) : null}

                  <div
                    className={
                      company.presenter.project_logo_white_path ||
                      company.presenter.is_inkind_cash
                        ? "span-col"
                        : "span-col-full"
                    }
                  >
                    <div className="font-bold">
                      {!!ikPassSingle
                        ? "inKind Pass"
                        : !!company?.presenter?.display_map_siblings
                          ? company.presenter.project_name
                          : company.presenter.brand_name}
                    </div>
                    <span>
                      {!nav.gift ? (
                        <span>
                          New balance:{" "}
                          <span className="font-bold">{credit_balance}</span>
                        </span>
                      ) : (
                        <span>
                          Gifted Amount:{" "}
                          <span className="font-bold">
                            {keep_bonus_amount
                              ? purchase_amount
                              : credit_to_give}
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {needsPassword ? (
          <SetPassword
            purchaseResponse={nav.purchaseResponse}
            setNeedsPassword={setNeedsPassword}
          />
        ) : (
          <GetOurApp />
        )}
      </div>
    )
  );
};

export default Confirmation;
