import React from "react";
import Circle from "./images/splash-circle.svg";

export const Splash = ({ logoSrc }) => {
  return (
    <div className="splash">
      <img
        src={
          logoSrc ||
          "https://res-2.cloudinary.com/equityeats-test/image/upload/c_fit,h_300,w_300/v1617225005/uj8xav5nliosa0jjmkzf.png"
        }
        alt="restaurant logo"
        className="splash__restaurant-logo"
      />
      <div className="splash__inkind-logo">
        Payment by
        <div className="splash__inkind-logo-img" />
      </div>
      <div className="splash__circle">
        <img src={Circle} alt="circle" />
      </div>
    </div>
  );
};
