import React from "react";

interface MetricCardProps {
  children: React.ReactNode;
  classes?: string;
}

const MetricCard = ({ classes, children }: MetricCardProps) => {
  return <div className={`ambassador-metric-card ${classes}`}>{children}</div>;
};

export default MetricCard;
