import React from "react";
import { css } from "@emotion/css";
import FieldWrapper from "@/shared/forms/field-wrapper";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const CardForm = ({ error }) => {
  const stripe = useStripe();
  const elements = useElements();
  return (
    <FieldWrapper
      label="Card"
      error={error}
      className={css`
        .StripeElement {
          border-bottom: 0;
          flex: 1;
          padding: 0;
        }
      `}
    >
      <CardElement />
    </FieldWrapper>
  );
};

export default CardForm;
