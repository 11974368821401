import React from "react";
import giftsIcon from "../../../../assets/images/icons/gifts-icon.svg";
import rightChevronBlack from "../../../../assets/images/icons/right-chevron-blk.svg";

const BusinessLink = ({ businessUrl }) => {
  return (
    <a href={businessUrl} className="business-page-link">
      <div className="business-link-image-container">
        <img src={giftsIcon} alt="Sending gifts to several people?" />
      </div>
      <div className="business-link-text">
        <div className="font-bold">Sending gifts to several people?</div>
        Send gifts to clients, employees and more!
      </div>
      <img
        src={rightChevronBlack}
        className="right-arrow"
        alt="Get more details"
      />
    </a>
  );
};

export default BusinessLink;
