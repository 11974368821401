import React, { Suspense } from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import Confirmation from "./components/Confirmation";
import { setCompany, setPR } from "./redux/slices/companySlice";
import { fetchUserByToken } from "./redux/slices/userSlice";
import {
  setUTMData,
  setIkPass,
  setIkPassSingle,
  setSubscription,
  setShowToast,
} from "./redux/slices/navSlice";
import { MinusIcon } from "@heroicons/react/20/solid";
import numeral from "../../../../vendor/assets/javascripts/numeral";
const ConfirmEmailToast = React.lazy(
  () => import("../new-login-create-account/ConfirmEmailToast.jsx"),
);
const GiftPage2 = React.lazy(() => import("./components/GiftPage2"));
const Cart = React.lazy(() => import("./components/Cart"));
const Subscription = React.lazy(() => import("./components/Subscription"));
const RightPanel = React.lazy(() => import("./components/RightPanel"));
const Payment = React.lazy(() => import("./components/Payment"));
const RestaurantGuide = React.lazy(
  () => import("./components/RestaurantGuide"),
);

const App = ({ presenter }) => {
  const utm_values = queryString.parse(location.search);
  const userSubscription = useSelector((state) => state.user.subscription);
  const user = useSelector((state) => state.user);
  const subscriptionStatus = user.subscription_status;
  const isFreemiumSubscriber = user.is_freemium_subscriber;
  const pageStatus = useSelector((state) => state.nav.pageStatus);
  const dispatch = useDispatch();
  const bonusCredit = numeral(presenter.bonus_credit).format("$0,0[.]00");
  const [isAgreeAllChecked, setIsAgreeAllChecked] = React.useState(false);
  const nav = useSelector((state) => state.nav);
  const isSubscription = useSelector((state) => state.nav.subscription);
  const showToast = useSelector((state) => state.nav.showToast);
  const isCurSubscriptionTier =
    Object.keys(useSelector((state) => state.nav.curSubscriptionTier)).length >
    0;

  const handleShowToast = (val) => {
    dispatch(setShowToast(val));
  };

  React.useEffect(() => {
    dispatch(setIkPass(!!presenter.name));
    dispatch(
      setIkPassSingle(
        window.location.hostname.split(".")[0] === "pass" && !presenter.name,
      ),
    );
    dispatch(
      setSubscription(window.location.pathname === "/checkout/subscribe"),
    );
    dispatch(setCompany(presenter));
    dispatch(setUTMData(JSON.stringify(utm_values)));
    dispatch(fetchUserByToken());
  }, []);

  return (
    <Suspense
      fallback={
        <div className="marcus-mode ">
          <MinusIcon className="loading-spinner" />
        </div>
      }
    >
      <div className="checkout-parent">
        {showToast ? (
          <ConfirmEmailToast setShowToast={handleShowToast} />
        ) : null}
        <div className="contain marcus-mode ">
          <div className="checkout-grid ">
            <div>
              <div className="checkout-left-column">
                {pageStatus !== "confirmation" && !!isSubscription ? (
                  <Subscription />
                ) : null}
                <div
                  style={{
                    display:
                      !!isSubscription &&
                      (!isCurSubscriptionTier ||
                        (!!user.subscription &&
                          !isFreemiumSubscriber &&
                          subscriptionStatus !== "canceled" &&
                          subscriptionStatus !== "canceling"))
                        ? "none"
                        : "block",
                  }}
                >
                  {pageStatus === "checkout" ? (
                    <Cart
                      isAgreeAllChecked={isAgreeAllChecked}
                      setIsAgreeAllChecked={setIsAgreeAllChecked}
                    />
                  ) : pageStatus === "payment" ? (
                    <Payment />
                  ) : pageStatus === "gift_page_2" ? (
                    <GiftPage2
                      bonusCredit={bonusCredit}
                      isAgreeAllChecked={isAgreeAllChecked}
                      setIsAgreeAllChecked={setIsAgreeAllChecked}
                    />
                  ) : pageStatus === "confirmation" ? (
                    <Confirmation />
                  ) : (
                    ""
                  )}
                </div>

                {presenter.is_inkind_cash &&
                  pageStatus === "confirmation" &&
                  presenter.country_code === "US" && (
                    <RestaurantGuide mobile={true} />
                  )}
              </div>
            </div>
            <div className="checkout-desktop right-panel-container">
              <RightPanel />
              {presenter.is_inkind_cash &&
                pageStatus === "confirmation" &&
                presenter.country_code === "US" && (
                  <RestaurantGuide mobile={false} />
                )}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default App;
