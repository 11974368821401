import React from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/icons/x_close.png";
import useCookie from "@lib/use-cookie";
import { update } from "lodash-es";

const PrivacyChoices = () => {
  const [privacyControlOn, setPrivacyControlOn] = React.useState(
    !!navigator?.globalPrivacyControl,
  );
  const [privacyOptOut, setPrivacyOptOut] = useCookie("ik_pcoo", "0");

  const handlePost = (path, func, head, body) => {
    const config = { method: "POST" };
    if (!!body) {
      config.body = body;
    }
    if (!!head) {
      config.headers = head;
    } else {
      config.headers = { "Content-Type": "application/json" };
      if (!!body) {
        config.body = JSON.stringify(body);
      }
    }
    fetch(path, config)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (!!func) func(data);
      })
      .catch((err) => {});
  };
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setPrivacyOptOut("1", 365);
    handlePost("/api/v5/do_not_track", null, null, data);
  };

  const [loggedIn, setLoggedIn] = React.useState(
    document.cookie.includes("userToken="),
  );
  const [usStates, setUsStates] = React.useState({
    name: "United States",
    states: [
      {
        name: "Alabama",
        state_code: "AL",
      },
      {
        name: "Alaska",
        state_code: "AK",
      },
      {
        name: "American Samoa",
        state_code: "AS",
      },
      {
        name: "Arizona",
        state_code: "AZ",
      },
      {
        name: "Arkansas",
        state_code: "AR",
      },
      {
        name: "Baker Island",
        state_code: "UM-81",
      },
      {
        name: "California",
        state_code: "CA",
      },
      {
        name: "Colorado",
        state_code: "CO",
      },
      {
        name: "Connecticut",
        state_code: "CT",
      },
      {
        name: "Delaware",
        state_code: "DE",
      },
      {
        name: "District of Columbia",
        state_code: "DC",
      },
      {
        name: "Florida",
        state_code: "FL",
      },
      {
        name: "Georgia",
        state_code: "GA",
      },
      {
        name: "Guam",
        state_code: "GU",
      },
      {
        name: "Hawaii",
        state_code: "HI",
      },
      {
        name: "Howland Island",
        state_code: "UM-84",
      },
      {
        name: "Idaho",
        state_code: "ID",
      },
      {
        name: "Illinois",
        state_code: "IL",
      },
      {
        name: "Indiana",
        state_code: "IN",
      },
      {
        name: "Iowa",
        state_code: "IA",
      },
      {
        name: "Jarvis Island",
        state_code: "UM-86",
      },
      {
        name: "Johnston Atoll",
        state_code: "UM-67",
      },
      {
        name: "Kansas",
        state_code: "KS",
      },
      {
        name: "Kentucky",
        state_code: "KY",
      },
      {
        name: "Kingman Reef",
        state_code: "UM-89",
      },
      {
        name: "Louisiana",
        state_code: "LA",
      },
      {
        name: "Maine",
        state_code: "ME",
      },
      {
        name: "Maryland",
        state_code: "MD",
      },
      {
        name: "Massachusetts",
        state_code: "MA",
      },
      {
        name: "Michigan",
        state_code: "MI",
      },
      {
        name: "Midway Atoll",
        state_code: "UM-71",
      },
      {
        name: "Minnesota",
        state_code: "MN",
      },
      {
        name: "Mississippi",
        state_code: "MS",
      },
      {
        name: "Missouri",
        state_code: "MO",
      },
      {
        name: "Montana",
        state_code: "MT",
      },
      {
        name: "Navassa Island",
        state_code: "UM-76",
      },
      {
        name: "Nebraska",
        state_code: "NE",
      },
      {
        name: "Nevada",
        state_code: "NV",
      },
      {
        name: "New Hampshire",
        state_code: "NH",
      },
      {
        name: "New Jersey",
        state_code: "NJ",
      },
      {
        name: "New Mexico",
        state_code: "NM",
      },
      {
        name: "New York",
        state_code: "NY",
      },
      {
        name: "North Carolina",
        state_code: "NC",
      },
      {
        name: "North Dakota",
        state_code: "ND",
      },
      {
        name: "Northern Mariana Islands",
        state_code: "MP",
      },
      {
        name: "Ohio",
        state_code: "OH",
      },
      {
        name: "Oklahoma",
        state_code: "OK",
      },
      {
        name: "Oregon",
        state_code: "OR",
      },
      {
        name: "Palmyra Atoll",
        state_code: "UM-95",
      },
      {
        name: "Pennsylvania",
        state_code: "PA",
      },
      {
        name: "Puerto Rico",
        state_code: "PR",
      },
      {
        name: "Rhode Island",
        state_code: "RI",
      },
      {
        name: "South Carolina",
        state_code: "SC",
      },
      {
        name: "South Dakota",
        state_code: "SD",
      },
      {
        name: "Tennessee",
        state_code: "TN",
      },
      {
        name: "Texas",
        state_code: "TX",
      },
      {
        name: "United States Minor Outlying Islands",
        state_code: "UM",
      },
      {
        name: "United States Virgin Islands",
        state_code: "VI",
      },
      {
        name: "Utah",
        state_code: "UT",
      },
      {
        name: "Vermont",
        state_code: "VT",
      },
      {
        name: "Virginia",
        state_code: "VA",
      },
      {
        name: "Wake Island",
        state_code: "UM-79",
      },
      {
        name: "Washington",
        state_code: "WA",
      },
      {
        name: "West Virginia",
        state_code: "WV",
      },
      {
        name: "Wisconsin",
        state_code: "WI",
      },
      {
        name: "Wyoming",
        state_code: "WY",
      },
    ],
  });

  React.useEffect(() => {
    let modalBg = document.querySelector(".privacy--outer");
    let modalClose = document.querySelector(".privacy--close");
    let modalSubmitClose = document.querySelector(".privacy-submit");
    modalClose.addEventListener("click", function () {
      parent.postMessage("closeIframe", "*");
    });
    modalSubmitClose.addEventListener("click", function () {
      parent.postMessage("closeIframe", "*");
    });
    modalBg.addEventListener("click", function (e) {
      if (
        !e.target.closest(".privacy--container") &&
        !e.target.classList.contains(".privacy--container")
      ) {
        parent.postMessage("closeIframe", "*");
      }
    });
  }, []);
  return (
    <div className="privacy--outer">
      <div className="privacy--container">
        <h2 className="privacy--header privacy--border">
          <span>Your Privacy Choices</span>
          <img src={CloseIcon} className="privacy--close" alt="Close button" />
        </h2>
        <div
          className="privacy--button privacy--button__not-detected"
          style={{
            display:
              !privacyControlOn && privacyOptOut !== "1" ? "block" : "none",
          }}
        >
          GPC signal not detected
        </div>
        <div
          className="privacy--button privacy--button__detected"
          style={{
            display:
              !!privacyControlOn || privacyOptOut === "1" ? "block" : "none",
          }}
        >
          {privacyControlOn
            ? "GPC signal detected"
            : privacyOptOut === "1"
              ? "OPT-OUT signal detected"
              : "OPT-OUT signal not detected"}
        </div>
        <div className="privacy--section">
          <div className="privacy--section--subhead">
            1. HOW INKIND USES YOUR INFORMATION
          </div>
          <div className="privacy--section--body">
            inKind uses your personal information in accordance with our{" "}
            <a
              className="privacy-links"
              target="_blank"
              href="https://inkind.com/terms"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              className="privacy-links"
              target="_blank"
              href="https://inkind.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            to service the needs of your account and help you use our platform.
            As defined in our Privacy Policy, we do not sell your information.
            The CCPA defines sharing as providing personal information to a
            third party for cross-context behavioral advertising. Opting out of
            tracking is limited to sharing of information in this context.
            However, in order to service your account and support your dining
            experience, we do, as a matter of course, exchange personal
            information with various partners (e.g. for payment processing) in
            accordance with our Privacy Policy. Our services would not work
            without sharing of this kind, and in each case the transfer and use
            of your information is limited to what is required to perform the
            service we specifically requested.
          </div>
          <div>
            If you would still like us to not share your information in any of
            these instances, the best recourse is to submit a request to delete
            your account so that we can safely purge any of your personal
            information from our servers. You can do this by clicking “Delete
            Account” in the Settings section of your inKind app or by emailing
            support@inkind.com with “Delete Account” in the subject. In the
            event of any issue with your opt-out choices or questions about
            privacy, please contact inKind at support@inkind.com.
          </div>
        </div>
        <div className="privacy--section">
          <div className="privacy--section--subhead">
            2. DO NOT SELL OR SHARE MY PERSONAL INFORMATION
          </div>
          <div className="privacy--section--body">
            If you would like to opt out of the sale/sharing of your personal
            information, please submit the form below. If you are currently
            logged in, we will honor your choice to opt-out across any other
            browsers once you are logged in. Otherwise, if you are not logged in
            the scope of this choice will be limited to use of your current
            browser. Note that we can only honor this choice for future visits
            if your browser is set to accept cookies. Also if you clear cookies
            from the cache in your browser, your “Do Not Sell or Share”
            preference will be erased, and you’ll need to resubmit this form to
            re-enable it or log back in if your preference is associated with
            your account.
          </div>
        </div>
        <div className="privacy--section">
          <div className="privacy--section--subhead">
            3. OPT-OUT PREFERENCE SIGNAL (GLOBAL PRIVACY CONTROL)
          </div>
          <div className="privacy--section--body">
            You may use an Opt-Out Preference Signal, such as the Global Privacy
            Control (GPC), to opt-out of the sale/sharing of your personal
            information. If you would like us to make the connection between
            your browser and your account, and you have not yet opted out of
            sale/sharing your personal information, please login to your account
            and submit the form below.
          </div>
        </div>
        <div className="privacy--border"></div>
        <div
          className="privacy--section"
          style={{
            display: !!loggedIn && !!privacyControlOn ? "none" : "flex",
          }}
        >
          <div className="privacy--section--body">
            Please provide the following details:
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="privacy-section--form-grid"
          >
            <div className="field privacy--floating-label-wrapper outline">
              <select
                placeholder=" "
                className="form-control privacy--floating-label-input"
                name="state"
              >
                {usStates.states.map((state) => {
                  return (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor="state"
                className="required privacy--floating-label"
              >
                State
              </label>
            </div>
            <div className="privacy--hide-on-small"></div>
            <div
              className="privacy--hide-on-small"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            ></div>
            <div
              className="field privacy--floating-label-wrapper outline"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            >
              <input
                placeholder=" "
                className="form-control privacy--floating-label-input"
                name="first_name"
                {...register("first_name", { required: true })}
              />
              <label
                htmlFor="first_name"
                className="required privacy--floating-label"
              >
                First Name
              </label>
            </div>
            <div
              className="field privacy--floating-label-wrapper outline"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            >
              <input
                placeholder=" "
                className="form-control privacy--floating-label-input"
                name="last_name"
                {...register("last_name", { required: true })}
              />
              <label
                htmlFor="last_name"
                className="required privacy--floating-label"
              >
                Last Name
              </label>
            </div>
            <div
              className="field privacy--floating-label-wrapper outline"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            >
              <input
                placeholder=" "
                className="form-control privacy--floating-label-input"
                name="email"
                {...register("email", { required: true })}
              />
              <label
                htmlFor="email"
                className="required privacy--floating-label"
              >
                Email Address
              </label>
            </div>
            <div
              className="privacy--hide-on-small"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            ></div>
            <div
              className="privacy--hide-on-small"
              style={{
                display: !!loggedIn ? "none" : "flex",
              }}
            ></div>
            <button
              className="privacy--submit-button privacy-submit"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PrivacyChoices;
