import React from "react";
import AddImage from "../../ui/molecules/AddImage";

const ChangeRequest = ({
  setSentState,
  logMessage,
  setLogMessage,
  images,
  setImages,
  cloudinary,
  attachLength,
  setAttachLength,
  isSending,
  finishedState,
}) => {
  const [imagesLength, setImagesLength] = React.useState(0);

  const handleSetImages = (data) => {
    setImagesLength(data.length);
    setImages(data);
  };

  return (
    <div className="email__review--section">
      <div
        className="email__review--subheader"
        style={{ display: !!finishedState ? "none" : "block" }}
      >
        What changes would you like to see?
      </div>
      <textarea
        className="email__review--textarea"
        aria-label="What changes would you like to see?"
        placeholder="Tell us more..."
        value={logMessage}
        onChange={(e) => setLogMessage(e.target.value)}
      />
      {[...Array(attachLength)].map((e, i) => {
        return (
          <AddImage
            cloudinary={cloudinary}
            values={{}}
            setFieldValue={handleSetImages}
            key={i}
            isMultipleArr={images}
          />
        );
      })}
      <div className="email__review--inner--columns">
        <div
          className="email__review--subtext--link"
          onClick={() => setAttachLength(attachLength + 1)}
        >
          + Add another image
        </div>
        <button
          className="email__review--button--send"
          onClick={() => {
            setSentState(true);
            setImagesLength(0);
          }}
          disabled={
            !!isSending || (logMessage?.length === 0 && imagesLength === 0)
              ? true
              : false
          }
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChangeRequest;
