import React, { useState } from "react";
import axios from "@lib/axios";
import classNames from "classnames";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Formik, Form, Field } from "formik";
import FullButton from "@/shared/forms/form-fullButton";
import ContentWrapper from "@/shared/content-wrapper";
import HeaderContent from "@/shared/header-content";
import ScrollContent from "@/shared/scroll-content";
import FixedContent from "@/shared/fixed-content";
import FormInput from "@/shared/forms/form-input";
import Loader from "@/shared/loader";
import ConditionalRender from "@/shared/conditional-render";
import HelpText from "@/shared/help-text";
import Icon from "@/shared/icon";
import MessageWrapper from "@/shared/message-wrapper";
import mediaQuery from "@lib/mediaQueries";

const logo = css`
  display: block;
  max-width: 95px;
  height: auto;
  margin: 2rem auto 3.25rem;
`;

const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;

  h3 {
    font-size: 1.625rem;
    margin-top: 3.25rem;
  }
`;

const CheckoutForgotPassword = ({ onComplete, onGoBack }) => {
  const [complete, setComplete] = useState(false);

  return (
    <>
      <Formik
        validateOnChange={true}
        validateOnMount={true}
        initialValues={{ email: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          return errors;
        }}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setFieldTouched },
        ) => {
          try {
            const res = await axios.post("/api/v1/users/forgot_password", {
              user: {
                email: values.email,
              },
            });

            setComplete(true);
            setSubmitting(false);
          } catch (error) {
            setFieldTouched("email");
            setFieldError(
              "email",
              "No account record found, please verify your spelling.",
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <ContentWrapper>
              <HeaderContent>
                <h1>Reset your password</h1>
              </HeaderContent>
              <ScrollContent>
                <ConditionalRender shouldRender={complete}>
                  <MessageWrapper>
                    <Icon
                      name="circle-checkmark"
                      color="#03C095"
                      size="80"
                      className={css`
                        margin-bottom: 22px;
                      `}
                    />
                    <h3>Check Your Email!</h3>
                    <p>We've sent you a link to reset your password.</p>
                  </MessageWrapper>
                </ConditionalRender>

                <ConditionalRender shouldRender={!complete}>
                  <p
                    className={css`
                      margin-bottom: 2rem !important;

                      ${mediaQuery.md} {
                        margin-bottom: 5rem !important;
                      }
                    `}
                  >
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.
                  </p>
                  <Field name="email" title={"Email"} component={FormInput} />
                  <button
                    type="button"
                    className={classNames(
                      css`
                        color: #7b7b7b;
                      `,
                      "transparent",
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      onGoBack();
                    }}
                  >
                    <Icon
                      name="arrow-left-2"
                      color="currentColor"
                      className={css`
                        margin-right: 12px;
                      `}
                    />{" "}
                    Return to sign in
                  </button>
                </ConditionalRender>
              </ScrollContent>
              <FixedContent>
                {!complete ? (
                  <FullButton type="submit" disabled={!isValid}>
                    Reset Password
                  </FullButton>
                ) : (
                  <FullButton
                    onClick={(e) => {
                      e.preventDefault();
                      onGoBack();
                    }}
                  >
                    Return to login
                  </FullButton>
                )}
              </FixedContent>
            </ContentWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CheckoutForgotPassword;
