import React, { useState, useEffect, useMemo } from "react";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import axios from "@lib/axios";
import { InKindNetworkError } from "../helpers/InKindNetworkError";
import useDeviceId from "../components/events/useDeviceId.tsx";
import useSessionId from "../components/events/useSessionId.tsx";

const StripeHelpers = ({ axiosHeaders, project, ticket, posSlug }) => {
  const deviceId = useDeviceId();
  const sessionId = useSessionId();
  const [reqHeaders, setReqHeaders] = useState(axiosHeaders);
  const [proj, setProj] = useState(project);
  const [tick, setTick] = useState(ticket);
  const [pos, setPos] = useState(posSlug);
  const stripe = useStripe();

  useEffect(() => {
    axiosHeaders.headers["X-InKind-Session"] = sessionId;
    axiosHeaders.headers["X-Inkind-Device"] = deviceId;
  }, [sessionId, deviceId, axiosHeaders]);

  const createCardToken = async (cardElement) => {
    const cardToken = await stripe.createToken(cardElement);
    return cardToken;
  };

  // save the token to backend
  const saveCard = async (tokenId) => {
    try {
      const newCard = await axios.post(
        "/api/v3/current_user/stripe_sources",
        {
          stripe_token: tokenId,
        },
        axiosHeaders,
      );
      return newCard.data;
    } catch (error) {
      return { error };
    }
  };

  const prepareStripeCard = async ({
    values,
    field,
    setFieldTouched,
    setFieldError,
    cardElement,
  }) => {
    let cardId = false,
      card = false;
    const cardErrors = [];

    if (cardElement) {
      // we're creating a new card
      const cardToken = await createCardToken(cardElement);

      if (cardToken.error) {
        cardErrors.push(cardToken.error);
      } else if (cardToken) {
        const last4 = cardToken.token.card.last4;
        const result = await saveCard(cardToken.token.id);
        if (result?.error) cardErrors.push(result.error);
        if (result?.credit_cards?.length > 0) {
          for (let x = result.credit_cards.length - 1; x >= 0; x--) {
            if (result.credit_cards[x].last_four === last4) {
              card = result.credit_cards[x];
              break;
            }
          }
          if (card) {
            cardId = card?.id;
          }
        }
      }
    } else {
      // Using an existing card
      cardId = values[field] ? values[field].value : false;
    }

    if (cardErrors.length > 0) {
      setFieldTouched(field, true);
      setFieldError(field, cardErrors[0].message);
    }

    return {
      card,
      cardId,
      cardErrors,
    };
  };

  const sendPayment = async ({
    total,
    tip,
    cardId,
    charge_stripe_source_id,
    offer_claim_id = null,
  }) => {
    if (!proj || !tick) {
      return {
        payment: false,
        paymentErrors: "Missing project or ticket",
      };
    }

    let params = {
      transaction: {
        amount: total,
        charge_stripe_source_id: charge_stripe_source_id,
        partial_payment: tick.bill_amount_to_leave_unpaid > 0.0,
        offer_claim_id,
      },
      project: proj.project_slug,
      ticket_number: tick.id,
      location_id:
        proj.location_type === "ProjectLocation" ? proj.location_id : null,
    };

    if (!!pos) params.pos = pos;

    params.tip =
      cardId || tip > 0
        ? {
            amount: tip > 0 ? tip : 0,
            stripe_source_id: cardId,
          }
        : null;

    try {
      const payment = await axios.post(
        `/api/v3/current_user/credits`,
        params,
        axiosHeaders,
      );

      return {
        payment: payment.data,
        paymentErrors: false,
      };
    } catch (error) {
      return {
        payment: false,
        paymentErrors: error,
      };
    }
  };

  const purchaseGiftCard = async ({ cardId, optionId }) => {
    if (!cardId || !optionId) {
      return {
        payment: false,
        paymentErrors: "Missing card or option ID.",
      };
    }

    try {
      const payment = await axios.post(
        `/api/v2/gift_card_purchases.json`,
        {
          gift_card_purchase: {
            gift_card_option_id: optionId,
            stripe_source_id: cardId,
            agrees_with_tos_privacy: "on",
          },
        },
        axiosHeaders,
      );

      return {
        payment,
        paymentErrors: false,
      };
    } catch (error) {
      return {
        payment: false,
        paymentErrors: error,
      };
    }
  };

  return {
    prepareStripeCard,
    sendPayment,
    purchaseGiftCard,
  };
};

export default StripeHelpers;
