import React from "react";
import { css } from "@emotion/css";

const FooterNoAccount = ({ purchase_page }) => {
  return (
    <div
      className={css`
        font-size: 18px;
        color: var(--color-dark-gray);
      `}
    >
      Don't have a House Account?{" "}
      <a
        href={purchase_page || "#"}
        target="_blank"
        className={css`
          color: var(--color-blue);
          font-weight: 700;
        `}
      >
        Purchase Here
      </a>
    </div>
  );
};

export default FooterNoAccount;
