import { useState, useEffect } from "react";

type ColorScheme = "light" | "dark";

const useColorScheme = (): ColorScheme => {
  const getColorScheme = () => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return "dark";
    }
    return "light";
  };

  const [colorScheme, setColorScheme] = useState<ColorScheme>(getColorScheme());

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)",
    );

    const handleChange = () => {
      setColorScheme(darkModeMediaQuery.matches ? "dark" : "light");
    };

    darkModeMediaQuery.addEventListener("change", handleChange);

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return colorScheme;
};

export default useColorScheme;
