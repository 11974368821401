import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { connect, getIn } from "formik";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  background-color: var(--color-red);
  color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const ServerErrors = ({ formik, name, className }) => {
  const error = getIn(formik.errors, name);
  if (error === undefined) return null;

  return (
    <Wrapper className={classNames(className, "mb-4")}>
      {Array.isArray(error) ? (
        <ul className="list-unstyled m-0 p-0">
          {error.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      ) : (
        <span>{error}</span>
      )}
    </Wrapper>
  );
};

ServerErrors.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
const ServerErrorsWithFormik = connect(ServerErrors);

export default ServerErrorsWithFormik;
