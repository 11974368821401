import React, { useRef, useEffect } from "react";
import { ChevronLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { formatPhoneNumber } from "@lib/helpers";

export const AltVerification = ({
  checkVerificationStatus,
  smsNumber,
  goBack,
}) => {
  const startTime = useRef();
  const intervalRef = useRef();

  const displaySmsNumber = formatPhoneNumber(smsNumber);

  // Poll the backend to see if the user texted us to verify
  useEffect(() => {
    startTime.current = Date.now();
    intervalRef.current = setInterval(() => {
      checkVerificationStatus?.();

      // If we've been polling for more than 30mins then stop
      if (startTime.current && Date.now() - startTime.current > 1800000) {
        clearInterval(intervalRef.current);
      }
    }, 3000);

    // Stop polling on unmount
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className="alternative-verification">
      <div className="verification-content">
        <ChevronLeftIcon
          className="arrow-left"
          fontSize={16}
          onClick={goBack}
        />
        <div className="header">Let's try something else.</div>
        <div className="subheader">
          <span className="highlight">Don’t worry! </span>We’ll get you
          verified.
        </div>
        <div className="verify-text">
          Verify your phone number by texting the word{" "}
          <span className="highlight">VERIFY</span> to&nbsp;
          <span className="highlight">{displaySmsNumber}</span>
        </div>
        <a
          className="action-button"
          href={"sms:" + smsNumber + "?&body=VERIFY"}
        >
          <span>
            Tap to text <span className="highlight">VERIFY</span> to us
          </span>
          <ArrowRightIcon className="chevron" />
        </a>
      </div>
    </div>
  );
};

export default AltVerification;
