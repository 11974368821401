import React, { Suspense, lazy, useMemo, useState } from "react";
import { Splash } from "./Splash";
import { loadStripe } from "@stripe/stripe-js";
import {
  Location,
  LocationData,
  PaymentCard,
  PaymentResponse,
  ProjectData,
  TicketData,
} from "./type";
import { ViewOrder } from "./ViewOrder";
import { Success } from "./Success";
import { Receipt } from "./Receipt";
import { Elements } from "@stripe/react-stripe-js";
const Checkout = lazy(() => import("./Checkout"));

export enum Stage {
  Checkout,
  Success,
  Receipt,
}

interface SingleBillProps {
  posSlug: string;
  location: string;
  projectInit?: ProjectData;
  ticketInit?: TicketData;
  locationInit?: LocationData[];
  ticket: string;
  locationsCount: number;
}

const SingleBill: React.FC<SingleBillProps> = ({
  projectInit,
  locationInit,
  ticketInit,
  ticket,
  locationsCount,
  posSlug,
  location,
}) => {
  const [showViewOrder, setShowViewOrder] = useState(false);
  const [stage, setStage] = useState<Stage>(Stage.Checkout);
  const [cardInfo, setCardInfo] = useState<PaymentCard>({} as PaymentCard);
  const [paymentResponse, setPaymentResponse] = useState<PaymentResponse>(
    {} as PaymentResponse,
  );
  const stripePublicKey = projectInit?.stripe_public_key;

  const stripePromise = useMemo(() => {
    if (!stripePublicKey) return Promise.resolve(null);
    return loadStripe(stripePublicKey);
  }, [stripePublicKey]);

  const {
    custom_tip_percent0,
    custom_tip_percent1,
    custom_tip_percent2,
    custom_tip_percent3,
  } = locationInit?.[0] || {};
  const logoUrl = projectInit?.logo_path;

  return (
    <div className={showViewOrder ? "overflow-hidden" : ""}>
      {stage === Stage.Checkout && (
        <Suspense fallback={<Splash logoSrc={logoUrl} />}>
          <Elements stripe={stripePromise}>
            <Checkout
              ticketData={ticketInit || ({} as TicketData)}
              projectData={projectInit || ({} as ProjectData)}
              locationData={{
                tippingEnabled: !!locationInit?.[0]?.checkout?.tipping_enabled,
                tipOptions: [
                  custom_tip_percent0,
                  custom_tip_percent1,
                  custom_tip_percent2,
                  custom_tip_percent3,
                ],
                locationsCount,
              }}
              showViewOrder={() => setShowViewOrder(true)}
              onSuccess={() => setStage(Stage.Success)}
              setCardInfo={setCardInfo}
              setPaymentResponse={setPaymentResponse}
              ticketCode={ticket}
              posSlug={posSlug}
              locationId={location}
            />
          </Elements>
          <div className={`view-order-wrapper ${showViewOrder ? "show" : ""}`}>
            <ViewOrder
              ticketData={ticketInit || ({} as TicketData)}
              hideViewOrder={() => setShowViewOrder(false)}
            />
          </div>
        </Suspense>
      )}
      {stage === Stage.Success && (
        <Success
          goToReceipt={() => setStage(Stage.Receipt)}
          rewardsAmount={paymentResponse?.subscription_credit_back?.amount || 0}
          isOptIn={paymentResponse?.opt_in_inkind}
          locationsCount={locationsCount}
        />
      )}
      {stage === Stage.Receipt && (
        <Receipt
          backToSuccess={() => setStage(Stage.Success)}
          cardInfo={cardInfo}
          paymentResponse={paymentResponse}
          lineItems={ticketInit?.line_items || []}
          locationInfo={{
            name: locationInit?.[0]?.name || "",
            location: locationInit?.[0]?.location || ({} as Location),
          }}
          ticketData={ticketInit || ({} as TicketData)}
        />
      )}
    </div>
  );
};

export default SingleBill;
