import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";
import { signOut } from "../services/auth";
import useCookie from "@lib/use-cookie";
import { logOutUser } from "../redux/slices/userSlice";
import { setSelectedList, setLoggedOut } from "../redux/slices/massGiftSlice";
import { useDispatch } from "react-redux";
const MobileNavButton = () => {
  let [showNav, setShowNav] = React.useState(false);
  const { pathname } = useLocation();
  const [cookie, updateCookie] = useCookie("userToken", null);
  const dispatch = useDispatch();
  async function logOut() {
    dispatch(logOutUser());
    dispatch(setSelectedList(null));
    dispatch(setLoggedOut());
    updateCookie("", -365);
    dispatch(savedListApi.util.resetApiState());
    clearUserToken();
    await signOut();
  }
  React.useEffect(() => {
    setShowNav(false);
  }, [pathname]);

  return (
    <>
      <button onClick={() => setShowNav(!showNav)}>
        <svg
          width="16px"
          height="13px"
          viewBox="0 0 16 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Checkout-screens_V4"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Checkout-Mobile-02"
              transform="translate(-342.000000, -22.000000)"
            >
              <rect fill="#FFFFFF" x="0" y="0" width="375" height="1600"></rect>
              <g
                id="icons/existing/star"
                transform="translate(340.000000, 19.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
                <path
                  d="M3.75,13.3333333 L11.25,13.3333333 C11.9403559,13.3333333 12.5,13.8929774 12.5,14.5833333 C12.5,15.2736893 11.9403559,15.8333333 11.25,15.8333333 L3.75,15.8333333 C3.05964406,15.8333333 2.5,15.2736893 2.5,14.5833333 C2.5,13.8929774 3.05964406,13.3333333 3.75,13.3333333 Z M3.75,8.33333333 L16.25,8.33333333 C16.9403559,8.33333333 17.5,8.8929774 17.5,9.58333333 C17.5,10.2736893 16.9403559,10.8333333 16.25,10.8333333 L3.75,10.8333333 C3.05964406,10.8333333 2.5,10.2736893 2.5,9.58333333 C2.5,8.8929774 3.05964406,8.33333333 3.75,8.33333333 Z M3.75,3.33333333 L16.25,3.33333333 C16.9403559,3.33333333 17.5,3.8929774 17.5,4.58333333 C17.5,5.27368927 16.9403559,5.83333333 16.25,5.83333333 L3.75,5.83333333 C3.05964406,5.83333333 2.5,5.27368927 2.5,4.58333333 C2.5,3.8929774 3.05964406,3.33333333 3.75,3.33333333 Z"
                  id="Combined-Shape"
                  fill="#000000"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
      {showNav && (
        <div className="mobile-nav-panel-wrapper">
          <div className="exit-nav-row">
            <button onClick={() => setShowNav(false)}>
              <XCircleIcon className="xcircle" />
            </button>
          </div>
          <div className="nav-link-column">
            <NavLink to="/">
              <span className={`${pathname === "/" ? "active" : ""} `}>
                Create Gift
              </span>
            </NavLink>
            <NavLink to="/dashboard">
              <span
                className={` ${pathname === "/dashboard" ? "active" : ""} `}
              >
                Dashboard
              </span>
            </NavLink>
            <button>
              <span onClick={logOut}>Logout</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNavButton;
