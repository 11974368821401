import React, { useState } from "react";
import { MobileLinkProps } from "../../../helpers/types.ts";

const MobileLink = ({ ikstate, demo_append }: MobileLinkProps) => {
  const [inkindStateAppStoreUrl, setInkindStateAppStoreUrl] = useState<string>(
    ikstate?.enterprise?.app_store_url,
  );
  const [inkindStatePlayStoreUrl, setInkindStatePlayStoreUrl] =
    useState<string>(ikstate?.enterprise?.play_store_url);
  const apple =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  const android = navigator.userAgent.toLowerCase().indexOf("android") > -1;

  return (
    <>
      <br />
      <a
        className="email-verification-btn"
        href={`${ikstate?.enterprise?.app_id}${demo_append}://`}
        style={{ display: apple ? "" : android ? "" : "none" }}
      >
        Open {ikstate?.enterprise?.name} app
      </a>
      <br />
      <a
        className="email-verification-btn"
        href={
          !apple && !!android
            ? inkindStatePlayStoreUrl
            : !!apple && !android
              ? inkindStateAppStoreUrl
              : ""
        }
        target="_blank"
        style={{ display: apple ? "" : android ? "" : "none" }}
      >
        Download {ikstate?.enterprise?.name}
      </a>
    </>
  );
};

export default MobileLink;
