import React from "react";
import fetch from "isomorphic-fetch";
import BrandTile from "./BrandTile";

const App = ({ cloudinaryPath }) => {
  //state
  let [userLocation, setUserLocation] = React.useState(null);
  let [locations, setLocations] = React.useState([]);
  let [brands, setBrands] = React.useState(null);
  let [isReady, setIsReady] = React.useState(false);
  let [brandsForSlider, setBrandsForSlider] = React.useState([]);

  //React effects
  React.useEffect(() => {
    handleInit();
  }, []);

  React.useEffect(() => {
    if (!!locations && !!brands && !!userLocation) {
      handleBuild();
    }
  }, [locations, brands, userLocation]);

  const handleGetMapData = () => {
    fetch("https://app.inkind.com/api/v5/map")
      .then((response) => response.json())
      .then((data) => handleSuccess(data))
      .catch((err) => console.log(err));
  };

  const handleBrowserLocation = () => {
    if (
      !userLocation ||
      !userLocation.location ||
      userLocation?.location?.length === 0
    ) {
      let location = {};
      navigator.geolocation.getCurrentPosition(
        (position) => {
          location.location = [
            position.coords.latitude,
            position.coords.longitude,
          ];
          setUserLocation(location);
        },
        (error) => {
          setUserLocation("no location found");
        },
      );
    }
  };
  //fetch user data and set up listeners
  const handleInit = () => {
    let cheapRuler = document.createElement("script");
    cheapRuler.async = true;
    cheapRuler.src = "https://unpkg.com/cheap-ruler@3.0.1/cheap-ruler.min.js";

    let body = document.getElementsByTagName("body")[0];
    body.appendChild(cheapRuler, body);
    cheapRuler.onload = () => {
      let url = window.location.hostname.includes("localhost")
        ? "http://app.localhost:3000/"
        : window.location.hostname.includes("inkind-staging")
          ? "https://app.inkind-staging.com/"
          : "https://app.inkind.com/";
      fetch(url + "api/v4/ip_location")
        .then((response) => response.json())
        .then((ip) => {
          if (ip?.location?.length > 0) {
            setUserLocation(ip);
            handleGetMapData();
          } else {
            setUserLocation(null);
            handleBrowserLocation();
            handleGetMapData();
          }
        })
        .catch((err) => {
          setUserLocation(null);
          handleBrowserLocation();
          handleGetMapData();
        });
    };
  };

  const getDistance = (lng, lat) => {
    const ruler = new CheapRuler(userLocation.location[0] ?? 30.26, "miles");
    const distance = ruler.distance(
      [
        Math.round(userLocation.location[1] * 10) / 10,
        Math.round(userLocation.location[0] * 10) / 10,
      ],
      [Math.round(lng * 10) / 10, Math.round(lat * 10) / 10],
    );
    const rounded =
      distance > 100 ? Math.round(distance) : Math.round(distance * 10) / 10;
    return rounded;
    return null;
  };

  const getNearbyBrands = () => {
    const brandNumList = [];
    if (!!userLocation && userLocation !== "no location found") {
      //get nearby brands
      const closeLocs = [];
      for (let i = 0; i < locations.length; i++) {
        let useDistance = getDistance(
          locations[i].location?.longitude,
          locations[i].location?.latitude,
        );
        locations[i].distance = useDistance;
        locations[i].latlng =
          String(locations[i].location?.latitude) +
          String(locations[i].location?.longitude);
        if (useDistance < 150) {
          closeLocs.push(locations[i]);
        }
      }

      //sort nearby brands by rr
      const sortedLocs = closeLocs.sort((a, b) => a.rr - b.rr);
      if (sortedLocs.length === 0) {
        return brandNumList;
      }

      //add nearby brands to list, unless duplicate brand or list length > 25
      for (let i = 0; i < sortedLocs.length; i++) {
        if (brandNumList.length >= 25) {
          break;
        } else if (brandNumList.includes(sortedLocs[i].brand_id)) {
          continue;
        } else {
          brandNumList.push(sortedLocs[i].brand_id);
        }
      }
    }
    return brandNumList;
  };

  const handleBuild = () => {
    const brandNumArr = getNearbyBrands();
    //fill out list with default brands if applicable
    const brandLogosArr = [];
    const defaultBrandsArr = [
      59, 1, 688, 508, 425, 745, 653, 395, 5, 853, 614, 676, 555, 567, 505, 521,
      605, 336, 499, 663, 725, 21, 420, 795, 316, 28,
    ];
    while (brandNumArr.length < 25) {
      const brandToAdd = defaultBrandsArr.splice(0, 1)[0];
      brandNumArr.push(brandToAdd);
    }
    brandNumArr.forEach((brandNum) => {
      brandLogosArr.push(brands[brandNum]?.branding);
    });
    setBrandsForSlider(brandLogosArr);
    setIsReady(true);
  };

  //deal with brand/group/location data returned
  const handleSuccess = (data) => {
    let brandsList = {};
    for (let i = 0; i < data.brands.length; i++) {
      brandsList[data.brands[i].brand_id] = data.brands[i];
    }
    setBrands(brandsList);
    setLocations(data.locations);
  };

  //ui
  return (
    <div
      className="slide-block react-slide-block"
      style={{ display: !!isReady ? "block" : "none" }}
    >
      <div className="animation">
        {brandsForSlider.map((brand, i) => {
          return (
            <BrandTile brand={brand} cloudinaryPath={cloudinaryPath} key={i} />
          );
        })}
        {brandsForSlider.map((brand, i) => {
          return (
            <BrandTile
              brand={brand}
              cloudinaryPath={cloudinaryPath}
              key={`${i}-2`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default App;
