import React from "react";
import classNames from "classnames";
import { css } from "@emotion/css";

const helptextStyle = css`
  display: block;
  margin-top: 1.5rem;
  font-size: 0.75rem;
  color: var(--color-dark-gray);
  text-align: center;

  a {
    color: var(--color-blue);
    font-family: var(--font-bold);
  }
`;

const HelpText = ({ children, className, onClick }) => {
  return (
    <div className={classNames(helptextStyle, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default HelpText;
