import React from "react";
import FooterDesktop from "../2fa-user-flow/desktop/FooterDesktop";
import MetricCard from "../molecules/MetricCard";
import EarningsChart from "../organisms/EarningsChart";
import ReachChart from "../organisms/ReachChart";
import MemberIcon from "../../../../assets/images/icons/member-icon.svg";
import LeftArrow from "../../../../assets/images/icons/leftarrow.svg";
import ProfilePicture from "../molecules/ProfilePicture/ProfilePicture";
import PhoneMenu from "../ambassador-web-discovery/PhoneMenu";

const AmbassadorUserProfile = ({ ambassadorStats, user, locationsCount }) => {
  const redirectTo = () => {
    let redirect = (window.location.href = "/");
    return redirect;
  };
  return (
    <>
      <div className="ambassador-header-container">
        <div className="title-container">
          <h2 className="center ambassador-back-button">
            <button className="back-button" onClick={redirectTo}>
              <img src={LeftArrow} alt="back arrow" /> Back
            </button>
          </h2>
        </div>
        <PhoneMenu
          setStep={() => console.log("step")}
          step={1}
          profilePage={true}
          locationsCount={locationsCount}
        />
      </div>
      <div
        style={{ position: "relative" }}
        className="ambassador-web-profile-parent"
      >
        <main className="ambassador-main">
          <div className="ambassador-info">
            <ProfilePicture profilePicture={user.profilePicture} />
            <p className="ambassador-name">{user.name}</p>
            <p className="ambassador-email">{user.email}</p>
            <p className="ambassador-member-date">
              <span className="ambassador-member-icon">
                <img src={MemberIcon} alt="Member since icon" />
              </span>
              Member since {user.memberSince}
            </p>
          </div>
          <div className="ambassador-charts">
            <EarningsChart earnings={ambassadorStats.earnings} />
            <ReachChart reach={ambassadorStats.reach} />
          </div>
          <div className="ambassador-metrics">
            <MetricCard>
              <div className="ambassador-metric-value">
                {ambassadorStats.brandLinks}
              </div>
              <div className="ambassador-metric-title">Brand Links</div>
            </MetricCard>

            <MetricCard>
              <div className="ambassador-metric-value">
                {ambassadorStats.totalClaims}
              </div>
              <div className="ambassador-metric-title">Claims</div>
            </MetricCard>

            <MetricCard>
              <div className="ambassador-metric-value">
                {ambassadorStats.totalRedemptions}
              </div>
              <div className="ambassador-metric-title">Redemptions</div>
            </MetricCard>

            <MetricCard>
              <div className="ambassador-metric-value-credit">
                {ambassadorStats.totalCreditsEarned}
              </div>
              <div className="ambassador-metric-title">Credit Earned</div>
            </MetricCard>
          </div>
        </main>
        <footer></footer>
        <FooterDesktop />
      </div>
    </>
  );
};

export default AmbassadorUserProfile;
