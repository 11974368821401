import * as React from "react";
import ReactApexChart from "react-apexcharts";

const ReachChart = ({ reach }) => {
  const [series, setSeries] = React.useState(reach.stats);
  return (
    <div className="reach-chart">
      <ReactApexChart
        options={{
          colors: ["#5DBAE6", "#B7D957", "#1276D6"],
          chart: {
            background: "#fff",
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
            position: "top",
            offsetX: 250,
            offsetY: -15,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
            },
          },
          xaxis: {
            categories: reach.assessedPeriodInMonths,
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Reach",
            margin: 40,
            floating: true,
          },
        }}
        series={series}
        type="bar"
        height="326px"
        width="100%"
      />
    </div>
  );
};

export default ReachChart;
