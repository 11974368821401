import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { cleanClassNames } from "../../../helpers/classNames";
import "./style.scss";

export interface FormikTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  icon?: React.ReactNode;
  className?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
}

const FormikTextInput: React.FC<FormikTextInputProps> = ({
  name,
  label,
  icon,
  className,
  inputRef,
  ...restProps
}) => {
  return (
    <div className={cleanClassNames("ui-formik-text-input", className)}>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <>
            <div className="ui-formik-text-input__input-container">
              <input
                {...field}
                {...restProps}
                id={name}
                ref={inputRef}
                className={cleanClassNames(
                  "ui-formik-text-input__input",
                  form.errors[name] && form.touched[name]
                    ? "ui-formik-text-input__input--error"
                    : "",
                  !!field.value || field.value === 0 ? "has-value" : "",
                  icon ? "has-icon" : "",
                )}
              />
              <label htmlFor={name} className="ui-formik-text-input__label">
                {label}
              </label>
              {icon && (
                <span className="ui-formik-text-input__icon">{icon}</span>
              )}
            </div>
            <ErrorMessage
              name={name}
              component="div"
              className="ui-formik-text-input__error"
            />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormikTextInput;
