import React, { InputHTMLAttributes, useId } from "react";
import { Field } from "formik";
import "./style.scss";
import { cleanClassNames } from "../../../helpers/classNames";

export interface FormikRadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
}

export const FormikRadioButton: React.FC<FormikRadioButtonProps> = ({
  label,
  className,
  ...props
}) => {
  const id = useId();

  return (
    <div className={cleanClassNames("radio-button__container", className)}>
      <Field name={props.name}>
        {({ field }: { field: any }) => (
          <input
            type="radio"
            {...field}
            {...props}
            className="radio-button__input"
            id={id}
            checked={field.value === props.value}
          />
        )}
      </Field>
      {label && (
        <label htmlFor={id} className="radio-button__label">
          <span className="radio-button__custom"></span>
          <span>{label}</span>
        </label>
      )}
    </div>
  );
};
