import { useState, useEffect } from "react";

export enum DeviceType {
  Apple,
  Android,
  Unknow,
}

function useDeviceType() {
  const [deviceType, setDeviceType] = useState(DeviceType.Unknow);
  const isApple = deviceType === DeviceType.Apple;
  const isAndroid = deviceType === DeviceType.Android;

  useEffect(() => {
    const detectDevice = () => {
      const userAgent = navigator.userAgent;
      if (
        /iPad|iPhone|iPod/.test(userAgent) ||
        (userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        return DeviceType.Apple;
      } else if (/android/i.test(userAgent)) {
        return DeviceType.Android;
      } else {
        return DeviceType.Unknow;
      }
    };

    setDeviceType(detectDevice());
  }, []);

  return { isApple, isAndroid };
}

export default useDeviceType;
