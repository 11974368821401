export const handleAmexLoad = async (token, runWhenDone) => {
  const env = location.hostname.endsWith(".inkind.com") ? "production" : "qa";
  // BEGIN: Remove Amex... to bring back, add "@kunaico/amex-perks-sdk": "^3.1.4" back to package.json
  window.location.href =
    env === "qa"
      ? "https://qwww.americanexpress.com/en-us/benefits/perks/inkind/offer"
      : "https://www.americanexpress.com/en-us/benefits/perks/inkind/offer";
  return;
  // END: Remove Amex
  /*
  const { getCampaignUuid, init, validateUser, refreshAccessToken } =
    await import("@kunaico/amex-perks-sdk");
  const campaignUuid = getCampaignUuid();
  const ico = new URLSearchParams(window.location.search).get("ico_id");
  if (
    (!!ico &&
      ((env === "production" && ico !== "426") ||
        (env === "qa" && ico !== "145"))) ||
    !campaignUuid
  ) {
    window.location.href =
      env === "qa"
        ? "https://qwww.americanexpress.com/en-us/benefits/perks/inkind/offer"
        : "https://www.americanexpress.com/en-us/benefits/perks/inkind/offer";
  } else {
    const config = {
      mode: env,
      campaignUuid: campaignUuid,
      merchantApiKey: token,
    };
    init(config);
    // validates the user
    // will redirect the user to the cobranded url if the user is invalid.
    validateUser().then((response) => {
      !!runWhenDone ? runWhenDone(true) : null;
      refreshAccessToken();
    });
  }
  */
};

const handleFormatCard = (card) => {
  switch (card) {
    case "American Express":
      return "AMEX";
    case "Discover":
      return "DISCOVER";
    case "MasterCard":
      return "MASTERCARD";
    case "Visa":
      return "VISA";
    default:
      return "OTHER";
  }
};

export const handleAmexRedeemed = async (data, card) => {
  // REMOVE Amex:
  return;

  /*
  const { offerRedeemed } = await import("@kunaico/amex-perks-sdk");
  const cardToUse = handleFormatCard(card);
  const charge = !!data?.transaction
    ? data.transaction.amount_to_charge
    : !!data?.purchase
    ? data.purchase.amount_to_charge
    : null;
  offerRedeemed({
    order_cost: Number(charge),
    currency: "USD",
    payment_type: cardToUse,
  });
  */
};
