import React, { useEffect } from "react";
import { handleAmexLoad } from "../../helpers/handleAmexLoad";

const App = (props) => {
  useEffect(() => {
    handleAmexLoad(props.amToken);
  }, []);
  return <></>;
};

export default App;
