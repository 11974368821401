import React from "react";
const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 3000;

const BrandCarousel = ({
  brands,
  cloudinary = "https://res-5.cloudinary.com/equityeats-test/image/upload/",
  dark = false,
}) => {
  let brand_entries = (brands && Object.entries(brands)) || [];
  const perChunk = 3; // items per chunk

  let chunked = brand_entries.reduce(function (resultArray, item, index) {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === chunked.length - 1 ? 0 : prevIndex + 1,
        ),
      delay,
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {chunked.length > 1 ? (
          chunked.map((chunk, i) => (
            <div className="slide " key={i}>
              <div className="sister-brands-wrap mass-gifting">
                {chunk.map((brand, i) =>
                  brand[1][1] ? (
                    <img
                      className="brand"
                      src={cloudinary.concat("/h_280/").concat(brand[1][1])}
                      alt={brand[0]}
                      key={i}
                    />
                  ) : (
                    <div key={i} className="brand-square">
                      {brand[1]}
                    </div>
                  ),
                )}
              </div>
            </div>
          ))
        ) : (
          <div>
            <div className="sister-brands-wrap mass-gifting">
              {brand_entries.map((brand, i) =>
                brand[1][1] ? (
                  <img
                    className="brand"
                    src={cloudinary.concat("/h_280/").concat(brand[1][1])}
                    alt={brand[0]}
                    key={i}
                  />
                ) : (
                  <div key={i} className="brand-square white">
                    {brand[1]}
                  </div>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandCarousel;
