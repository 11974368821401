import React from "react";
import styled from "@emotion/styled";
import { FooterNoAccount, FooterPoweredBy } from "./footers";
/** @jsx jsx */
import { Global, css, jsx } from "@emotion/react";
import Icon from "@/shared/icon";
import mediaQuery from "@lib/mediaQueries";
import useBreakpoint from "@lib/useBreakpoint";
import getCookiesArr from "@lib/get-cookies-arr";
import ConfirmEmailToast from "../new-login-create-account/ConfirmEmailToast.jsx";

const cookiesArr = getCookiesArr();

const globalStyles = css`
  :root {
    --color-primary: #f3b900;
    --color-secondary: #29212d;
    --color-secondary-dark: #201923;
    --color-light-gray: #f5f5f5;
    --color-medium-gray: #dadada;
    --color-dark-gray: #4e4e4e;
    --color-black: #313030;
    --color-red: #f30000;
    --color-green: #03c095;
    --color-blue: #007aff;
    --color-blue-rgb: 0, 122, 255;

    --font-base: "Rethink-Sans-Medium";
    --font-bold: "Rethink-Sans-Bold";

    --checkout-mobile-pad: 0.5rem;
    --checkout-xpad: 0.625rem;
    --checkout-maxh: 470px;
    --checkout-maxw: 533px;

    --input-height: 3rem;
    --checkout-mobile-pad: 0rem;

    ${mediaQuery.sm} {
      --checkout-xpad: 1.8125rem;
    }

    ${mediaQuery.md} {
      --checkout-xpad: 5.25rem;
      --checkout-maxh: 550px;
    }
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 14px;

    ${mediaQuery.sm} {
      font-size: 16px;
    }
  }

  body {
    font-size: 1rem;
    font-family: var(--font-base);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
    user-select: none;
  }

  .transparent {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .disabled-text {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-light-gray);
  color: var(--color-dark-gray);

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--font-bold);
  }

  h4 {
    color: var(--color-dark-gray);
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const Header = styled.header`
  background-color: var(--color-secondary);
  text-align: center;
  padding: 10px var(--checkout-mobile-pad) 0;
  position: relative;
  color: white;
  min-height: 198px;
  
  ${mediaQuery.md} {
  }

  img {
    max-width: 130px;
    margin: 0 auto;
    height: auto;
    max-height: 100px;
  }

  .logout,
  .backLink {
    position: absolute;
    align-self: flex-start;
    display: inline-flex;
    align-items: center;
    color: white;
    font-size: 0.875rem;
    width: auto;
    cursor: pointer;

    i {
      margin-right: 0.5rem;
    }
  }

  .backLink {
    left: 10px;
    background-color: var(--color-secondary-dark);
    font-family: var(--font-bold);
    border-radius: 21px;
    padding: 13px 1rem;
    cursor: pointer:
  }

  .logout {
    right: 10px;
    background: transparent;
    border: 0;
    top: 20px;

    ${mediaQuery.md} {
      
    }
  }
`;

const Main = styled.main`
  flex: 1;
  position: relative;
  padding: 0 var(--checkout-mobile-pad);

  .jumpMenu {
    bottom: 37px;
    right: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.875rem;
    margin-top: 2rem;

    ${mediaQuery.md} {
      position: absolute;
      flex-direction: row;
      margin-top: 0;
    }

    strong {
      display: block;
      margin-right: 0.5rem;
    }

    ul {
      display: flex;
      color: white;
      list-style: none;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-size: inherit;
    }

    li {
      display: block;
      background-color: rgba(25, 25, 25, 0.67);
      border-radius: 5px;
      margin: 0 0.25rem 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 3px 8px;

      ${mediaQuery.md} {
        margin-bottom: 0;
      }

      &.active {
        background-color: rgba(25, 25, 25, 1);
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: var(--checkout-maxw);
  min-height: var(--checkout-maxh);
  margin: -5.2rem auto 0;
  background-color: white;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  overflow: hidden;
  font-size: 1rem;

  /*${mediaQuery.md} {
    height: var(--checkout-maxh);
    max-height: var(--checkout-maxh);
  }*/

  p {
    font-size: inherit;
  }
`;

const Footer = styled.footer`
  margin: 1rem 0 0;
`;

const isDev =
  window.location.hostname === "localhost" ||
  window.location.hostname === "app.inkind-staging.com"
    ? true
    : false;

const Layout = ({
  children,
  stages,
  stage,
  onStageChange,
  displayJumpMenu,
  projectInit,
  project,
  debugging,
  returnUrl,
  onLogout,
  userToken,
  backButtonAction,
  backButtonLabel,
  paymentProcessing,
  setShowToast,
  showToast,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Global styles={globalStyles} />
      <Wrapper>
        {showToast ? <ConfirmEmailToast setShowToast={setShowToast} /> : null}
        <Header>
          {backButtonLabel ? (
            <div onClick={backButtonAction} className="backLink">
              <Icon name="arrow-left" color="white" size="19" />{" "}
              {backButtonLabel}
            </div>
          ) : (
            !paymentProcessing && (
              <a href={returnUrl} className="backLink">
                <Icon name="arrow-left" color="white" size="19" />{" "}
                {breakpoint.isMdUp ? "Back to order" : "Back"}
              </a>
            )
          )}
          <img
            src={
              project?.white_logo_path
                ? project.white_logo_path
                : projectInit?.white_logo_path
                  ? projectInit.white_logo_path
                  : "https://res-2.cloudinary.com/equityeats-test/image/upload/c_fit,h_300,w_300/v1617225005/uj8xav5nliosa0jjmkzf.png"
            }
            alt="logo"
          />
          {userToken && (
            <button onClick={onLogout} className="logout">
              <Icon name="logout" color="white" size="19" /> Log Out
            </button>
          )}
        </Header>
        <Main>
          <ContentWrapper>{children}</ContentWrapper>
          <Footer>
            {(stage === "login" || stage === "forgotPassword") &&
            !!project?.purchase_page ? (
              <FooterNoAccount
                purchase_page={
                  project?.purchase_page || projectInit?.purchase_page
                }
              />
            ) : (
              <FooterPoweredBy />
            )}
          </Footer>

          {displayJumpMenu && (
            <div className="jumpMenu">
              <strong>Jump to stage</strong>
              <ul>
                {Object.keys(stages).map((n) => (
                  <li
                    key={n}
                    onClick={() => onStageChange(n)}
                    className={stage === n ? "active" : ""}
                  >
                    {n}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Main>
        {isDev && debugging && (
          <ul
            css={css`
              --spacing: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              list-style: none;
              font-size: 1rem;

              li {
                margin-right: 2rem;
                display: flex;
                padding-right: var(--spacing);
                background-color: white;
                border-radius: 4px;
                overflow: hidden;

                strong {
                  font-family: var(--font-bold);
                  display: inline-block;
                  background-color: rgba(0, 0, 0, 0.2);
                  padding: 0 var(--spacing);
                  margin-right: var(--spacing);
                }
              }
            `}
          >
            {debugging.map((d, i) => (
              <li key={i}>
                <strong>{d.label}:</strong> {d.value}
              </li>
            ))}
          </ul>
        )}
        {isDev && cookiesArr && (
          <ul
            css={css`
              --spacing: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              list-style: none;
              flex-direction: column;
              font-size: 1rem;

              li {
                margin-right: 2rem;
                margin-top: 20px;
                display: flex;
                width: 100%;
                padding-right: var(--spacing);
                background-color: white;
                border-radius: 4px;
                overflow: hidden;

                strong {
                  font-family: var(--font-bold);
                  display: inline-block;
                  background-color: rgba(0, 0, 0, 0.2);
                  padding: 0 var(--spacing);
                  margin-right: var(--spacing);
                }
              }
            `}
          >
            {cookiesArr.map((d, i) => (
              <li key={i}>
                <strong>{d.label}:</strong> {d.value}
              </li>
            ))}
          </ul>
        )}
      </Wrapper>
    </>
  );
};

Layout.defaltProps = {
  displayJumpMenu: false,
  backButtonLabel: false,
  backButtonAction: () => {},
};

export default Layout;
