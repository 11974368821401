import React from "react";
import DemoProfile from "../../../../../assets/images/ambassador/profile-demo.svg";
import PencilIcon from "../../../../../assets/images/ambassador/pencil-icon.svg";
import useCookie from "@lib/use-cookie";
import "./style.scss";
import { MinusIcon } from "@heroicons/react/24/outline";

export type ProfilePictureProps = {
  profilePicture: string;
};
// This component allows users to edit and upload their profile images
export const ProfilePicture = ({ profilePicture }: ProfilePictureProps) => {
  const [cookie, _setCookie] = useCookie("userToken", null);
  const [loading, setLoading] = React.useState(false);

  const uploadProfilePicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("profile_picture_upload", file);
    const config = {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: "Bearer ".concat(cookie),
      },
    };

    const path = `/api/v5/current_user/user_profile_pictures`;
    fetch(path, config).then((response) => {
      if (response.ok) {
        response.json().then((_data) => {
          setLoading(false);
          window.location.reload();
        });
      } else {
        setLoading(false);
        console.error("Error uploading profile picture");
      }
    });
  };

  return (
    <div className="ambassador-profile-image">
      {loading ? <MinusIcon className="spinner" /> : null}
      <label className="ambassador-label" htmlFor="file">
        <div className="">
          <img src={PencilIcon} width={40} alt="Edit Icon" />
        </div>
        <span className="ambassador-edit">Edit Photo</span>
      </label>
      <input
        className="ambassador-upload"
        id="file"
        type="file"
        accept="image/jpeg, image/png"
        multiple={false}
        onInput={uploadProfilePicture}
      />
      <img
        className="ambassador-image"
        src={profilePicture || DemoProfile}
        width={200}
        alt="Profile"
      />
    </div>
  );
};

export default ProfilePicture;
