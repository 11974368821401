import React from "react";
import { Provider } from "react-redux";
import MassGifting from "./MassGifting";
import { store } from "../redux/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const App = ({ presenter, cloudinaryData, bulkGiftTiers, env }) => {
  const [stripePromise, setStripePromise] = React.useState(null);
  React.useEffect(() => {
    setStripePromise(loadStripe(presenter.data_stripe_pk));
  }, [presenter]);
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <MassGifting
          presenter={presenter}
          cloudinaryData={cloudinaryData}
          bulkGiftTiers={bulkGiftTiers}
          environment={env}
        />
      </Elements>
    </Provider>
  );
};

export default App;
