import React from "react";
import { useSelector } from "react-redux";
import InKindLogo from "../../../../assets/images/icons/inkind-logo.png";
import { NavLink, useLocation } from "react-router-dom";
import DesktopNavButton from "./DesktopNavButton";
import MobileNavButton from "./MobileNavButton";

const NavBar = ({ isSos }) => {
  let user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  let firstname = user?.user && JSON.parse(user.user).first_name;
  let lastname = user?.user && JSON.parse(user.user).last_name;
  let email = user?.user && JSON.parse(user.user).email;
  return pathname === "/login" || !user ? (
    <></>
  ) : (
    <div className="mass-gifting-navbar">
      <nav>
        <img src={InKindLogo} className="inkind" />
        <NavLink
          exact="exact"
          to="/"
          className={`middle-link ${
            pathname === "/" ? "active" : ""
          } checkout-desktop`}
        >
          {!!isSos ? "Send Universal Vouchers" : "Send Dining Credit"}
        </NavLink>
        <NavLink
          exact="exact"
          to="/dashboard"
          className={`middle-link ${
            pathname === "/dashboard" ||
            pathname.split("/")[1] === "redemptions"
              ? "active"
              : ""
          } checkout-desktop`}
        >
          Dashboard
        </NavLink>
        <section className="right-nav">
          <div className="name-col">
            <h6>
              {firstname} {lastname}
            </h6>
            <span>{email}</span>
          </div>
          <span className="checkout-desktop">
            <DesktopNavButton />
          </span>
          <span className="checkout-mobile">
            <MobileNavButton />
          </span>
        </section>
      </nav>
    </div>
  );
};

export default NavBar;
