import React, { useState } from "react";
import styled from "@emotion/styled";
import mediaQuery from "@lib/mediaQueries";

const Scroller = styled.div`
  min-height: ${(props) =>
    `calc(var(--checkout-maxh) - ${
      props.minHeightOverride || props.height
    }px)`};
  flex: 1;
  padding-left: var(--checkout-xpad);
  padding-right: calc(var(--checkout-xpad) - 25px);
  margin-right: var(--checkout-xpad);
  padding-bottom: 2rem;
  -webkit-overflow-scrolling: auto;

  ${mediaQuery.md} {
    margin-right: 25px;
    /*max-height: ${(props) =>
      `calc(var(--checkout-maxh) - ${props.height}px)`};
    height: ${(props) => `calc(var(--checkout-maxh) - ${props.height}px)`};
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;*;/
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #ededed;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  & > p:first-of-type {
    padding-top: 1rem;
  }
`;

const ScrollContent = React.forwardRef(
  ({ children, className, minHeightOverride }, ref) => {
    const [height, setHeight] = useState(0);

    React.useEffect(() => {
      const headerContent = document.querySelector(".headerContent") || false;
      const footerContent = document.querySelector(".footerContent") || false;

      setHeight(
        (headerContent ? headerContent.offsetHeight : 0) +
          (footerContent ? footerContent.offsetHeight : 0),
      );
    }, []);

    return (
      <Scroller
        ref={ref}
        id="scroll-content"
        height={height}
        minHeightOverride={minHeightOverride}
        className={className}
      >
        {children}
      </Scroller>
    );
  },
);

export default ScrollContent;
