import React from "react";
import classNames from "classnames";
import { css } from "@emotion/css";

const fieldInputStyle = css`
  position: relative;
  margin-bottom: 2rem;
  font-size: 1rem;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    line-height: var(--input-height);
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    font-size: inherit;
    padding: 0 16px;
    color: #212121;
    box-shadow: 0 0 0 2.5px rgba(0, 122, 255, 0);

    &::-webkit-input-placeholder {
      color: var(--color-medium-gray);
    }

    &:-ms-input-placeholder {
      color: var(--color-medium-gray);
    }

    &::placeholder {
      color: var(--color-medium-gray);
    }

    &:focus,
    &.is-invalid {
      line-height: 46px;
      border-width: 2px;
      padding: 0 15px;
      outline: none;
    }

    &:focus {
      border-color: var(--color-blue);
      box-shadow: 0 0 0 2.5px rgba(0, 122, 255, 0.16);
    }

    &.is-invalid {
      border-color: var(--color-red);

      &:focus {
        border-color: var(--color-red);
        box-shadow: 0 0 0 2.5px rgba(243, 0, 0, 0.16);
      }
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0.5rem;
    font-size: 0.875rem;
    color: #7b7b7b;
    line-height: 1;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 0.5rem;
    font-weight: 500;
  }

  .invalid-feedback {
    font-size: 0.875rem;
    color: var(--color-red);
    padding-left: 1rem;
  }
`;

const FormInput = ({
  field,
  form: { touched, errors },
  title,
  className,
  labelClassName,
  fieldClassName,
  inValidClassName,
  errorClassName,
  placeholder,
  type = "text",
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];
  return (
    <div className={classNames(className, fieldInputStyle)}>
      <input
        type={type}
        placeholder={placeholder}
        className={classNames(fieldClassName, {
          [inValidClassName]: isError,
        })}
        {...field}
        {...props}
      />
      <label className={classNames(labelClassName)} htmlFor={field.name}>
        {title}
      </label>
      {isError && <div className={errorClassName}>{errors[field.name]}</div>}
    </div>
  );
};

FormInput.defaultProps = {
  fieldClassName: "",
  inValidClassName: "is-invalid",
  errorClassName: "invalid-feedback",
};

export default FormInput;
