import React from "react";
import AddImage from "../../ui/molecules/AddImage";
import unavailLogo from "../../../assets/images/icons/triangle-exclamation-solid.svg";

const PageUnavailable = () => {
  return (
    <div className="email__review--section email__review--approved--section">
      <div className="email__review__login--content">
        <img className="email__review--approved--logo" src={unavailLogo} />
        <div>
          <div className="email__review--listheader">
            This page is unavailable
          </div>
          <br />
          <div className="email__review--subtext">
            You've reached a page that you don't have access to. Please reach
            out to{" "}
            <span className="email__review--subheader">
              partner.support@inkind.com
            </span>{" "}
            if you believe you have reached this page in error.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageUnavailable;
