import React, { useState, useEffect, useMemo } from "react";
import numeral from "../../../../../vendor/assets/javascripts/numeral";
import RangeSlider from "../../../ui/molecules/RangeSlider";

const SubscriptionCalculator = () => {
  const [sliderSubsriptionValue, setSliderSubscriptionValue] = useState(5);
  const [inputSubscriptionValue, setInputSubscriptionValue] = useState(0);
  const [stopArr, setStopArr] = useState([
    50, 100, 150, 200, 250, 300, 400, 500, 600, 750, 1000, 1250, 1500, 2000,
    2500, 3000, 4000, 5000, 7500, 10000, 15000, 20000,
  ]);
  const [bonusSubscriptionValue, setBonusSubscriptionValue] = useState(50);

  const sliderValueChanged = (val) => {
    setSliderSubscriptionValue(val);
  };
  const handleChange = (e) => {
    let { min, max, value } = e.target;
    const regex = /^[0-9\b]+$/;
    let inputValue = Math.max(
      Number(min),
      Math.min(Number(max), Number(value)),
    );
    if (inputValue === "" || regex.test(inputValue)) {
      setInputSubscriptionValue(inputValue);
      setBonusSubscriptionValue(inputValue * 0.2);
    }
  };

  useEffect(() => {
    sliderSteps(sliderSubsriptionValue);
  }, [sliderSubsriptionValue]);

  const sliderProps = useMemo(
    () => ({
      min: 0,
      max: 21,
      value: sliderSubsriptionValue,
      onChange: (e) => sliderValueChanged(e),
    }),
    [sliderSubsriptionValue],
  );

  const sliderSteps = (val) => {
    for (let i = 0; i < stopArr.length; i++) {
      if (val < stopArr[i]) {
        setInputSubscriptionValue(stopArr[sliderSubsriptionValue]);
        setBonusSubscriptionValue(stopArr[sliderSubsriptionValue] * 0.2);
        break;
      }
    }
  };

  return (
    <div className="subscriptions-calc-section">
      <div className="subscription-container">
        <h5>DINE & EARN</h5>
        <p className="subscription-content">
          How much do you spend <span className="text-bold">dining out</span>{" "}
          each month?
        </p>
        <p className="subscription-label">TOTAL SPENT MONTHLY</p>
        <div className="subscription-input-value">
          <span className="amount-dollar-sign">$</span>
          <input
            className="subscription-text-box"
            type="number"
            aria-placeholder="Total Spent Monthly Input"
            aria-label="Total Spent Monthly Input"
            min={50}
            max={20000}
            value={inputSubscriptionValue}
            onChange={(e) => handleChange(e)}
          />
          {inputSubscriptionValue === 20000 ||
          sliderSubsriptionValue === 20000 ? (
            <span className="plus-sign">+</span>
          ) : null}
        </div>
        <RangeSlider
          sliderVal={sliderSubsriptionValue}
          setSliderVal={setSliderSubscriptionValue}
          name="subscription"
          id="subscription-range"
          aria-placeholder="Subscription Range Slider"
          aria-label="Subscription Range Slider"
          {...sliderProps}
        />
      </div>
      <div className="subscription-value-card">
        <h5>YOU COULD EARN</h5>
        <div className="subscription-value-content">
          <p className="subscription-value">
            {numeral(Math.floor(inputSubscriptionValue * 0.2)).format(
              "$0,0[.]00",
            )}
            <span>+</span>
          </p>
        </div>
        <h5>BACK EVERY MONTH</h5>
        <p className="subscription-save">
          That's enough{" "}
          {bonusSubscriptionValue >= 10 && bonusSubscriptionValue < 25
            ? "to cover the cost of the membership."
            : bonusSubscriptionValue >= 25 && bonusSubscriptionValue < 100
              ? "for a round (or two) of drinks for your crew."
              : bonusSubscriptionValue >= 100 && bonusSubscriptionValue < 200
                ? "to get that A5 Wagyu steak you've been eyeing."
                : bonusSubscriptionValue >= 200 && bonusSubscriptionValue < 500
                  ? "for a special bottle of wine."
                  : bonusSubscriptionValue >= 500 &&
                      bonusSubscriptionValue < 1000
                    ? "for a big night out."
                    : bonusSubscriptionValue >= 1000 &&
                        bonusSubscriptionValue < 3000
                      ? "to invite the whole crew for a night out."
                      : bonusSubscriptionValue >= 3000 &&
                          bonusSubscriptionValue < 10000
                        ? "to order the whole menu like the VIP you are."
                        : bonusSubscriptionValue >= 10000
                          ? "to rent out the whole place for your friends."
                          : ""}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionCalculator;
