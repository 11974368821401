import React from "react";

const GiftTabs = ({ gift, setGift }) => {
  React.useEffect(() => {
    if (window.location.hash === "#giftcards") {
      setGift(true);
    }
  }, []);
  return (
    <div className="gift-tabs regular-font">
      <button
        onClick={() => setGift(false)}
        className={`opt-btn ${!gift && "blue"}`}
      >
        House Accounts
      </button>
      <button
        onClick={() => setGift(true)}
        className={`opt-btn ${gift && "blue"}`}
      >
        Gift Cards
      </button>
    </div>
  );
};

export default GiftTabs;
