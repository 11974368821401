import React from "react";
import styled from "@emotion/styled";
import mediaQuery from "@lib/mediaQueries";

const ScrollWrapper = styled.div`
  height: 100%;
  min-height: var(--checkout-maxh);
  display: flex;
  flex-direction: column;

  /*${mediaQuery.md} {
    max-height: var(--checkout-maxh);
  }*/
`;

const ContentWrapper = ({ children }) => {
  return <ScrollWrapper>{children}</ScrollWrapper>;
};

export default ContentWrapper;
