import React from "react";
import CustomCurrencyInput from "./CustomCurrencyInput";
import poweredBy from "../../../../assets/images/checkout/inKind_Powered_By.png";

const absolutePanel = () => {
  let [currency, setCurrency] = React.useState("");
  return (
    <div className="absolute-panel">
      <div className="panel-wrap">
        <h2 className="head panel-h">Employee Dining</h2>
        <div className="inkind-cash-description">
          <span className="regular-font">
            Bulk orders are rewarded with 20% - 30% in bonus dining credit.
          </span>
        </div>
        <div className="pannel-inner-wrapper">
          <div className="panel-options-wrapper lefter ff">
            <div className="open-amount-col ff mass-gifting regular-font">
              <div className="flex-col">
                <span className="font-bold">Dining Credit Amount</span>
                <span className="fourteen">
                  {" "}
                  Enter the individual gift amount you would like each person to
                  receive.
                </span>
              </div>
              <div className="input-error-col">
                <CustomCurrencyInput
                  currency={currency}
                  setCurrency={setCurrency}
                />
              </div>
              <div className="button-row">
                <button onClick={() => setCurrency(100)}>$100</button>
                <button onClick={() => setCurrency(250)}>$250</button>
                <button onClick={() => setCurrency(500)}>$500</button>
              </div>
            </div>
            <a href={window.location + "/checkout" + `?spend=${currency}`}>
              <button className="start-order">Start your order!</button>
            </a>
          </div>
          <img src={poweredBy} alt="powered by inKind" className="powered-by" />
        </div>
      </div>
    </div>
  );
};

export default absolutePanel;
