import PropTypes from "prop-types";
import isFunction from "lodash-es/isFunction";

const ConditionalRender = ({ shouldRender, children, fallback }) =>
  shouldRender ? (isFunction(children) ? children() : children) : fallback;

ConditionalRender.defaultProps = {
  fallback: null,
};

ConditionalRender.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  fallback: PropTypes.node,
  shouldRender: PropTypes.bool.isRequired,
};

export default ConditionalRender;
