import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  min-height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-size: inherit;
  padding: 0 16px;
  color: #212121;
  box-shadow: 0 0 0 2.5px rgba(0, 122, 255, 0);
`;

const Error = styled.div`
  font-size: 0.875rem;
  color: var(--color-red);
  padding-left: 1rem;
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0.5rem;
  font-size: 0.875rem;
  color: #7b7b7b;
  line-height: 1;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.5rem;
  font-weight: 500;
`;

const FieldWrapper = ({ label, children, className, error }) => {
  return (
    <>
      <Wrapper className={className}>
        {children}
        <Label>{label}</Label>
      </Wrapper>
      {error !== "" && <Error>{error}</Error>}
    </>
  );
};

export default FieldWrapper;
