import React, { useState, useEffect } from "react";
import classNames from "classnames";
import axios from "@lib/axios";
import { Formik, Form, Field } from "formik";
import FormInput from "@/shared/forms/form-input";
import FullButton from "@/shared/forms/form-fullButton";
import ContentWrapper from "@/shared/content-wrapper";
import HeaderContent from "@/shared/header-content";
import ScrollContent from "@/shared/scroll-content";
import FixedContent from "@/shared/fixed-content";
import MessageWrapper from "@/shared/message-wrapper";
import PaymentOptions from "@/shared/payment-options";
import { css } from "@emotion/css";
import mediaQuery from "@lib/mediaQueries";
import StripeHelpers, { prepareStripeCard } from "@lib/stripe-helpers";
import ConditionalRender from "@/shared/conditional-render";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { currency, getCardDetails } from "@lib/helpers";
import Loader from "@/shared/loader";

const calculateTip = (balance, tip, mode = "currency") => {
  const rawTip =
    tip <= 0 || tip === "" || tip === null || tip === false
      ? 0.0
      : balance * (tip / 100);
  return mode === "cents" ? Math.round(rawTip * 100) : currency(rawTip);
};

const tipOptions = [
  {
    value: 0,
    label: "No tip",
  },
  {
    value: 18,
    label: "18%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 22,
    label: "22%",
  },
  {
    value: 25,
    label: "25%",
  },
];

const additionalTipOptions = [
  {
    value: 0,
    label: "No tip",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 10,
    label: "10%",
  },
];

const dollarTipOptions = [
  {
    value: 0.0,
    label: "No tip",
  },
  {
    value: 0.75,
    label: "$0.75",
  },
  {
    value: 1.0,
    label: "$1.00",
  },
  {
    value: 2.0,
    label: "$2.00",
  },
  {
    value: 3.0,
    label: "$3.00",
  },
];

const CheckoutRedeemCredit = ({
  onComplete,
  onPaymentFailed,
  project,
  ticket,
  axiosHeaders,
  defaultTip,
  paymentMethod,
  setPaymentMethod,
  setCheckoutData,
  tipStorage,
  setTipStorage,
  tipFieldsDisabled,
  isCardNeeded,
  offer,
}) => {
  const { prepareStripeCard, sendPayment } = StripeHelpers({
    axiosHeaders,
    project,
    ticket,
  });
  const tipType = ticket.bill_total >= 10 ? "percent" : "fixed";
  const stripe = useStripe();
  const elements = useElements();
  const balance = Number(ticket?.bill_amount);
  const taxes = ticket?.taxes
    .reduce((taxes, tax) => taxes + Number(tax.display_amount), 0)
    .toFixed(2);
  const [submit, setSubmit] = useState(false);
  const updatePercentTip = (tip, setFieldValue) => {
    setUiTip(tip);
    setFieldValue("tip", calculateTip(balance - taxes, tip));
  };
  const updateFixedTip = (tip, setFieldValue) => {
    setUiTip(tip);
    setFieldValue("tip", currency(tip));
  };
  const hasAutomaticGratuity =
    ticket?.service_charges?.find((sc) => sc.type === "gratuity") || false;
  const suggestedGratuity =
    ticket?.service_charges?.find((sc) => sc.type === "suggested_tip") || false;
  let tipButtons;
  let updateTip;
  let uiDef = 20;
  if (tipType === "percent") {
    tipButtons = hasAutomaticGratuity ? additionalTipOptions : tipOptions;
    updateTip = updatePercentTip;
  } else {
    tipButtons = dollarTipOptions;
    updateTip = updateFixedTip;
    uiDef = 1.0;
  }
  const [uiTip, setUiTip] = useState(
    defaultTip || tipStorage != null ? null : hasAutomaticGratuity ? 2 : uiDef,
  );
  const calculateDefaultTip = React.useMemo(() => {
    if (tipType === "fixed") {
      return !!suggestedGratuity
        ? currency(suggestedGratuity.due)
        : currency(1.0);
    }
    if (tipStorage != null && !tipFieldsDisabled) {
      return currency(tipStorage / 100);
    }

    if (defaultTip)
      return currency(
        tipFieldsDisabled
          ? "0"
          : hasAutomaticGratuity
            ? Math.max(defaultTip - hasAutomaticGratuity.due, 0)
            : defaultTip,
      );
    if (suggestedGratuity) return currency(suggestedGratuity.due);
    return calculateTip(balance - taxes, uiTip);
  }, [
    suggestedGratuity,
    defaultTip,
    tipFieldsDisabled,
    balance,
    taxes,
    uiTip,
    tipStorage,
  ]);

  return (
    <>
      <div>
        <Formik
          initialValues={(() => {
            return {
              tip: calculateDefaultTip,
              paymentMethod: "",
            };
          })()}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (
            values,
            { setSubmitting, setFieldError, setFieldTouched },
          ) => {
            setSubmitting(true);
            setSubmit(true);
            if (!tipFieldsDisabled) {
              const tipCents = Math.round(
                Number(values.tip.replace(/[^0-9\.]+/g, "")) * 100,
              );
              const { card, cardId, cardErrors } = await prepareStripeCard({
                values,
                setFieldTouched,
                setFieldError,
                field: "paymentMethod",
                cardElement: elements.getElement(CardElement),
              });

              if (cardId && cardErrors.length < 1) {
                setPaymentMethod(getCardDetails(cardId, project, card));
                setTipStorage(tipCents);
                onComplete();
              }
              if (!isCardNeeded(values) || cardId) {
                onComplete();
              } else {
                setSubmitting(false);
                setSubmit(false);
                onPaymentFailed(cardErrors);
              }
            }

            setSubmitting(false);
            setSubmit(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ContentWrapper>
                <HeaderContent
                  displayCurrentCredit
                  project={project}
                  ticket={ticket}
                  offer={offer}
                >
                  <h1>Redeem Credit</h1>
                </HeaderContent>
                <ScrollContent>
                  <div
                    className={css`
                      font-size: 1.125rem;

                      strong {
                        font-family: var(--font-bold);
                        display: block;
                      }

                      div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #f0f0f0;
                        padding: 12px 0;

                        div {
                          border: 0;
                          padding: 0;

                          strong {
                            margin-right: 0.5rem;
                          }
                        }
                      }
                    `}
                  >
                    <div>
                      <strong>Amount Due</strong>
                      <span>{currency(balance)}</span>
                    </div>
                    {ticket.service_charges
                      .filter((sc) =>
                        ["suggested_tip", "gratuity"].includes(sc.type),
                      )
                      .map((sc, i) => (
                        <div key={i}>
                          <strong>{sc.label}</strong>
                          <span>{currency(sc.due)}</span>
                        </div>
                      ))}
                    <ConditionalRender shouldRender={tipFieldsDisabled}>
                      <div>
                        <div>
                          <strong>Tip</strong>
                          <span
                            className={css`
                              font-size: 0.8rem;
                            `}
                          >
                            (charged to your card on file)
                          </span>
                        </div>
                        <span>{currency(defaultTip * -1)}</span>
                      </div>
                    </ConditionalRender>
                  </div>

                  <ConditionalRender shouldRender={!tipFieldsDisabled}>
                    <h4
                      className={css`
                        padding-top: 1rem;
                      `}
                    >
                      {hasAutomaticGratuity
                        ? "Additional tip amount "
                        : "Tip amount "}
                      <span
                        className={css`
                          font-family: var(--font-base);
                        `}
                      >
                        (charged to card)
                      </span>
                    </h4>
                    <Field
                      name="tip"
                      value={values.tip ? values.tip : ""}
                      component={FormInput}
                      type="text"
                      validate={(val) => {
                        if (
                          !/^[|$]?[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(
                            val,
                          )
                        ) {
                          return "Invalid tip format";
                        }
                        const gratCents = Math.round(
                          Number(val.replace(/[^0-9\.]+/g, "")) * 100 +
                            (hasAutomaticGratuity
                              ? hasAutomaticGratuity.due
                              : 0) *
                              100,
                        );
                        if (
                          gratCents <= 50 &&
                          gratCents > 0 &&
                          Number(values.tip.replace(/[^0-9\.]+/g, "")) * 100 > 0
                        ) {
                          return "Tip must be above $0.50";
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("tip", e.target.value);
                        if (e.target.value != null && !tipFieldsDisabled) {
                          setUiTip(null);
                          for (let i = 0; i < tipButtons.length; i++) {
                            if (
                              calculateTip(
                                balance - taxes,
                                tipButtons[i].value,
                                "cents",
                              ) === e.target.value
                            ) {
                              setUiTip(tipButtons[i].value);
                            }
                          }
                        }
                      }}
                      className={css`
                        margin-bottom: 0.5rem;

                        ${mediaQuery.lg} {
                          margin-bottom: 1rem;
                        }

                        input {
                          font-weight: 700;
                        }
                      `}
                    />
                    <ul
                      className={css`
                        list-style: none;
                        margin: 0 0 24px 0;
                        padding: 0;
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        grid-gap: 4px;
                        font-size: 1rem;
                        text-align: center;
                        user-select: none;

                        ${mediaQuery.lg} {
                          grid-gap: 8px;
                        }

                        li {
                          flex: 1;
                          margin: 0;
                          padding: 0;
                          line-height: 48px;
                          border: 1px solid #e7e7e7;
                          border-radius: 4px;
                          cursor: pointer;

                          &.current {
                            border-width: 2px;
                            border-color: var(--color-blue);
                            font-weight: 700;
                          }
                        }
                      `}
                    >
                      {tipButtons.map((t, i) => (
                        <li
                          key={i}
                          className={classNames({ current: t.value === uiTip })}
                          onClick={() => updateTip(t.value, setFieldValue)}
                        >
                          {t.label}
                        </li>
                      ))}
                    </ul>

                    <h4
                      className={css`
                        margin-bottom: 1.5rem;
                      `}
                    >
                      Tip payment method
                    </h4>

                    <Field
                      name="paymentMethod"
                      component={PaymentOptions}
                      project={project}
                      ticket={ticket}
                      onChange={(val) => {
                        setFieldValue("paymentMethod", val);
                        setCheckoutData(val);
                      }}
                    />

                    <div
                      className="loaderContainer"
                      className={css`
                        display: ${!!submit ? "block" : "none"};
                      `}
                    >
                      <Loader
                        className={css`
                          margin: 0 auto;
                          margin-top: 40px;
                          margin-bottom: 20px;
                        `}
                      />
                      <h4
                        className={css`
                          text-align: center;
                        `}
                      >
                        Running your card, please wait
                      </h4>
                    </div>
                  </ConditionalRender>
                </ScrollContent>
                <FixedContent>
                  <FullButton type="submit" disabled={!stripe || submit}>
                    Next
                  </FullButton>
                </FixedContent>
              </ContentWrapper>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CheckoutRedeemCredit;
