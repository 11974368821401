import { useEffect, useMemo, useReducer } from "react";
import { initRequest, onRefresh, onManualFetch } from "./actions";
import { reducer } from "./reducer";
import isFunction from "lodash-es/isFunction";
import identity from "lodash-es/identity";

function useFetch(
  { request, initialState, manual = false, onSuccess = identity },
  ...args
) {
  const [results, dispatch] = useReducer(
    reducer,
    initialState,
    (initialResponse) => ({
      error: null,
      status: manual ? "idle" : "pending",
      response: initialResponse,
    }),
  );

  const memoizedRequestArgs = useMemo(() => args, args);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedArgs = useMemo(
    () => [args, request],
    [memoizedRequestArgs, request, manual],
  );

  const onRequest = (...args) => initRequest(request, args)(dispatch);
  const handleRefresh = () => onRefresh(request, args)(dispatch);
  const handleManualFetch = (...args) => onManualFetch(request, args)(dispatch);

  useEffect(() => {
    if (manual === false) {
      let cancelRequest = false;
      if (cancelRequest === false) {
        initRequest(request, args)(dispatch).then(onSuccess);
      }
      return function cleanup() {
        cancelRequest = true;
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memoizedArgs);

  return useMemo(
    () => ({
      ...results,
      updateData: (state) =>
        dispatch({
          type: "update",
          payload: isFunction(state) ? state(results) : state,
        }),
      request: onRequest,
      refresh: handleRefresh,
      onManualFetch: handleManualFetch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results],
  );
}

export default useFetch;
