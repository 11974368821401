import React from "react";
import poweredBy from "../../../../assets/images/checkout/inKind_Powered_By.png";
import HouseAccountButton from "../layouts/HouseAccountButton";
import "core-js/es/object";

const InKindTiers = ({
  tb,
  setFriendsAndFamily,
  second,
  gift,
  ffOptions,
  utm_values,
}) => {
  const isGive = window.location.host.split(".")[0] === "give";
  let options = gift ? tb.standard_gcos : tb.inkind_cash ? tb.icos : tb.gcos;
  let sorted_options = !tb.desc
    ? Object.entries(options)
    : Object.entries(options).reverse();

  return (
    <>
      <h2 className={!!isGive ? "head-extra-bold panel-h" : "head panel-h"}>
        {second
          ? tb.second_offer_headline
          : !gift
            ? tb.tier_modal_title
            : "Purchase a Gift Card"}
      </h2>
      <div className={second ? "second-offer-wrapper" : "pannel-inner-wrapper"}>
        <span
          className={
            tb.tier_modal_description.length > 80
              ? "smaller-text"
              : "regular-font"
          }
        >
          {tb.tier_modal_description}
        </span>
        <div className="panel-options-wrapper">
          {sorted_options.map((gco, i) => (
            <HouseAccountButton
              id={gco[1].gco_slug || gco[1].ico_slug}
              pay_verb={tb.pay_verb}
              get_verb={tb.get_verb}
              ribbon_color={tb.ribbon_color}
              key={i}
              most_popular={i === 1 ? true : false}
              pay={gco[1].pay_amount}
              give={gco[1].get_amount}
              strike={gco[1].strike_through_get}
              button_color={tb.button_color}
              ribbon_font={tb.ribbon_font}
              banner_label={gco[1].banner_label}
              strike_through_mode={tb.strike_through_mode}
              standard={gco[1].pay_amount === gco[1].get_amount ? true : false}
              tiers_banner_mode={tb.tiers_banner_mode}
              utm_values={utm_values || null}
              inkind_cash={tb.inkind_cash}
            />
          ))}
          {tb.inkind_cash && !isGive ? (
            <span className="under-text">
              Gifting for business?{" "}
              <a
                href={
                  window.location.origin.replace("/give", "/business") +
                  "/gifting#calculator-section"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="see-tiers "
              >
                Explore Business Accounts
              </a>
            </span>
          ) : tb.inkind_cash && !!isGive ? (
            <span className="under-text">
              Need to send multiple gifts? <br />
              <a
                href={
                  window.location.origin.replace("://give.", "://business.") +
                  "/gifting#calculator-section"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="see-tiers "
              >
                Explore our gifting portal.
              </a>
            </span>
          ) : (
            <></>
          )}
          {ffOptions && (
            <button
              className="back-row"
              onClick={() => setFriendsAndFamily(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="back-chevron"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>{" "}
              Back to Previous Options
            </button>
          )}
          {tb.fine_print && <span className="under-text">{tb.fine_print}</span>}
        </div>
      </div>
    </>
  );
};

export default InKindTiers;
