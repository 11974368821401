import React from "react";
import { ItemsList, generateBillDetails } from "./ViewOrder";
import VisaIcon from "./images/visa-card.svg";
import AmexIcon from "./images/american-express-card.svg";
import MasterIcon from "./images/master-card.svg";
import DinersClubIcon from "./images/diners-club-card.svg";
import JCBIcon from "./images/jcb-card.svg";
import UnionPayIcon from "./images/union-pay-card.svg";
import UnknownCardIcon from "./images/unknown-card.svg";
import DiscoverCardIcon from "./images/discover-card.svg";
import {
  LineItem,
  Location,
  PaymentCard,
  PaymentResponse,
  TicketData,
} from "./type";

type ReceiptProps = {
  backToSuccess: () => void;
  cardInfo: PaymentCard;
  paymentResponse: PaymentResponse;
  lineItems: LineItem[];
  locationInfo: {
    name: string;
    location: Location;
  };
  ticketData: TicketData;
};

function formatDateString(dateString: string): string {
  const date = new Date(dateString);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  };

  const timeString = date.toLocaleTimeString(undefined, timeOptions);
  const dateStringFormatted = date.toLocaleDateString(undefined, dateOptions);

  return `${timeString} ${dateStringFormatted}`;
}

const getCardIcon = (brand: string) => {
  switch (brand) {
    case "visa":
      return VisaIcon;
    case "mastercard":
      return MasterIcon;
    case "american express":
      return AmexIcon;
    case "diners club":
      return DinersClubIcon;
    case "jcb":
      return JCBIcon;
    case "unionpay":
      return UnionPayIcon;
    case "discover":
      return DiscoverCardIcon;
    default:
      return UnknownCardIcon;
  }
};
export const calcTotalQuantity = (lineItems: LineItem[]) =>
  lineItems.reduce((total, item) => total + item.quantity, 0);

export const Receipt: React.FC<ReceiptProps> = ({
  backToSuccess,
  cardInfo,
  paymentResponse,
  lineItems,
  locationInfo,
  ticketData,
}) => {
  const { exp_month, exp_year, last4, brand } = cardInfo;
  const { pos_success, balance } = paymentResponse;
  const {
    branding,
    created_at,
    amount = 0,
    tip,
    fee_detail = [],
  } = pos_success || {};
  const { name, location } = locationInfo || {};
  const address1 = location?.address;
  const address2 = `${location?.city}, ${location?.state} ${location?.zip_code}`;
  const brandLowerCase = brand?.toLowerCase() || "";
  const cardIcon = getCardIcon(brandLowerCase);
  const totalAmountWithTip = (amount + (tip?.amount || 0)).toFixed(2);

  const billDetails = {
    ...generateBillDetails(ticketData),
    Tip: fee_detail?.find((fee) => fee.fee_type === "server_tip")?.amount || 0,
    "Bill Total": totalAmountWithTip,
  };
  const totalQuantity = calcTotalQuantity(lineItems);

  return (
    <div className="receipt">
      <div className="receipt__header">
        <button onClick={backToSuccess} className="receipt__arrow-left" />
        <span>Receipt</span>
      </div>
      <img
        src={
          branding?.logo?.light_logo ||
          "https://res-2.cloudinary.com/equityeats-test/image/upload/c_fit,h_300,w_300/v1617225005/uj8xav5nliosa0jjmkzf.png"
        }
        alt="logo"
        className="receipt__logo receipt__section"
      />
      <div className="receipt__details receipt__section">
        <div className="receipt__date-time">
          {formatDateString(created_at || "")}
        </div>
        <div className="receipt__amount">${totalAmountWithTip}</div>
        <div className="receipt__bill-total">Bill Total</div>
        <div className="receipt__name">{name}</div>
        <div className="receipt__address">
          <span>{address1}</span>
          <span>{address2}</span>
        </div>
      </div>
      <div className="receipt__items-list-container receipt__section">
        <div className="receipt__title">
          Your order ({totalQuantity} Item{totalQuantity > 1 ? "s" : ""})
        </div>
        <ItemsList items={lineItems} />
      </div>
      <div className="receipt__payment receipt__section">
        {!!balance && (
          <>
            <div className="receipt__inkind">
              <span>inKind Balance</span>
              <span className="receipt__amount">${balance}</span>
            </div>
            <div className="divider"></div>
          </>
        )}
        <div className="receipt__card">
          <span>Charged to card</span>
          <div className="receipt__card-info">
            <img src={cardIcon} alt="credit card logo" />
            <span className="receipt__card-number">
              {brand?.toUpperCase()} *{last4}
            </span>
            <span className="receipt__date">
              {exp_month}/{exp_year}
            </span>
          </div>
        </div>
      </div>
      <div className="receipt__bill-details">
        {Object.entries(billDetails).map(([key, value]) => {
          return (
            <div
              className={`receipt__bill-details-row ${key === "Bill Total" ? "bill-total" : ""}`}
              key={key}
            >
              <span>{key}</span>
              <span>${(+value).toFixed(2)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
