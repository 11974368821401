import React, { useState } from "react";
import { Splash } from "./Splash";

interface CheckNotFoundProps {
  logoSrc?: string;
}

const CheckNotFound: React.FC<CheckNotFoundProps> = ({ logoSrc }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <Splash logoSrc={logoSrc} />
      <div
        className={`check-not-found ${!isOpen ? "check-not-found--hidden" : ""}`}
      >
        <div className="check-not-found__image" />
        <h2 className="check-not-found__title">Check not found</h2>
        <p className="check-not-found__message">
          Your specific ticket couldn’t be found. Please try again or ask your
          server for help closing out.
        </p>
        <button
          className="check-not-found__button"
          onClick={() => setIsOpen(false)}
        >
          Done
        </button>
      </div>
    </>
  );
};

export default CheckNotFound;
