import React from "react";
import TierStayTuned from "../../../../assets/images/purchases/Tier-Stay-Tuned.svg";
import poweredBy from "../../../../assets/images/checkout/inKind_Powered_By.png";
import EmailCapture from "./EmailCapture";

const StatyTuned = ({ mobile, second, projectId }) => {
  return (
    <div
      className={`${mobile ? "panel-mobile" : "panel-desktop"} ${
        second && !mobile
          ? "second-offer-container  modal-padding"
          : "right-panel"
      } `}
    >
      <div className="stay-tuned">
        <img className="tray" src={TierStayTuned} />
        <h3 className="head">Stay tuned for House Accounts</h3>
        <span className="regular-font second-line">
          Enter your email below to get notified when House Accounts become
          available.
        </span>
        <div>
          <EmailCapture projectId={projectId} />
          <img
            src={poweredBy}
            alt="powered by inKind"
            className="powered-by email-capture"
          />
        </div>
      </div>
    </div>
  );
};

export default StatyTuned;
