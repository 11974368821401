import React from "react";
import { MinusIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { changePassword } from "../services/auth";

const SetPassword = ({ purchaseResponse, setNeedsPassword }) => {
  const [password, setPassowrd] = React.useState("");
  const [confirmPassword, setConfirmPassowrd] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("• Passwords do not match");
      return;
    }
    if (password.length < 8) {
      setPasswordError("• Password must be at least 8 characters");
      return;
    }
    setProcessing(true);
    try {
      await changePassword(
        password,
        confirmPassword,
        purchaseResponse.reset_token,
      );
      setNeedsPassword(false);
    } catch (error) {
      setPasswordError("• ".concat(JSON.parse(error.request.response).error));
    } finally {
      setProcessing(false);
    }
  };
  return (
    <form className="set-pw-col" onSubmit={handleSubmit}>
      <div className="inner">
        <div className="action-required">
          <ExclamationCircleIcon className="icon" />
          <span>Required Action</span>
        </div>
        <div className="checkout-head pb-four">Set your inKind password</div>
        <div>Required to log in to the inKind app to spend your credit.</div>
        {passwordError && <span className="error">{passwordError}</span>}
        <div className="input-error-col">
          <div className="floating-label-wrapper outline">
            <input
              value={password}
              type={passwordShown ? "text" : "password"}
              placeholder=" "
              aria-label="password"
              className="floating-label-input"
              onChange={({ target }) => {
                setPassowrd(target.value);
                setPasswordError(null);
              }}
            />
            {!passwordShown ? (
              <EyeIcon
                onClick={() => setPasswordShown(true)}
                className="eye-icon"
              />
            ) : (
              <EyeSlashIcon
                onClick={() => setPasswordShown(false)}
                className="eye-icon"
              />
            )}
            <label className="floating-label">Password</label>
          </div>
        </div>
        <div className="input-error-col">
          <div className="floating-label-wrapper outline">
            <input
              value={confirmPassword}
              type={passwordShown ? "text" : "password"}
              className="floating-label-input"
              placeholder=" "
              onChange={({ target }) => {
                setConfirmPassowrd(target.value);
                setPasswordError(null);
              }}
            />
            <label className="floating-label">Confirm Password</label>
          </div>
        </div>
        <button
          type="submit"
          disabled={processing}
          className={`pay-button  ${processing ? "loading" : "actionable"}`}
          aria-label="set password"
        >
          <span>Set Password</span>
          {processing && <MinusIcon className="spinner" />}
        </button>
      </div>
    </form>
  );
};

export default SetPassword;
