//Returns an array of objects, with the key as the cookie name and value as the cookie value
const getCookiesArr = () => {
  const cookies = document.cookie.split("; ");
  let cookiesArr = [];
  for (let i = 0; i < cookies.length; i++) {
    const arr = cookies[i].split("=");
    cookiesArr.push({ label: arr[0], value: arr[1] });
  }
  return cookiesArr;
};

export default getCookiesArr;
