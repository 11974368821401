import get from "lodash-es/get";

export const initRequest = (fn, params) => async (dispatch) => {
  dispatch({ type: "init" });
  try {
    const response = await fn.apply(null, params);
    dispatch({ type: "success", payload: response });
    return response;
  } catch (error) {
    const { status, statusText, code, message } = get(error, "response", error);
    dispatch({
      payload: {
        status: status || code,
        statusText: statusText || message,
      },
      type: "fail",
    });
  }
};

export const onRefresh = (fn, params) => async (dispatch) => {
  dispatch({ type: "refresh" });
  try {
    const response = await fn.apply(null, params);
    dispatch({ type: "refresh_success", payload: response });
    return response;
  } catch (error) {
    dispatch({
      payload: error,
      type: "refresh_fail",
    });
  }
};

export const onManualFetch = (fn, params) => async (dispatch) => {
  dispatch({ type: "manual_fetch" });
  try {
    const response = await fn.apply(null, params);
    dispatch({ type: "manual_fetch_success", payload: response });
    return response;
  } catch (error) {
    dispatch({
      payload: error,
      type: "manual_fetch_success_fail",
    });
  }
};
