import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import FullButton from "@/shared/forms/form-fullButton";
import ContentWrapper from "@/shared/content-wrapper";
import HeaderContent from "@/shared/header-content";
import ScrollContent from "@/shared/scroll-content";
import FixedContent from "@/shared/fixed-content";
import MessageWrapper from "@/shared/message-wrapper";
import PaymentOptions from "@/shared/payment-options";
import { css } from "@emotion/css";
import StripeHelpers from "@lib/stripe-helpers";
import ConditionalRender from "@/shared/conditional-render";
import ReviewSection from "./components/ReviewSection";
import Icon from "@/shared/icon";
import HelpText from "@/shared/help-text";
import { currency, getCardDetails } from "@lib/helpers";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Loader from "@/shared/loader";
import Dropdown from "./dropdown";
import EarnBackIcon from "../../../assets/images/icons/credit-back-icon.svg";
import numeral from "../../../../vendor/assets/javascripts/numeral";

const CheckoutReview = ({
  onComplete,
  setCheckoutData,
  onPaymentFailed,
  checkoutData,
  posSlug,
  earnBackAmount,
  location,
  project,
  ticket,
  axiosHeaders,
  defaultTip,
  payAmount,
  returnUrl,
  paymentMethod,
  setPaymentMethod,
  tipStorage,
  tipFieldsDisabled,
  skipTipEntryStage,
  isCardNeeded,
  onPaymentAlreadyComplete,
  setPaymentProcessing,
  redirectToVendor,
  offer,
}) => {
  const [offerToUse, setOfferToUse] = useState(offer);

  const balanceMinusOffer =
    ticket.bill_house_account_amount -
    (offerToUse?.claim_details?.balance ?? 0);

  const chargeToHA =
    balanceMinusOffer > project?.credit_balance
      ? project?.credit_balance
      : balanceMinusOffer;
  const remainingAfterHA = balanceMinusOffer - chargeToHA;
  const chargeToIkPass =
    remainingAfterHA > project?.ik_pass_balance
      ? project?.ik_pass_balance
      : remainingAfterHA;
  const remainingAfterIkPass = remainingAfterHA - chargeToIkPass;
  const chargeToCard = remainingAfterIkPass > 0 ? remainingAfterIkPass : 0;

  const scrollContainer = useRef(null);
  const [offerData, setOfferData] = useState(project?.offers);
  const [offerVal, setOfferVal] = useState(
    offer?.name ? offer?.name : "Select one...",
  );
  const [chargeDetails, setChargeDetails] = useState([
    {
      title: "Charge to House Account",
      total: chargeToHA,
    },
    {
      title: "Charge to inKind Pass",
      total: chargeToIkPass,
    },
  ]);

  let itemsReduced =
    chargeDetails.reduce((acc, cur) => acc + cur.total, 0) +
    (offerToUse?.claim_details?.balance ?? 0);

  const [offerAmount, setOfferAmount] = useState(
    itemsReduced < offer?.claim_details.balance
      ? itemsReduced
      : itemsReduced > offer?.claim_details.balance
        ? offer?.claim_details.balance
        : offer?.claim_details.balance
          ? offer?.claim_details.balance
          : "",
  );
  const [claimDetails, setClaimDetails] = useState("");
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [showChargeWhy, setShowChargeWhy] = useState(false);
  const [submit, setSubmit] = useState(false);
  const { prepareStripeCard, sendPayment } = StripeHelpers({
    axiosHeaders,
    project,
    ticket,
    posSlug,
  });
  const stripe = useStripe();
  const elements = useElements();
  const cardTotal = ticket.bill_card_amount + tipStorage / 100;
  const grandTotal = ticket.bill_amount + tipStorage / 100;
  const displayGrandTotal = ticket.service_charges.some(
    (sc) => sc.bill_card && sc.type !== "suggested_tip",
  );
  const displayChargedItems = Boolean(displayGrandTotal || tipStorage);
  const displayChargedItemsTemp = Boolean(true);
  const [img, setImg] = useState("/assets/checkout/payment.gif");
  const [header, setHeader] = useState("Redeeming Credit");
  const [bodyText, setBodyText] = useState(
    "Applying credit and paying your bill,\n this may take a moment",
  );
  const [billPaid, setBillPaid] = useState(false);
  const [returnUrlOverride, setReturnUrlOverride] = useState(null);
  const [offerItems, setOfferItems] = useState([]);
  const paymentComplete = () => {
    return setTimeout(() => {
      setBillPaid(true);
    }, 6000);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [checkingPayment]);
  const handleOfferChange = (val, amount, offer) => {
    if (val === "Don't apply any offer") setOfferVal(val);
    setOfferVal(val);
    setOfferAmount(amount);
    setOfferToUse(offer);
  };

  const overspendAmount = offerToUse
    ? ticket.bill_house_account_amount -
      Number(project?.balance) -
      offerToUse?.claim_details.balance
    : ticket.bill_house_account_amount - Number(project?.balance);

  const displayPassPaid = Boolean(chargeToIkPass > 0);

  // should display show house account
  let hasCredit =
    (offerToUse?.claim_details?.balance || 0) > 0 || project?.balance > 0;

  return (
    <>
      <ConditionalRender shouldRender={checkingPayment}>
        <MessageWrapper>
          <img
            className={css`
              margin-bottom: ${header === "Payment Success!" ? "20px" : "41px"};
              max-width: 80%;
              margin-top: ${header === "Payment Success!" ? "180px" : "41px"};
            `}
            src={img}
            alt="Redeeming Credit"
          />
          <h3>{header}</h3>
          <p>{bodyText}</p>
        </MessageWrapper>
      </ConditionalRender>
      <div
        style={{
          display: !checkingPayment ? "block" : "none",
        }}
      >
        <Formik
          initialValues={{
            paymentMethod: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (
            values,
            { setSubmitting, setFieldError, setFieldTouched },
          ) => {
            try {
              setClaimDetails(offerToUse?.claim_details.offer_claim_id);
              setCheckingPayment(true);
              setSubmitting(true);
              setSubmit(true);
              let selectedCardId = false;
              // We've already selected a payment method, process it
              if (paymentMethod) {
                selectedCardId = paymentMethod.id;
              } else {
                // We're collecting a tip so we need to validate
                // the card (if a paymentMethod isn't already set)
                let { card, cardId, cardErrors } = await prepareStripeCard({
                  values,
                  setFieldTouched,
                  setFieldError,
                  field: "paymentMethod",
                  cardElement: elements.getElement(CardElement),
                });

                if (cardErrors?.length) {
                  setSubmitting(false);
                  setSubmit(false);
                  onPaymentFailed(cardErrors);
                  throw cardErrors;
                }

                if (cardId) {
                  setPaymentMethod(getCardDetails(cardId, project, card));
                  selectedCardId = cardId;
                }
              }

              const { payment, paymentErrors } = await sendPayment({
                total: ticket.bill_amount,
                tip: tipFieldsDisabled ? 0 : tipStorage,
                cardId: cardTotal > 0 ? selectedCardId : false,
                charge_stripe_source_id: selectedCardId,
                ...(offerToUse?.claim_details?.balance
                  ? {
                      offer_claim_id: offerToUse?.claim_details.offer_claim_id,
                    }
                  : {}),
              });
              if (paymentErrors) {
                setSubmitting(false);
                setSubmit(false);
                onPaymentFailed(paymentErrors);
                throw paymentErrors;
              }

              if (payment?.pos_success) {
                setSubmitting(false);
                setSubmit(false);
                setImg("/assets/checkout/success.svg");
                setHeader("Payment Success!");
                setBodyText("You can safely close this tab.");
                setReturnUrlOverride(payment.return_url_override);
                paymentComplete();
                onComplete(payment.return_url_override || returnUrl);
              }
            } catch (e) {
              if (e?.message?.includes("Payment is already done")) {
                setSubmitting(false);
                setSubmit(false);
                onPaymentAlreadyComplete();
              } else {
                setSubmitting(false);
                setSubmit(false);
                setCheckingPayment(false);
                onPaymentFailed(e);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ContentWrapper>
                <HeaderContent
                  displayCurrentCredit
                  offer={offerToUse}
                  project={project}
                  ticket={ticket}
                >
                  <h1>Review Payment</h1>
                </HeaderContent>
                <ScrollContent ref={scrollContainer}>
                  <div
                    className={css`
                      .section {
                        padding: 1rem 0;
                        border-bottom: 1px solid var(--color-medium-gray);

                        &:last-child {
                          border-bottom: 0;
                          padding: 0px;
                        }
                      }

                      .spaced {
                        display: flex;
                        justify-content: space-between;
                      }

                      header {
                        font-size: 1.3rem;
                        font-family: var(--font-bold);
                      }
                    `}
                  >
                    {project?.offers && (
                      <Dropdown
                        options={offerData}
                        handleOfferChange={handleOfferChange}
                        offerVal={offerVal}
                        offerAmount={offerAmount}
                        grandTotal={grandTotal}
                      />
                    )}
                    {displayGrandTotal && (
                      <header className="section spaced">
                        <strong
                          className={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <Icon
                            name="split-bill"
                            color="#03C095"
                            size={28}
                            className={css`
                              margin-right: 0.5rem;
                            `}
                          />
                          Total
                        </strong>
                        <span>{currency(grandTotal)}</span>
                      </header>
                    )}
                    <div className="section">
                      {hasCredit && (
                        <ReviewSection
                          offerData={offerData}
                          offerVal={offerVal}
                          offerAmount={offerAmount}
                          title="Charge to inKind"
                          total={
                            chargeToHA + chargeToIkPass > 0
                              ? chargeToHA + chargeToIkPass
                              : 0
                          }
                          offer={offerToUse}
                          items={[
                            {
                              title: "Charge to House Account",
                              total: chargeToHA,
                            },
                            {
                              title: "Charge to inKind Pass",
                              total: chargeToIkPass,
                            },
                          ]}
                        />
                      )}
                      {displayChargedItemsTemp && (
                        <ReviewSection
                          title={
                            paymentMethod
                              ? `Charge to ${paymentMethod.brand} *${paymentMethod.last_four}`
                              : `Charge to card`
                          }
                          offer={offerToUse}
                          total={
                            overspendAmount > 0
                              ? currency(cardTotal + overspendAmount)
                              : currency(cardTotal)
                          }
                          items={[
                            ...ticket.service_charges
                              .filter((sc) => sc.bill_card)
                              .map((sc) => {
                                return {
                                  title: sc.label,
                                  total: sc.due,
                                };
                              }),

                            {
                              ...(overspendAmount > 0
                                ? {
                                    title: hasCredit
                                      ? "Remaining bill balance"
                                      : "Bill (Food and Beverage)",
                                    total: chargeToCard,
                                  }
                                : {}),
                            },
                            {
                              title: "Tip",
                              total: tipStorage / 100,
                            },
                          ].filter((item) => item.total > 0.0)}
                        />
                      )}
                    </div>

                    {displayChargedItemsTemp && (
                      <div
                        className={classNames(
                          "section",
                          css`
                            padding-top: 1.5rem !important;
                            border-bottom: 0px !important;
                          `,
                        )}
                      >
                        <Field
                          name="paymentMethod"
                          /*title="Gratuity Payment Method"*/
                          component={PaymentOptions}
                          defaultValue={checkoutData}
                          project={project}
                          ticket={ticket}
                          onChange={(val) => {
                            setFieldValue("paymentMethod", val);
                            setCheckoutData(val);
                          }}
                        />
                      </div>
                    )}
                    {displayChargedItemsTemp && (
                      <HelpText
                        className={classNames(
                          "section",
                          css`
                            margin-top: 0;
                          `,
                        )}
                      >
                        {showChargeWhy && (
                          <div
                            className={css`
                              text-align: left;
                              margin-top: 1rem;
                              font-size: 0.85rem;
                            `}
                          >
                            <strong
                              className={css`
                                display: block;
                                margin-bottom: 0.5rem;
                              `}
                            >
                              About Gratuity and Fees
                            </strong>
                            <div>
                              House accounts are used to support your favorite
                              restaurants and small businesses. By keeping House
                              Accounts strictly redeemable for food and beverage
                              you further help the restaurant and allow for the
                              gratuity amount to be passed to their amazing
                              staff.
                            </div>
                          </div>
                        )}
                      </HelpText>
                    )}
                  </div>
                  <div
                    className="loaderContainer"
                    className={css`
                      display: ${!!submit ? "block" : "none"};
                    `}
                  >
                    <Loader
                      className={css`
                        margin: 0 auto;
                        margin-top: 40px;
                        margin-bottom: 20px;
                      `}
                    />
                    <h4
                      className={css`
                        text-align: center;
                      `}
                    >
                      Running your card, please wait
                    </h4>
                  </div>
                </ScrollContent>
                <FixedContent>
                  {earnBackAmount !== null &&
                  overspendAmount * earnBackAmount > 0 ? (
                    <div className="checkout-earn-back-container">
                      <img
                        className="checkout-earn-back-icon"
                        src={EarnBackIcon}
                      />
                      {overspendAmount > chargeToIkPass ? (
                        <>
                          {" "}
                          <p className="checkout-earn-back-title">
                            You're earning{" "}
                            <span className="checkout-earn-back-amount">
                              {numeral(overspendAmount * earnBackAmount).format(
                                "$0,0.00",
                              )}
                            </span>{" "}
                            back today
                          </p>
                          <p className="checkout-earn-back-sub-title">
                            Thanks to your inKind Pass Subscription
                          </p>
                        </>
                      ) : (
                        <p className="checkout-ikpass">
                          Hooray! Your inKind balance fully covers your food and
                          beverage today.
                        </p>
                      )}
                    </div>
                  ) : null}
                  <FullButton
                    type="submit"
                    disabled={!stripe || submit}
                    onClick={() => {
                      setPaymentProcessing(true);
                    }}
                  >
                    Pay
                  </FullButton>
                </FixedContent>
              </ContentWrapper>
            </Form>
          )}
        </Formik>
      </div>
      {billPaid && (
        <div>
          <ScrollContent minHeightOverride={370} />
          <FixedContent>
            <FullButton
              type="submit"
              onClick={() => {
                redirectToVendor(returnUrlOverride || returnUrl);
              }}
            >
              Return to App
            </FullButton>
          </FixedContent>
        </div>
      )}
    </>
  );
};

export default CheckoutReview;
