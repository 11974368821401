import React from "react";
import icons from "@lib/icons";
import styled from "@emotion/styled";

const Ico = styled.i`
  display: inline-block;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  mask-size: contain;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background-size: contain;
  background-position: center;
  -webkit-mask-image: ${(p) => `url("${p.icon}")`};
  mask-image: ${(p) => `url("${p.icon}")`};
  background-color: ${(p) => p.color};
`;

const Icon = ({ name, color, size, className }) => {
  return (
    <Ico color={color} icon={icons[name]} size={size} className={className} />
  );
};

Icon.defaultProps = {
  color: "black",
  size: 25,
};

export default Icon;
