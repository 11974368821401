/**
 * Example:
 * import useBreakpoint from './useBreakpoint'
 *
 * const MyComponent = () => {
 *   const breakpoint = useBreakpoint();
 *
 *   {breakpoint.isMdUp && <AnotherComponent />}
 *   {breakpoint.xl && <AnotherComponent />}
 * };
 */

import { useState, useEffect, useMemo } from "react";
import throttle from "lodash.throttle";

const breakpointValues = ["xs", "sm", "md", "lg", "xl"];

const getBreakpoint = (width) => {
  if (width < 576) {
    return 0;
  } else if (width >= 576 && width < 768) {
    return 1;
  } else if (width >= 768 && width < 992) {
    return 2;
  } else if (width >= 992 && width < 1200) {
    return 3;
  } else if (width >= 1200) {
    return 4;
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getBreakpoint(window.innerWidth));
    }, 200);

    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  const breakpoint = useMemo(() => {
    return {
      breakpoint: breakpointValues[brkPnt],
      xs: brkPnt === 0,
      sm: brkPnt === 1,
      md: brkPnt === 2,
      lg: brkPnt === 3,
      xl: brkPnt === 4,
      isSmUp: brkPnt >= 1,
      isMdUp: brkPnt >= 2,
      isLgUp: brkPnt >= 3,
      isXlUp: brkPnt === 4,
      isSmDown: brkPnt <= 1,
      isMdDown: brkPnt <= 2,
      isLgDown: brkPnt <= 3,
      isXlDown: brkPnt <= 4,
    };
  }, [brkPnt]);

  return breakpoint;
};

export default useBreakpoint;
