import React from "react";
import OfferInput from "./OfferInput";

const OfferCode = ({ title, placeholder, handleCodeSubmit, loading }) => {
  function handleSubmit(e) {
    e.preventDefault();
    handleCodeSubmit(e.target[0].value);
  }

  return (
    <div className="claim-container">
      <div className="enter-code-header">{title}</div>
      <div className="divider-new"></div>
      <form
        className="email-container input-form"
        onSubmit={(e) => handleSubmit(e)}
      >
        <OfferInput
          name={"Code"}
          placeholder={placeholder}
          buttonShow={true}
          buttonText={"Next"}
          type={"text"}
          value={""}
          loading={loading}
        />
      </form>
    </div>
  );
};

export default OfferCode;
