import React, { useState, useEffect, useRef } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Input from "../../ui/atoms/Input";
import { Button } from "../../ui/atoms/Button";
import validator from "validator";
import { formatPhoneNumber } from "../../lib/helpers";
import { motion, AnimatePresence } from "framer-motion";
import { opacify } from "polished";
import { entries } from "core-js/core/array";
import { root } from "postcss";

const ContactForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const maxLength = 700;
  const sectionRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    gifts: "",
    notes: "",
  });
  const [errors, setErrors] = useState({ email: "", phone: "" });
  const [loading, setLoading] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setFormData({ ...formData, name: value });
        break;
      case "email":
        const result = value;
        if (result) setErrors({ ...errors, email: "" });
        else setErrors({ ...errors, email: "Please enter a valid email" });
        setFormData({ ...formData, email: value });
        break;
      case "phone":
        if (value.length === 1 && formData.phone.length === 3) {
          setErrors({ ...errors, phone: "" });
          setFormData({ ...formData, phone: "" });
          return;
        }
        if (value.length === 18) return;
        if (validator.isMobilePhone(value)) setErrors({ ...errors, phone: "" });
        else
          setErrors({ ...errors, phone: "Please enter a valid phone number" });
        if (value.length < 18) setFormData({ ...formData, phone: value });
        break;
      case "company":
        setFormData({ ...formData, company: value });
        break;
      case "gifts":
        setFormData({ ...formData, gifts: value });
        break;
      case "notes":
        setText(e.target.value);
        setFormData({ ...formData, notes: value });
        break;
      default:
        break;
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    const { email, phone } = formData;
    const emailValidated = !!(
      email &&
      validator.isEmail(email) &&
      !errors.email
    );
    const phoneValidated = !!(
      (!phone || (phone && validator.isMobilePhone(phone))) &&
      !errors.phone
    );
    if (!emailValidated)
      await setErrors({ ...errors, email: "Please enter a valid email" });
    if (!phoneValidated) setErrors({ ...errors, phone: "" });
    if (emailValidated && phoneValidated) {
      console.log("Form submitted", formData);
      async function saveContactData() {
        setLoading(true);
        const headers = {
          "content-type": "application/json",
        };
        await fetch("/api/v5/gifting_inquiry", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            data: formData,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            // else {
            //   setError(true);
            //   return response.json();
            // }
          })
          .then((response) => {
            setSubmittedForm(true);
            // Do something with the response
          })
          .catch((err) => {
            console.log("err", err);
            setLoading(false);
          });

        setLoading(false);
      }
      saveContactData();
    }
  };
  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      const calcElement = document.getElementById("calculator");
      const observeSection = () => {
        if (!calcElement) return;
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsOpen(!isOpen);
            }
          });
        });
        observer.observe(calcElement as Element);
      };
      observeSection();
    }, 2000);
  }, []);

  return (
    <motion.div
      layout
      style={{ height: isOpen ? "auto" : "auto" }}
      className="gifting-contact-form-container"
    >
      <motion.button
        layout
        className="gifting-toggle-button"
        onClick={toggleForm}
      >
        Have any questions?{" "}
        <span>
          {isOpen ? (
            <ChevronUpIcon className="chevron" />
          ) : (
            <ChevronDownIcon className="chevron" />
          )}
        </span>
      </motion.button>
      {isOpen && (
        <motion.div
          layout
          initial={{ opacity: 0, y: 12 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className={`gifting-form-content`}
        >
          {!submittedForm ? (
            <form>
              <Input
                name="name"
                onChange={onChange}
                label="Name"
                showLabel={true}
              />
              <div className="two-col input-gaps">
                <Input
                  name={"email"}
                  type="email"
                  error={errors.email}
                  onChange={onChange}
                  isRequired={true}
                  label="Email"
                  showLabel={true}
                />
                <Input
                  value={formatPhoneNumber(formData.phone)}
                  name="phone"
                  error={errors.phone}
                  onChange={onChange}
                  label="Phone number"
                  showLabel={true}
                />
              </div>
              <div className="two-col input-gaps">
                <Input
                  name="company"
                  onChange={onChange}
                  label="Company Name"
                  showLabel={true}
                />
                <Input
                  name="gifts"
                  onChange={onChange}
                  label="Estimated number of gifts"
                  showLabel={true}
                />
              </div>
              <div className="gifting-textarea">
                <label htmlFor="notes">Additional Notes</label>
                <textarea
                  name="notes"
                  onChange={onChange}
                  name="notes"
                  cols="33"
                  rows="10"
                  maxLength={maxLength}
                />
                <span>
                  {text.length}/{maxLength}
                </span>
              </div>
              <Button
                onClick={submit}
                buttonClasses="gifting-submit-btn"
                type="button"
                children="Send"
              />
            </form>
          ) : (
            <motion.div
              layout
              initial={{ opacity: 0, y: 12 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="gift-success-container"
            >
              <h4>Message sent!</h4>
              <p>Someone from inKind will get in touch with you!</p>
              <Button
                onClick={toggleForm}
                buttonClasses="gifting-done-btn"
                type="button"
                children="Done"
              />
            </motion.div>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default ContactForm;
