import App from "./App";
import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { handleAmexLoad } from "../../helpers/handleAmexLoad";

const checkout = (presenter) => {
  const [isAmex, setIsAmex] = React.useState(
    window.location.pathname.split("/")[1] === "amex-business-special" ||
      new URLSearchParams(window.location.search).get("utm_campaign") ===
        "amex-business-special",
  );
  const [stripePromise, setStripePromise] = React.useState(null);
  const ikPass = !!presenter.name;
  React.useEffect(() => {
    setStripePromise(loadStripe(presenter.data_stripe_pk));
  }, [presenter]);

  React.useEffect(() => {
    if (!!isAmex) {
      handleAmexLoad(presenter.am_token);
    }
  }, []);

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <App presenter={presenter} />
      </Elements>
    </Provider>
  );
};

export default checkout;
