import React from "react";
import CurrentCredit from "@/checkout/components/current-credit";
import ConditionalRender from "@/shared/conditional-render";
import { css } from "@emotion/css";
import mediaQuery from "@lib/mediaQueries";

const HeaderContent = ({
  children,
  displayCurrentCredit,
  project,
  ticket,
  offer,
}) => {
  const headerStyle = css`
    padding-top: ${displayCurrentCredit ? "0.75rem" : "1.75rem"};
    padding-left: var(--checkout-xpad);
    padding-right: var(--checkout-xpad);
    color: var(--color-black);

    ${mediaQuery.md} {
      padding-top: ${displayCurrentCredit ? "1.43rem" : "2.56rem"};
    }

    h1 {
      text-align: left;
      font-size: 1.25rem;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 1rem;
    }
  `;

  return (
    <header className="headerContent">
      <ConditionalRender shouldRender={displayCurrentCredit}>
        <CurrentCredit project={project} ticket={ticket} offer={offer} />
      </ConditionalRender>
      <div className={headerStyle}>{children}</div>
    </header>
  );
};

HeaderContent.defaultProps = {
  displayCurrentCredit: false,
};

export default HeaderContent;
