import React from "react";
import ContentWrapper from "@/shared/content-wrapper";
import HeaderContent from "@/shared/header-content";
import ScrollContent from "@/shared/scroll-content";
import useCookie from "@lib/use-cookie";
import NewLoginCreateAccount from "../new-login-create-account/NewLoginCreateAccount.jsx";

const CheckoutLogin = ({ country, onComplete }) => {
  const [cookie, updateCookie] = useCookie("userToken", null);

  return (
    <ContentWrapper>
      <HeaderContent></HeaderContent>
      <br />
      <ScrollContent minHeightOverride={200}>
        <NewLoginCreateAccount
          country={country}
          isBslCheckout={true}
          setUser={onComplete}
          siteArea={"redeem_checkout"}
        />
      </ScrollContent>
    </ContentWrapper>
  );
};

export default CheckoutLogin;
