import React from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { currency } from "@lib/helpers";
import numeral from "numeral";
const Section = styled.div`
  border-bottom: 1px solid var(--color-medium-gray);
  padding: 1rem 0;
  font-size: 1rem;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-bold);
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const Items = styled.ul`
  --itemSpacing: 30px;
  --bulletSize: 7px;
  margin: 0;
  padding: 0 0 0 0.25rem;
  font-size: inherit;

  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-left: 1rem;
    color: var(--color-dark-gray);
    line-height: var(--itemSpacing);

    &.offer {
      span {
        color: #1dc195;
        font-family: "Rethink-Sans-Bold";
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: var(--bulletSize);
      height: var(--bulletSize);
      transform: translateY(-50%);
      background-color: var(--color-medium-gray);
      border-radius: 50%;
    }

    &:not(:first-of-type):after {
      content: "";
      width: 1px;
      height: calc(var(--itemSpacing) - calc(var(--bulletSize) * 2));
      position: absolute;
      left: calc(var(--bulletSize) * 0.5);
      bottom: 50%;
      border-left: 1px solid var(--color-medium-gray);
      transform: translateY(calc(var(--bulletSize) * -1));
    }
  }
`;

const ReviewSection = ({ title, total, items, offer, offerVal, offerData }) => {
  const itemsReduced =
    items.reduce((acc, cur) => acc + cur.total, 0) +
    (!offerData?.claim_details?.balance
      ? offer?.claim_details?.balance
      : (offerData?.claim_details?.balance ?? 0));
  const itemsUse = items?.filter((item) => item.total > 0);
  return (
    <Section>
      <Items>
        {offer && offerVal ? (
          <li className="offer">
            <span
              className={css`
                display: ${offerVal === "Select one..." ? "none" : "inline"};
              `}
            >
              {offerVal}
            </span>
            {offer?.claim_details?.balance && (
              <span>
                -
                {numeral(
                  itemsReduced < offer?.claim_details.balance
                    ? itemsReduced
                    : offer?.claim_details.balance,
                ).format("$0,0.00")}
              </span>
            )}
          </li>
        ) : null}

        {itemsUse && itemsUse.length > 0 && (
          <>
            {itemsUse.map((item, i) => (
              <li key={i}>
                <span>{item.title}</span>
                <span>{numeral(item.total).format("$0,0.00")}</span>
              </li>
            ))}
          </>
        )}
      </Items>

      <Header>
        <span>{title}</span>
        <span>
          {total
            ? numeral(total).format("$0,0.00")
            : itemsReduced - (offer?.claim_details?.balance || 0.0) <= 0
              ? "$0.00"
              : numeral(
                  itemsReduced - (offer?.claim_details?.balance || 0),
                ).format("$0,0.00")}
        </span>
      </Header>
    </Section>
  );
};

export default ReviewSection;
