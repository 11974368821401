/**
 *
 * @param state
 * @param action
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        error: null,
        status: "pending",
        refreshing: false,
      };
    case "success":
      return {
        error: null,
        status: "resolved",
        response: action.payload,
        refreshing: false,
      };
    case "refresh":
    case "manual_fetch":
      return {
        ...state,
        status: state.status === "idle" ? "pending" : state.status,
        refreshing: state.status !== "pending",
        error: null,
      };
    case "refresh_success":
    case "manual_fetch_success":
      return {
        ...state,
        status: "resolved",
        response: action.payload,
        refreshing: false,
      };
    default:
      return state;

    case "update":
      return {
        ...state,
        ...action.payload,
      };
    case "fail":
    case "refresh_fail":
    case "manual_fetch_fail":
      return {
        ...state,
        error: action.payload,
        status: "rejected",
      };
  }
};
