import React from "react";
import { css } from "@emotion/css";
import classNames from "classnames";

const MessageWrapper = ({ children, className }) => {
  return (
    <div
      className={classNames(
        className,
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 100%;
          color: var(--color-dark-gray);

          h3 {
            font-size: 1.625rem;
            color: var(--color-black);
            margin: 0 0 0.5rem;
          }

          p {
            font-size: 1rem;
            max-width: 345px;
            text-align: center;
          }
        `,
      )}
    >
      {children}
    </div>
  );
};

export default MessageWrapper;
