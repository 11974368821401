import { useState, useEffect } from "react";

function useIsMobile(mobileWidth = 600) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

  useEffect(() => {
    // Function to update the state based on window width
    function handleResize() {
      setIsMobile(window.innerWidth < mobileWidth);
    }

    // Adding event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

export default useIsMobile;
