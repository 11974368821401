import React from "react";

const ReturnUserNoLongerAvailable = ({
  expirationDays,
  isNewUserOnly,
  tiers,
  ikname,
}) => {
  return (
    <div className="return-user-no-longer-available">
      <div className="return-user-no-longer-available-top">
        <div className="header">
          {isNewUserOnly
            ? "This offer is for new guests only"
            : "This offer is no longer available"}
        </div>
      </div>
      <div className="divider-new"></div>
      <div className="return-user-no-longer-available-bottom">
        <div className="subheader">
          Looking for a bonus? You can still get a bonus when you add funds to
          your inKind account.
        </div>
        <div className="tier-buttons-container">
          {tiers.map((tier) => {
            const { ico_id, pay_amount, get_amount, rounded_bonus_amount } =
              tier;
            return (
              <a
                href={
                  window.location.origin.replace("app.", "pass.") +
                  "/checkout?ico_id=" +
                  ico_id
                }
                key={ico_id}
              >
                <div className="tier-button">
                  <div className="tier-button-text">
                    <div className="tier-main-text">
                      Pay ${pay_amount},&nbsp;
                      <span className="bold-text green-text">
                        get ${get_amount}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`tier-button-bonus bold-text ${
                      isNewUserOnly ? "new-user-only" : ""
                    }`}
                  >
                    ${rounded_bonus_amount} bonus
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className="return-user-no-longer-available-footer">
        <div>
          You must have the {ikname} app to use at participating restaurants.
        </div>
        <div className="org-tos">
          {`Offers may not be combined with any other offer. 
            ${
              expirationDays
                ? `Offer is valid for ${expirationDays} day${
                    expirationDays === 1 ? "" : "s"
                  } after claiming.`
                : ""
            }`}
        </div>
      </div>
    </div>
  );
};

export default ReturnUserNoLongerAvailable;
