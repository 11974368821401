import React from "react";
import { css } from "@emotion/css";

const FooterPoweredBy = () => {
  return (
    <div
      className={css`
        text-align: center;
      `}
    >
      <img
        src="/assets/checkout/inKind_Powered_By.png"
        className={css`
          max-width: 150px;
          height: auto;
        `}
      />
    </div>
  );
};

export default FooterPoweredBy;
