import React from "react";
import EmailIcon from "../../../assets/images/icons/email-yellow-icon.svg";

const ConfirmEmailToast = ({ setShowToast }) => {
  const [toastHide, setToastHide] = React.useState("");

  React.useEffect(() => {
    setTimeout(() => {
      setToastHide("hide-toast");
    }, 3000);
  }, []);

  React.useEffect(() => {
    if (toastHide === "hide-toast") {
      setTimeout(() => {
        setShowToast(false);
      }, 1000);
    }
  }, [toastHide]);

  return (
    <div className={`login-ca__toast-container ${toastHide}`}>
      <img
        src={EmailIcon}
        alt="Check your email"
        className="login-ca__toast-icon"
      />
      <div className="login-ca__toast-text">
        <div className="login-ca__toast-header">Verify your email address</div>
        <div className="login-ca__toast-subheader">
          Confirm the email sent from inKind
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailToast;
