import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
const PartnerMainPage = lazy(() => import("./pages/PartnerMainPage"));

const App = ({ project, cloudinary }) => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="marcus-mode "></div>}>
          <PartnerMainPage project={project} cloudinary={cloudinary} />
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
