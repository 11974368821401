import React from "react";
import { createEmailRegex } from "@lib/helpers";
import OfferInput from "./OfferInput";
import ForgotPassword from "../newCheckout/components/ForgotPassword";

const SignIn = ({
  email,
  handleLogin,
  sendType,
  showError,
  errorMessage,
  expirationDays,
  userPassword,
  setUserPassword,
  ikname,
  inkindStateRequiredEmails,
  inkindStateRequiredEmailsDisplay,
  loading,
  handleAmplitude,
}) => {
  let emailRegex = createEmailRegex(inkindStateRequiredEmails);
  const [resetEmail, setResetEmail] = React.useState(email);
  let type = sendType === "phone" ? "phone number" : "email";

  return (
    <div className="claim-container">
      <div className="header">
        <h5 className="signin-header">Sign In</h5>
        <p className="signin-subheader">Welcome back!</p>
      </div>
      <div className="divider-new"></div>
      <form className="input-form signin-form" onSubmit={(e) => handleLogin(e)}>
        <p className="signin-text-info">
          It looks like you already have a {ikname} account associated with this{" "}
          {type}. Please sign in.
        </p>
        <div className="signin-claim-container">
          <OfferInput
            name={"Email"}
            placeholder={"Email"}
            buttonShow={false}
            buttonText={""}
            type={"email"}
            regex={emailRegex}
            title={inkindStateRequiredEmailsDisplay}
            value={email}
          />
          <OfferInput
            name={"Password"}
            placeholder={"Password"}
            buttonShow={false}
            buttonText={""}
            type={"password"}
            regex={"^.{6,}$"}
            title={"Please enter a valid phone password."}
            setUserPassword={setUserPassword}
            // handleAmplitude={handleAmplitude}
          />
        </div>
        <div
          className="error-message"
          style={{
            display: !showError ? "none" : "",
            color: "red",
            paddingTop: "12px",
          }}
        >
          {errorMessage}
        </div>
        <button
          type="submit"
          className={
            userPassword ? "full-width-button" : "signin-inactive-button"
          }
          disabled={!userPassword}
        >
          {loading ? (
            <div
              className="loading-container"
              style={{ display: loading === true ? "" : "none" }}
            >
              <div className="loading-screen">
                <div className="loading-ic"></div>
              </div>
            </div>
          ) : (
            "Sign In"
          )}
        </button>
        <div className="org-tos">
          <ForgotPassword
            email={resetEmail}
            setEmail={setResetEmail}
            handleAmplitude={handleAmplitude}
          />
        </div>
      </form>
      <div className="signin-footer">
        <div className="highlight">
          You must have the {ikname} app to use at participating restaurants.
        </div>
        <div className="offer-details">
          {expirationDays
            ? `Offer is valid for ${expirationDays} day${
                expirationDays === 1 ? "" : "s"
              } after claiming.`
            : ""}{" "}
          By continuing you agree to our terms of service and privacy policy.
          Offers may not be combined with any other offer.
        </div>
      </div>
    </div>
  );
};

export default SignIn;
