import React from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import { MinusIcon } from "@heroicons/react/20/solid";
import axios from "@lib/axios";
import InputLayout from "../../../ui/molecules/InputLayout.tsx";
import { setNewEmail } from "../../newCheckout/redux/slices/userSlice";
const EmailCapture = ({ projectId }) => {
  let [loading, setLoading] = React.useState(false);
  let [success, setSuccess] = React.useState(false);
  let [email, setEmail] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    setLoading(true);
    try {
      const url = `/api/v2/projects/${projectId}/inquiry`;
      await axios.post(url, {
        inquiry: {
          email: data.email,
        },
      });
      setSuccess(true);
      setEmail(data.email);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      {!success ? (
        <form
          className="email-capture-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputLayout
            label="Your email"
            error={
              errors.email?.type === "required"
                ? "An email address is required"
                : errors.email?.type === "validate"
                  ? "Invalid email"
                  : null
            }
          >
            <input
              {...register("email", {
                required: true,
                validate: (value) => {
                  return validator.isEmail(value);
                },
              })}
              type="email"
              name="email"
              aria-label="email"
              placeholder=" "
              className="floating-label-input"
            />
          </InputLayout>

          <button
            type="submit"
            disabled={loading}
            className={`submit-email ${loading ? "loader" : ""}`}
          >
            {loading ? <MinusIcon className="spinner" /> : "Submit"}
          </button>
        </form>
      ) : (
        <div className="success-div regular-font">
          <span>
            Thanks! We will keep you updated at{" "}
            <span className="font-bold">{email}</span>
          </span>
        </div>
      )}
    </>
  );
};

export default EmailCapture;
