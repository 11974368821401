import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import numeral from "numeral";
import CustomCurrencyInput from "./CustomCurrencyInput";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "@headlessui/react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import sosPhone from "../../../../assets/images/corporate/sos_phone_img-min.png";
import queryString from "query-string";

const SavingsCalculator = ({
  bulkGiftTiers,
  border = false,
  primary = "#1DC195",
  bg = "#272727",
  buttonColor = "#ffffff",
  sos,
}) => {
  const tiersUse = bulkGiftTiers?.bulk_gift_tiers;
  const presetsUse = bulkGiftTiers?.bulk_gift_presets;
  let numsToUse;
  let options;
  let startingValue;

  if (tiersUse?.length === 4) {
    numsToUse = [
      {
        percent: !!sos
          ? 0
          : Math.round(Number(tiersUse[0].discount) * 1000) / 10,
        min: null,
        max: Number(tiersUse[0].max),
      },
      {
        percent: !!sos
          ? 0
          : Math.round(Number(tiersUse[1].discount) * 1000) / 10,
        min: Number(tiersUse[1].min),
        max: Number(tiersUse[1].max),
      },
      {
        percent: !!sos
          ? 0
          : Math.round(Number(tiersUse[2].discount) * 1000) / 10,
        min: Number(tiersUse[2].min),
        max: Number(tiersUse[2].max),
      },
      {
        percent: !!sos
          ? 0
          : Math.round(Number(tiersUse[3].discount) * 1000) / 10,
        min: Number(tiersUse[3].min),
        max: null,
      },
    ];
  } else {
    numsToUse = [
      { percent: !!sos ? 0 : 10, min: null, max: 5000 },
      { percent: !!sos ? 0 : 15, min: 5000, max: 10000 },
      { percent: !!sos ? 0 : 20, min: 10000, max: 25000 },
      { percent: !!sos ? 0 : 25, min: 25000, max: null },
    ];
  }

  if (presetsUse?.length === 4) {
    options = [
      Number(presetsUse[0].amount),
      Number(presetsUse[1].amount),
      Number(presetsUse[2].amount),
      Number(presetsUse[3].amount),
    ];
    let defaultVals = presetsUse.filter((preset) => preset.default === true);
    startingValue = Number(
      defaultVals.length > 0 ? defaultVals[0].amount : presetsUse[2].amount,
    );
  } else {
    options = [25, 50, 100, 250];
    startingValue = 100;
  }

  const startingTier = getTier(numsToUse, startingValue * 125);
  let startingPercent = getPercent(
    getTierNum(numsToUse, startingValue * 125) * 33.34,
    startingTier.min ?? 0,
    startingTier.max ?? 0,
    startingValue * 125,
  );

  let [amount, setAmount] = React.useState(startingValue);

  library.add(faCheckCircle, faCheck);

  let [isCustom, setIsCustom] = React.useState(false);

  let bonus = false;

  const qString = queryString.stringify(queryString.parse(location.search));

  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: false,
    integerLimit: 5, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const recipMask = createNumberMask(defaultMaskOptions);

  let [recipients, setRecipients] = React.useState(125);
  let [beforeSavings, setBeforeSavings] = React.useState(startingValue * 125);
  let [afterSavings, setAfterSavings] = React.useState(
    startingValue * 125 * (1 - startingTier.percent / 100),
  );

  let [percent, setPercent] = React.useState(startingPercent);
  let [curPercent, setCurPercent] = React.useState(0);

  React.useEffect(() => {
    setCurPercent(100 - Math.round((afterSavings / beforeSavings) * 100));
  }, [beforeSavings, afterSavings]);

  function handleAmountChange(e) {
    setAmount(e);
    setBeforeSavings(e * recipients);
    getPercentAndTier(e * recipients);
  }

  function getTier(nums, value) {
    for (let tier in nums) {
      if (
        (value >= nums[tier].min && value < nums[tier].max) ||
        (value >= nums[tier].min && !nums[tier].max) ||
        (value < nums[tier].max && !nums[tier].min)
      ) {
        return nums[tier];
      }
    }
  }

  function getTierNum(nums, value) {
    for (let tier in nums) {
      if (
        (value >= nums[tier].min && value < nums[tier].max) ||
        (value >= nums[tier].min && !nums[tier].max) ||
        (value < nums[tier].max && !nums[tier].min)
      ) {
        return Number(tier);
      }
    }
  }

  function handleRecipChange(e) {
    let numberfied = Number(e.replace(/\$|,/g, ""));
    setRecipients(numberfied);
    setBeforeSavings(amount * numberfied);
    getPercentAndTier(amount * numberfied);
  }

  function getPercent(start, min, max, value) {
    //Based on a) which tier the user is purchased in, b) that tier's min and max, and
    //c) the value of their purchase, calculate what the percent bar display should show.
    const tierWidth = max - min;
    return (
      start + ((tierWidth - (tierWidth - (value - min))) / tierWidth) * 33.3
    );
  }

  function getAmount(numberfied, recipientsUse, val) {
    //Get value for each individual purchase based on total payment value, number of recips, and current tier percent
    return numberfied / recipientsUse / (1 - Number(val) / 100);
  }

  function getPayPercent(initNum, initPercent) {
    //From a discount percent (eg, 20%) and an initial price (eg, $2000), get the final payment value (eg, $1600)
    //by multiplying by a precentage of 1 calculated from the discount (eg, .8 for 20%)
    return Number(initNum) * (1 - Number(initPercent) / 100);
  }

  function setAfterField(e) {
    //Using current post-discount purchase amount, find tier.
    //Once tier is identified, calculate UI percentage bar display,
    //and determine subtotal (pre-discount) amount
    let numberfied = Number(e.replace(/\$|,/g, ""));
    let recipientsUse = !!recipients && recipients > 0 ? recipients : 1;
    let firstNum = getPayPercent(numsToUse[1].min, numsToUse[1].percent); //third-highest percent
    let secondNum = getPayPercent(numsToUse[2].min, numsToUse[2].percent); //second-highest percent
    let thirdNum = getPayPercent(numsToUse[3].min, numsToUse[3].percent); //highest percent
    setAfterSavings(numberfied);
    if (!e) {
      return;
    }
    let amount;
    if (numberfied >= firstNum && numberfied < secondNum) {
      amount = getAmount(numberfied, recipientsUse, numsToUse[1].percent); //third-highest percent
      setAmount(amount);
      setBeforeSavings(amount * recipientsUse);
      const percentBar = getPercent(
        33.4,
        numsToUse[1].min,
        numsToUse[1].max,
        amount * recipientsUse,
      );
      setPercent(percentBar);
    } else if (numberfied >= secondNum && numberfied < thirdNum) {
      amount = getAmount(numberfied, recipientsUse, numsToUse[2].percent); //second-highest percent
      setAmount(amount);
      setBeforeSavings(amount * recipientsUse);
      const percentBar = getPercent(
        66.7,
        numsToUse[2].min,
        numsToUse[2].max,
        amount * recipientsUse,
      );
      setPercent(percentBar);
    } else if (numberfied >= thirdNum) {
      let amount = getAmount(numberfied, recipientsUse, numsToUse[3].percent); //highest percent
      setAmount(amount);
      setBeforeSavings(amount * recipientsUse);
      setPercent(100);
    } else {
      amount = getAmount(numberfied, recipientsUse, numsToUse[0].percent); //lowest percent
      const percentBar = getPercent(
        0,
        numsToUse[0].min,
        numsToUse[0].max,
        amount * recipientsUse,
      );
      setPercent(percentBar);
      setAmount(amount);
      setBeforeSavings(amount * recipientsUse);
    }
  }

  function handleCustomAmount(e) {
    let numberfied = Number(e.replace(/\$|,/g, ""));
    setAmount(numberfied);
    setBeforeSavings(Number(numberfied) * recipients);
    getPercentAndTier(numberfied * recipients);
  }

  function getPercentAndTier(beforeNumber) {
    //Using current initial (pre-discount) purchase amount, find current tier.
    //Once tier is identified, calculate UI percentage bar display,
    //and determine savings by subtracting tier's discount % from initial amount.
    if (beforeNumber > 0 && beforeNumber < numsToUse[0].max) {
      const percentBar = getPercent(
        0,
        numsToUse[0].min,
        numsToUse[0].max,
        beforeNumber,
      );
      setPercent(percentBar);
      setAfterSavings(
        getPayPercent(beforeNumber, Number(numsToUse[0].percent)),
      );
    } else if (
      beforeNumber >= numsToUse[1].min &&
      beforeNumber < numsToUse[1].max
    ) {
      const percentBar = getPercent(
        33.4,
        numsToUse[1].min,
        numsToUse[1].max,
        beforeNumber,
      );
      setPercent(percentBar);
      setAfterSavings(
        getPayPercent(beforeNumber, Number(numsToUse[1].percent)),
      );
    } else if (
      beforeNumber >= numsToUse[2].min &&
      beforeNumber < numsToUse[2].max
    ) {
      const percentBar = getPercent(
        66.7,
        numsToUse[2].min,
        numsToUse[2].max,
        beforeNumber,
      );
      setPercent(percentBar);
      setAfterSavings(
        getPayPercent(beforeNumber, Number(numsToUse[2].percent)),
      );
    } else if (beforeNumber >= numsToUse[3].min) {
      setAfterSavings(
        getPayPercent(beforeNumber, Number(numsToUse[3].percent)),
      );
      setPercent(100);
    } else {
      setAfterSavings(beforeNumber);
      setPercent(0);
    }
  }

  return (
    <div id="calculator" className="popout-container">
      <figure className="modal-padding-savings">
        <div
          className="popout-modal popout-modal-savings popout-modal-header"
          style={{ display: "none" }}
        >
          <div className="selected">Get a discount</div>
          <div>Get a bonus</div>
        </div>
        <div
          className={
            !!sos ? "savings-modal-grid popout-modal" : "savings-modal-grid"
          }
        >
          <section
            className={
              !!sos
                ? "left-col savings"
                : "left-col savings popout-modal popout-modal-savings"
            }
          >
            <div className="text-col">
              <div className="flex-col">
                <h6
                  className="big-header"
                  style={{ textAlign: "left", marginBottom: "20px" }}
                >
                  {!!sos
                    ? "Build your voucher order"
                    : "See how much you'll save with inKind."}
                </h6>
                <h6>Recipients</h6>
                <span>
                  Enter the number of recipients. You can add more later.
                </span>
              </div>

              <MaskedInput
                style={{ marginTop: "8px" }}
                min="0"
                value={recipients !== 0 ? recipients : ""}
                onChange={({ target }) => handleRecipChange(target.value)}
                onFocus={(e) => e.target.select()}
                className="mass-gifting-input regular-font"
                placeholder="Number of recipients"
                mask={recipMask}
                inputMode="numeric"
                name="recip-input"
              />
            </div>
            <div className="text-col">
              <div className="flex-col">
                <h6>Amount per recipient</h6>
                <span>Enter the amount each person would receive.</span>
              </div>
              <div className="login-form">
                <div className="mass-gifting-amount-row inCalculator">
                  {options.map((option, i) => (
                    <button
                      onClick={() => {
                        handleAmountChange(option);
                        setIsCustom(false);
                      }}
                      key={i}
                      className={`${amount === option ? "active" : ""}`}
                      style={{
                        backgroundColor: amount === option ? primary : "",
                        borderColor: amount === option ? primary : "",
                      }}
                    >
                      ${option}
                    </button>
                  ))}
                </div>
                <div className="input-error-col">
                  <div className="currency-relative-div">
                    <CustomCurrencyInput
                      setError={() => {}}
                      setCurInput={() => {
                        setIsCustom(true);
                      }}
                      setCurrency={handleCustomAmount}
                      currency={amount}
                      placeholder="Custom amount"
                      maxDigits={5}
                      mgCalc={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ position: "relative", marginBottom: "20px" }}>
              <hr className="savings-hr" />
              <h6
                style={{
                  color: "#999",
                  position: "absolute",
                  top: "-10px",
                  textAlign: "center",
                  left: "0px",
                  right: "0px",
                }}
              >
                <span
                  style={{
                    width: "50px",
                    background: "white",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                >
                  OR
                </span>
              </h6>
            </div>

            <div className="text-col">
              <div className="flex-col">
                <h6>Tell us your total budget</h6>
                <span>We'll calculate how much each recipient receives.</span>
              </div>

              <div className="input-error-col" style={{ marginTop: "8px" }}>
                <div className="currency-relative-div">
                  <CustomCurrencyInput
                    setError={() => {}}
                    setCurInput={() => {}}
                    setCurrency={setAfterField}
                    currency={afterSavings < 10000000 ? afterSavings : 0}
                    placeholder="Total budget"
                    maxDigits={7}
                    mgCalc={true}
                  />
                </div>
              </div>
              <div style={{ display: !!sos ? "" : "none" }}>
                <a
                  href={
                    (!window.location.origin.includes("://app.")
                      ? window.location
                      : window.location.origin.replace(
                          "://app.",
                          "://business.",
                        )) + `/checkout/?spend=${amount}`
                  }
                  style={{ color: "inherit" }}
                >
                  <button
                    className="start-your-order"
                    style={{ backgroundColor: primary, color: buttonColor }}
                  >
                    Start your order
                  </button>
                </a>
              </div>
            </div>
          </section>
          <section
            className="savings-section flex-savings"
            style={{ display: !sos ? "none" : "" }}
          >
            <div>
              <img
                src={sosPhone}
                className="sos-calc-image"
                alt="SOS calculator image"
              />
            </div>
          </section>
          <section
            className="savings-section popout-modal popout-modal-savings"
            style={{ display: !!sos ? "none" : "" }}
          >
            <h6
              className="big-header"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              Savings
            </h6>
            <p
              style={{
                whiteSpace: "pre-line",
                textAlign: "left",
              }}
            >
              {getTierNum(numsToUse, beforeSavings) != 3
                ? `The more you send, the higher savings you'll unlock. Send ${numeral(
                    numsToUse[getTierNum(numsToUse, beforeSavings) + 1]?.min -
                      beforeSavings,
                  ).format("$0,0[.]00")} more to unlock ${
                    numsToUse[getTierNum(numsToUse, beforeSavings) + 1]?.percent
                  }% savings.`
                : "You've reached the highest savings tier!"}
            </p>
            <div className="progress-bar-container">
              <ProgressBar percent={percent} filledBackground={primary}>
                <Step>
                  {({ accomplished }) =>
                    amount > 0 || !amount ? (
                      <>
                        <Accomplished position="start" primary={primary} />
                      </>
                    ) : (
                      <Unaccomplished position="start" />
                    )
                  }
                </Step>
                <Step>
                  {({ accomplished }) =>
                    accomplished ? (
                      <Accomplished primary={primary} />
                    ) : (
                      <Unaccomplished />
                    )
                  }
                </Step>
                <Step>
                  {({ accomplished }) =>
                    accomplished ? (
                      <Accomplished primary={primary} />
                    ) : (
                      <Unaccomplished />
                    )
                  }
                </Step>
                <Step>
                  {({ accomplished }) =>
                    accomplished ? (
                      <>
                        <Accomplished primary={primary} position="end" />
                      </>
                    ) : (
                      <Unaccomplished position="end" />
                    )
                  }
                </Step>
              </ProgressBar>

              <div
                className="checkmark-container"
                style={{
                  zIndex: "101",
                  position: "relative",
                  margin: "17px",
                  top: "-45px",
                }}
              >
                <div style={{ position: "absolute", left: "0%" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "16px",
                      color: "white",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "33.3333%",
                    display:
                      curPercent < Number(numsToUse[1].percent) ? "none" : "",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "16px",
                      color: "white",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "66.6666%",
                    display:
                      curPercent < Number(numsToUse[2].percent) ? "none" : "",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "16px",
                      color: "white",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "100%",
                    display:
                      curPercent < Number(numsToUse[2].percent) ? "none" : "",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "16px",
                      color: "white",
                      transform: "translateX(-50%)",
                    }}
                  />
                </div>
              </div>

              <div className="tier-row" style={{ padding: "0px" }}>
                <div
                  className="flex-col mobile-text-resize"
                  style={{ textAlign: "left" }}
                >
                  <h4>
                    {numsToUse[0].percent}%
                    <span className="mobile-text-hide"> Savings</span>
                  </h4>

                  <span className="rethink purchase-details">
                    <span className="mobile-text-hide">
                      Less than {numeral(numsToUse[1].min).format("$0,0[.]00")}
                    </span>
                    <span className="mobile-text-show">
                      {" "}
                      &lt;${Math.round(Number(numsToUse[1].min) / 1000)}K{" "}
                    </span>
                  </span>
                </div>
                <div className="flex-col mobile-text-resize">
                  <h4>
                    {Number(numsToUse[1].percent)}%
                    <span className="mobile-text-hide"> Savings</span>
                  </h4>

                  <span className="rethink purchase-details middle">
                    <span className="mobile-text-hide">
                      {numeral(numsToUse[1].min).format("$0,0[.]00")} -{" "}
                      {numeral(numsToUse[1].max).format("$0,0[.]00")}
                    </span>
                    <span className="mobile-text-show">
                      ${Math.round(Number(numsToUse[1].min) / 1000)}K-$
                      {Math.round(Number(numsToUse[1].max) / 1000)}K
                    </span>
                  </span>
                </div>
                <div className="flex-col mobile-text-resize">
                  <h4>
                    {Number(numsToUse[2].percent)}%
                    <span className="mobile-text-hide"> Savings</span>
                  </h4>
                  <span className="rethink purchase-details middle">
                    <span className="mobile-text-hide">
                      {numeral(numsToUse[2].min).format("$0,0[.]00")} -{" "}
                      {numeral(numsToUse[2].max).format("$0,0[.]00")}
                    </span>
                    <span className="mobile-text-show">
                      ${Math.round(Number(numsToUse[2].min) / 1000)}K-$
                      {Math.round(Number(numsToUse[2].max) / 1000)}K
                    </span>
                  </span>
                </div>
                <div
                  className="flex-col mobile-text-resize"
                  style={{ textAlign: "right" }}
                >
                  <h4>
                    {Number(numsToUse[3].percent)}%
                    <span className="mobile-text-hide"> Savings</span>
                  </h4>
                  <span className="rethink purchase-details right">
                    <span className="mobile-text-hide">
                      {numeral(numsToUse[3].min).format("$0,0[.]00")} +
                    </span>
                    <span className="mobile-text-show">
                      ${Math.round(Number(numsToUse[3].min) / 1000)}K+
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <hr className="savings-hr" />

            <div className="additional-bonus-container">
              <div className="savings-container">
                <div>Subtotal (Gift Value)</div>
                <div>Savings </div>
                <h2 className="subtotal-gift-value">
                  {numeral(beforeSavings).format("$0,0.00")}
                </h2>
                <h2 className="savings" style={{ color: primary }}>
                  {beforeSavings - afterSavings > 0 ? "-" : ""}
                  {numeral(beforeSavings - afterSavings).format("$0,0.00")}
                </h2>
              </div>
              <div>Total Due</div>
              <h2 className="total-due">
                {numeral(afterSavings).format("$0,0.00")}
              </h2>
            </div>

            <hr className="savings-hr" />
            <a
              href={
                (!window.location.origin.includes("://app.")
                  ? window.location.origin
                  : window.location.origin.replace("://app.", "://business.")) +
                `/business/checkout/?spend=${amount}` +
                (!!qString ? `&${qString}` : "")
              }
              style={{ color: "inherit" }}
            >
              <button style={{ backgroundColor: primary, color: buttonColor }}>
                Start your order
              </button>
            </a>
          </section>
        </div>
      </figure>
    </div>
  );
};

export default SavingsCalculator;

const Unaccomplished = ({ position }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          border: "1px solid #e7e7e7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "28px",
            height: "28px",
            borderRadius: "50%",
            border: "none",
          }}
        />
        <div
          style={{
            backgroundColor: "white",
            height: "20px",
            width: "8px",
            position: "absolute",
            right: "36px",
            borderTop: "1px solid #e7e7e7",
            borderBottom: "1px solid #e7e7e7",
            display: position === "start" ? "none" : "",
          }}
        />
        <div
          style={{
            backgroundColor: "white",
            height: "20px",
            width: "8px",
            position: "absolute",
            left: "36px",
            borderTop: "1px solid #e7e7e7",
            borderBottom: "1px solid #e7e7e7",
            display: position === "end" ? "none" : "",
          }}
        />
      </div>
    </>
  );
};
const Accomplished = ({ position, primary }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        border: "1px solid #e7e7e7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: primary,
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          border: "1px solid #e7e7e7",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          height: "20px",
          width: "8px",
          position: "absolute",
          right: "36px",
          borderTop: "1px solid #e7e7e7",
          borderBottom: "1px solid #e7e7e7",
          display: position === "start" ? "none" : "",
        }}
      />
      <div
        style={{
          backgroundColor: "white",
          height: "20px",
          width: "8px",
          position: "absolute",
          left: "36px",
          borderTop: "1px solid #e7e7e7",
          borderBottom: "1px solid #e7e7e7",
          display: position === "end" ? "none" : "",
        }}
      />
    </div>
  );
};
