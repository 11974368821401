import React from "react";
import useDeviceType from "../../hooks/useDeviceType";
import inKindLogo from "./images/inkind-logo.svg";
import { getAppStoreUrl } from "./PaymentMethod";

type SuccessProps = {
  goToReceipt: () => void;
  rewardsAmount: number | string;
  isOptIn: boolean;
  locationsCount: number;
};

export const Success: React.FC<SuccessProps> = ({
  goToReceipt,
  rewardsAmount,
  isOptIn,
  locationsCount,
}) => {
  const formattedRewardsAmount = (+rewardsAmount).toFixed(2);

  return (
    <div className={`success ${isOptIn ? "opt-in" : ""}`}>
      <div className={`success__header ${isOptIn ? "opt-in" : ""}`}>
        <div className="success__check-icon"></div>
        <h2 className="success__title">Payment Successful</h2>
        <div className="success__sub-title">
          Please let your server know you’ve paid with inKind.
        </div>
      </div>
      <div className="success__buttons">
        {isOptIn && (
          <button className="success__action-button success__reward-button">
            <span className="success__reward-icon"></span>
            <span>
              You earned <span className="bold">${formattedRewardsAmount}</span>{" "}
              Back
            </span>
          </button>
        )}
        <button
          className="success__action-button success__view-receipt-button"
          onClick={goToReceipt}
        >
          <span className="success__receipt-icon receipt-icon"></span>
          <span>View Receipt</span>
        </button>
      </div>
      {isOptIn && (
        <div className="success__bottom">
          <img src={inKindLogo} className="inkind-logo" alt="inkind-logo" />
          <span className="rewards-amount">${formattedRewardsAmount}</span>
          <span className="rewards-title">New Rewards Balance</span>
          <span className="rewards-sub-title">
            Spend at {locationsCount}+ restaurants
          </span>
          <a className="success__download-button" href={getAppStoreUrl()}>
            Download inKind
          </a>
        </div>
      )}
    </div>
  );
};
