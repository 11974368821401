import React, { useState, useEffect } from "react";
import CardForm from "@/shared/stripe/card-form";
import ConditionalRender from "@/shared/conditional-render";
import styled from "@emotion/styled";
import FormSelect from "@/shared/forms/form-select";
import HelpText from "@/shared/help-text";
import { Field, Form } from "formik";
import { css } from "@emotion/css";

const PaymentOptions = ({
  field,
  form: { touched, errors, setFieldValue },
  title,
  name,
  className,
  labelClassName,
  fieldClassName,
  inValidClassName,
  errorClassName,
  defaultValue,
  project,
  ticket,
  onChange,
  ...props
}) => {
  const [newCardMode, setNewCardMode] = useState(false);
  const options =
    project.credit_cards.map((c) => {
      return {
        value: c.id,
        label: `${c.brand} ${c.last_four} - Exp. ${c.exp_month}-${c.exp_year}`,
      };
    }) || [];
  const hasLinkedCards = options.length > 0;

  useEffect(() => {
    if (field.value == "" && options[0]) {
      setFieldValue(field.name, options[0]);
    } else {
      setNewCardMode(true);
    }
  }, []);

  return (
    <>
      <ConditionalRender shouldRender={!newCardMode}>
        <Field
          {...field}
          {...props}
          onChange={onChange}
          name={field.name}
          title={title ? title : "Card"}
          component={FormSelect}
          defaultValue={defaultValue}
          value={field.value}
          options={options}
          isSearchable={false}
          errors={errors}
          touched={touched}
          className={css`
            margin-bottom: 0;
          `}
        />
        <HelpText>
          Want to pay on a different card?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setNewCardMode(true);
            }}
          >
            Add New Payment Method
          </a>
        </HelpText>
      </ConditionalRender>

      <ConditionalRender shouldRender={newCardMode}>
        <CardForm error={errors[field.name] ? errors[field.name] : false} />
        <ConditionalRender shouldRender={hasLinkedCards}>
          <HelpText>
            Want to pay on a saved card?{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setNewCardMode(false);
              }}
            >
              Choose Payment Card
            </a>
          </HelpText>
        </ConditionalRender>
      </ConditionalRender>
    </>
  );
};

export default PaymentOptions;
