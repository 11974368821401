import React from "react";
import Icon from "@/shared/icon";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import Loader from "@/shared/loader";

const Total = styled.div`
  background-color: var(--color-light-gray);
  color: #a1a1a1;
  font-family: var(--font-base);
  font-size: 1.125rem;
  text-align: center;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  strong {
    font-family: var(--font-bold);
    margin-left: 0.5rem;
    &.offer-balance {
      color: #1dc195;
      font-family: "Rethink-Sans-Bold";
    }
  }
`;

const CurrentCredit = ({ className, project, ticket, offer }) => {
  return (
    <Total className={className}>
      <Icon
        name="house-money"
        color="#A1A1A1"
        size="24"
        className={css`
          margin-right: 0.5rem;
        `}
      />
      <span>Available Balance:</span>
      <strong>${Number(project?.balance).toFixed(2)}</strong>{" "}
      {offer?.claim_details?.balance > 0 && (
        <strong
          className={css`
            color: #1dc195;
          `}
        >
          + ${Number(offer?.claim_details?.balance).toFixed(2)}
        </strong>
      )}{" "}
      {!project && (
        <Loader
          color="#a1a1a1"
          style="dots"
          className={css`
            margin-left: 1rem;
          `}
          size="20"
        />
      )}
    </Total>
  );
};

export default CurrentCredit;
