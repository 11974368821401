import React, { Suspense, lazy, useEffect } from "react";
import { LocationData, ProjectData, TicketData } from "./type";
import CheckNotFound from "./CheckNotFound";
import SingleBill from "./SingleBill";
import { Splash } from "./Splash";
const MultipleBills = lazy(() => import("./MultipleBills"));

type WebRedemptionProps = {
  posSlug: string;
  location: string;
  projectInit?: ProjectData;
  ticketInit?: TicketData[];
  locationInit?: LocationData[];
  ticket: string;
  appStoreUrl: string;
  playStoreUrl: string;
  locationsCount: number;
};

const WebRedemption: React.FC<WebRedemptionProps> = ({
  projectInit,
  locationInit,
  ticketInit,
  ticket,
  locationsCount,
  posSlug,
  location,
}) => {
  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const logoUrl = projectInit?.logo_path;
  const validTickets = ticketInit?.filter((ticket) => ticket.bill_amount > 0);

  return (
    <main className="web-redemption">
      {!validTickets?.length && <CheckNotFound logoSrc={logoUrl} />}
      {validTickets?.length === 1 && (
        <SingleBill
          projectInit={projectInit}
          locationInit={locationInit}
          ticketInit={validTickets?.[0]}
          ticket={ticket}
          locationsCount={locationsCount}
          posSlug={posSlug}
          location={location}
        />
      )}
      {!!validTickets?.length && validTickets?.length > 1 && (
        <Suspense fallback={<Splash logoSrc={logoUrl} />}>
          <MultipleBills ticketDataArr={validTickets} />
        </Suspense>
      )}
    </main>
  );
};

export default WebRedemption;
