import React from "react";
import AppStore from "../../../../assets/images/checkout/AppleStore.svg";
import OpeninKindAppButton from "../../../../assets/images/checkout/OpeninKindAppButton.svg";
import PlayStore from "../../../../assets/images/checkout/PlayStore.svg";
import qrCode from "../../../../assets/images/icons/qr-code.jpg";
import { os } from "../../../lib/helpers.js";
import { useSelector } from "react-redux";

const GetOurApp = () => {
  const isSubscription = useSelector((state) => state.nav.subscription);
  const [userOS, setUserOS] = React.useState(os());
  const isAppInstalled = useSelector((state) => state.user.is_app_installed);
  const navGift = useSelector((state) => state.nav.gift);
  const headerText = navGift
    ? "It pays to give"
    : isAppInstalled
      ? "Open inKind to spend your balance"
      : "Download inKind to spend your balance";
  return (
    <div className="get-app-col">
      <div className="checkout-head">
        {!isSubscription ? headerText : "Download inKind to start earning"}
      </div>
      <div className="checkout-base-font">
        {!isSubscription
          ? navGift
            ? "The gift recipient can use the inKind app to manage and spend your gift as well as gain access to unique promotions."
            : "You'll use the inKind app to manage and spend your balance as well as gain access to unique promotions."
          : "You’ll use inKind to pay for your bill in-store and for pick-up orders as well as gain access to unique promotions."}
      </div>
      {!isSubscription ? (
        isAppInstalled ? (
          <div className="badge-row">
            <a href="https://app.inkind.com/">
              <img
                src={OpeninKindAppButton}
                className="confirm-app-badge first"
                alt="Inkins app"
                style={{ maxWidth: "100%" }}
              />
            </a>
          </div>
        ) : (
          <div className="badge-row">
            <a
              href="https://apps.apple.com/us/app/inkind/id1153771822"
              style={{ display: userOS !== "android" ? "block" : "none" }}
            >
              <img
                src={AppStore}
                className="confirm-app-badge first"
                alt="App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.inkind.inkind&hl=en_US&gl=US"
              style={{ display: userOS !== "ios" ? "block" : "none" }}
            >
              <img
                src={PlayStore}
                className="confirm-app-badge"
                alt="Play Store"
              />
            </a>
          </div>
        )
      ) : (
        <>
          <div
            className="sub-check__qr-container"
            style={{ display: userOS === "desktop" ? "flex" : "none" }}
          >
            <img className="sub-check__qr" src={qrCode} />
            <div className="sub-check__qr-footer">
              Scan QR code to install the inKind App
            </div>
          </div>
          <div className="badge-row sub-check__badge-row">
            <a
              href="https://apps.apple.com/us/app/inkind/id1153771822"
              style={{ display: userOS !== "android" ? "block" : "none" }}
            >
              <img
                src={AppStore}
                className="confirm-app-badge first"
                alt="App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.inkind.inkind&hl=en_US&gl=US"
              style={{ display: userOS !== "ios" ? "block" : "none" }}
            >
              <img
                src={PlayStore}
                className="confirm-app-badge"
                alt="Play Store"
              />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default GetOurApp;
