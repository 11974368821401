import React, { useEffect, useRef, useState } from "react";
import { LineItem, Modifier, TicketData } from "./type";
import { calcTotalQuantity } from "./Receipt";

const flattenModifiers = (modifiers: Modifier[]): Modifier[] => {
  if (!modifiers || modifiers.length === 0) {
    return [];
  }
  return modifiers.reduce<Modifier[]>((acc, modifier) => {
    acc.push(modifier);

    if (modifier.modifiers && modifier.modifiers.length > 0) {
      acc.push(...flattenModifiers(modifier.modifiers));
    }
    return acc;
  }, []);
};

const processLineItems = (lineItems: LineItem[]): LineItem[] => {
  return lineItems.map((lineItem) => ({
    ...lineItem,
    modifiers: flattenModifiers(lineItem.modifiers),
  }));
};

export const generateBillDetails = (ticketData: TicketData) => {
  const serviceCharges = ticketData?.service_charges?.reduce(
    (acc, charge) => {
      acc[charge.label] = charge.amount;
      return acc;
    },
    {} as { [label: string]: number },
  );

  const billDetails = {
    Subtotal: ticketData?.sub_total || 0,
    Taxes: ticketData?.tax_total || 0,
    ...serviceCharges,
  };

  return billDetails;
};

type ViewOrderProps = {
  ticketData: TicketData;
  hideViewOrder: () => void;
  continuePayment?: () => void;
};

export const ItemsList: React.FC<{ items: LineItem[] }> = ({ items }) => {
  const processedLineItems = processLineItems(items);
  return (
    <ul className="items-list">
      {processedLineItems.map((item) => {
        const { name, quantity, price, modifiers, pos_id: id } = item;
        return (
          <li className="items-list__item" key={id}>
            <div className="items-list__item-main">
              <div className="items-list__item-left">
                <span className="items-list__quantity">{quantity}</span>
                <span className="items-list__name">{name}</span>
              </div>
              <div className="items-list__item-right">
                {quantity > 1 && (
                  <span className="items-list__price">${price.toFixed(2)}</span>
                )}
                <span className="items-list__total">
                  ${(price * quantity).toFixed(2)}
                </span>
              </div>
            </div>
            {modifiers.map((modifier, index) => (
              <div
                className="items-list__item__modifier"
                key={modifier.pos_id + index}
              >
                {modifier.name} ($
                {(modifier.price * modifier.quantity).toFixed(2)})
              </div>
            ))}
          </li>
        );
      })}
    </ul>
  );
};

export const ViewOrder: React.FC<ViewOrderProps> = ({
  ticketData,
  hideViewOrder,
  continuePayment,
}) => {
  const orderSummaryRef = useRef<HTMLDivElement>(null);
  const [orderSummaryHeight, setOrderSummaryHeight] = useState(0);
  useEffect(() => {
    if (orderSummaryRef.current) {
      setOrderSummaryHeight(orderSummaryRef.current.offsetHeight);
    }
  }, [ticketData]);

  const billDetails = {
    ...generateBillDetails(ticketData),
    "Bill Total": ticketData?.bill_total || 0,
  };
  const lineItems = ticketData?.line_items || [];
  const totalQuantity = calcTotalQuantity(lineItems);
  return (
    <div className="view-order">
      <div className="view-order-content">
        <div className="view-order__items-container">
          <div className="view-order__header">
            <h2 className="view-order__title">
              Your order ({totalQuantity} Item
              {totalQuantity > 1 ? "s" : ""})
            </h2>
            <button
              className="view-order__close-button"
              onClick={hideViewOrder}
            ></button>
          </div>
          <div
            className="view-order__items-list"
            style={{
              height: `calc(100dvh - 17rem - ${orderSummaryHeight}px)`,
            }}
          >
            <ItemsList items={lineItems} />
          </div>
        </div>
        <div className="view-order__button-container" ref={orderSummaryRef}>
          <div className="divider"></div>
          <div className="view-order__total-due">
            {Object.entries(billDetails).map(([key, value]) => {
              return (
                <div className="view-order__total-row" key={key}>
                  <span>{key}</span>
                  <span>${value.toFixed(2)}</span>
                </div>
              );
            })}
          </div>
          <button
            className="view-order__action-button"
            onClick={() => {
              if (!continuePayment) {
                hideViewOrder();
              } else {
                continuePayment?.();
              }
            }}
          >
            Continue to payment
          </button>
        </div>
      </div>
    </div>
  );
};
