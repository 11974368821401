import React from "react";
import { css } from "@emotion/css";

const FullButton = ({ disabled, children, ...rest }) => {
  const fullButtonStyle = css`
    background-color: var(--color-primary);
    border: 0;
    padding: 2rem 0;
    width: 100%;
    display: block;
    color: black;
    font-family: var(--font-bold);
    font-size: 1.125rem;
    height: 89px;

    &:focus {
      outline: none;
    }

    &:disabled {
      color: #d1d1d1;
      background-color: var(--color-light-gray);
    }
  `;

  return (
    <button
      data-test-id="submit-button"
      type="submit"
      className={fullButtonStyle}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default FullButton;
