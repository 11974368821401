import React, { lazy, Suspense } from "react";
import { MinusIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "../redux/slices/companySlice";
import { fetchUserByToken } from "../redux/slices/userSlice";
import { HashRouter as Router, Route, Routes, Switch } from "react-router-dom";
import NavBar from "./NavBar";
import { setGiftAmount, setTiers } from "../redux/slices/massGiftSlice";
import queryString from "query-string";
const Dashboard = lazy(() => import("./Dashboard"));
const Entry = lazy(() => import("./Entry"));
const CreateGift = lazy(() => import("./CreateGift"));
const Redemptions = lazy(() => import("./Redemptions"));

const MassGifting = ({
  presenter,
  cloudinaryData,
  bulkGiftTiers,
  environment,
}) => {
  const dispatch = useDispatch();
  const spend = queryString.parse(location.search);
  let giftAmount = Number(spend && spend?.spend?.replace(/\D\./g, ""));
  const tiersUse = bulkGiftTiers?.bulk_gift_tiers;
  React.useEffect(() => {
    if (tiersUse.length === 4) {
      let numsToUse = [
        {
          percent:
            presenter?.country_code === "NZ"
              ? 0
              : Number(tiersUse[0].discount) * 100,
          min: null,
          max: Number(tiersUse[0].max),
        },
        {
          percent:
            presenter?.country_code === "NZ"
              ? 0
              : Number(tiersUse[1].discount) * 100,
          min: Number(tiersUse[1].min),
          max: Number(tiersUse[1].max),
        },
        {
          percent:
            presenter?.country_code === "NZ"
              ? 0
              : Number(tiersUse[2].discount) * 100,
          min: Number(tiersUse[2].min),
          max: Number(tiersUse[2].max),
        },
        {
          percent:
            presenter?.country_code === "NZ"
              ? 0
              : Number(tiersUse[3].discount) * 100,
          min: Number(tiersUse[3].min),
          max: null,
        },
      ];
      dispatch(setTiers(numsToUse));
    }
    if (giftAmount) {
      dispatch(setGiftAmount(giftAmount));
    }
    dispatch(setCompany(presenter));
    dispatch(fetchUserByToken());
  }, []);

  const user = useSelector((state) => state.user);
  const isSos = presenter.country_code === "NZ";
  return user.loadingUser == "pending" ? (
    <div className="marcus-mode ">
      <MinusIcon className="loading-spinner" />
    </div>
  ) : (
    <>
      <Router>
        <NavBar isSos={isSos} />
        <Suspense
          fallback={
            <div className="marcus-mode ">
              <MinusIcon className="loading-spinner" />
            </div>
          }
        >
          <Routes>
            <Route
              exact="exact"
              path="/"
              element={
                <CreateGift
                  isSos={isSos}
                  cloudinaryData={cloudinaryData}
                  environment={environment}
                />
              }
            />
            <Route exact="exact" path="/login" element={<Entry />} />
            <Route exact="exact" path="/dashboard" element={<Dashboard />} />
            <Route
              exact="exact"
              path="/dashboard/redemptions/:id"
              element={<Redemptions />}
            />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default MassGifting;
