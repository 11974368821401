import React, { useState, useEffect } from "react";
import SquareDollarSign from "../../../assets/images/icons/square-dollar-solid.svg";
import ArrowDropDown from "../../../assets/images/icons/arrow_drop_down_black_24dp.svg";
import numeral from "numeral";
import { convertToUtc, addDays } from "../../helpers/dateHelper";

const Dropdown = ({
  options,
  offerAmount,
  offerVal,
  handleOfferChange,
  grandTotal,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenContent = (toSet) => {
    if (typeof toSet !== "undefined") {
      setOpen(toSet);
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  document.addEventListener("click", (e) => {
    if (!e.target.closest(".dropdown-outer")) {
      setOpen(false);
    }
  });
  const filteredOfferList = options.filter(
    (item) =>
      item.expiration?.mode === "no_expiration" ||
      new Date(item.expiration?.date).getTime() > new Date().getTime() ||
      new Date(item.claim_details?.expires_at).getTime() > new Date().getTime(),
  );
  return (
    <>
      {filteredOfferList.length > 0 && (
        <div className="dropdown-outer">
          <div className="dropdown-container">
            <div className="dropdown-btn" onClick={() => handleOpenContent()}>
              <div className="dropdown-select">
                <div>
                  {offerVal === "Don't apply any offer" ? (
                    <span className="dropdown-offer-title">{offerVal}</span>
                  ) : offerVal === "Select one..." ? (
                    <span className="dropdown-offer-title">{offerVal}</span>
                  ) : (
                    <div className="dropdown-offer-details">
                      <img
                        src={SquareDollarSign}
                        className="dropdown-offer-dollarsign-img"
                      />
                      <div>
                        <span className="dropdown-apply-offer-label">
                          Apply Offer
                        </span>
                        <div>
                          <span className="dropdown-offer-title">
                            {offerVal}
                          </span>
                          <span className="dropdown-offer-amount-selected">
                            (-{numeral(offerAmount).format("$0,0.00")})
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <img src={ArrowDropDown} />
              </div>
            </div>
            {open && (
              <div className="dropdown-content">
                {filteredOfferList.map((item) => {
                  const lessThanMin = item.minimum_spend_amount > grandTotal;
                  return (
                    <div
                      className={`dropdown-title-container ${
                        lessThanMin ? "min-not-met" : ""
                      }`}
                      key={item.name}
                      onClick={() => {
                        if (!!lessThanMin) {
                          return;
                        }
                        handleOfferChange(
                          item.name,
                          item?.claim_details?.balance,
                          item,
                        );
                        handleOpenContent(false);
                      }}
                    >
                      <div className="dropdown-offer-info">
                        <span className="dropdown-offer-title">
                          {item.name}
                        </span>
                        <span className="dropdown-offer-amount">
                          -
                          {numeral(item?.claim_details?.balance).format(
                            "$0,0.00",
                          )}
                        </span>
                      </div>
                      {!!lessThanMin ? (
                        <div className="dropdown-expire-label">
                          <span>
                            Minimum spend not met ( -
                            {numeral(item.minimum_spend_amount).format(
                              "$0,0.00",
                            )}
                            )
                          </span>
                        </div>
                      ) : item.expiration?.date ? (
                        <div className="dropdown-expire-label">
                          <span>Expires</span>{" "}
                          {convertToUtc(item.expiration.date)}
                        </div>
                      ) : item.expiration?.days ? (
                        <div className="dropdown-expire-label">
                          <span>Expires</span>{" "}
                          {convertToUtc(item.claim_details?.expires_at)}
                        </div>
                      ) : (
                        <div className="dropdown-expire-label">
                          <span>No expiration</span>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div
                  className="not-to-apply  dropdown-apply-border"
                  onClick={() => {
                    handleOfferChange("Don't apply any offer");
                    handleOpenContent(false);
                  }}
                >
                  <span className="dropdown-offer-title">
                    Don't apply any offer
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
