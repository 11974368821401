import React, { useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import MessageWrapper from "@/shared/message-wrapper";
import FullButton from "@/shared/forms/form-fullButton";
import ScrollContent from "@/shared/scroll-content";
import FixedContent from "@/shared/fixed-content";

const CheckoutFailed = ({ onComplete, onGoBack, errorMsg }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <ScrollContent>
        <MessageWrapper
          className={css`
            text-align: center;
          `}
        >
          <img
            className={css`
              margin-top: 65px;
              margin-bottom: 27px;
            `}
            src="/assets/checkout/Payment_Error.svg"
          />
          <h3>Couldn’t Process Payment</h3>
          <p>
            {errorMsg ||
              "If the problem continues, you can ask a manager to close out your bill."}
          </p>
        </MessageWrapper>
      </ScrollContent>
      <FixedContent>
        <FullButton onClick={onGoBack}>Back</FullButton>
      </FixedContent>
    </>
  );
};

export default CheckoutFailed;
