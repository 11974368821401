import React, { useState, useEffect, useUpdateEffect } from "react";
import PropTypes from "prop-types";
import useFetch from "@lib/use-fetch";
import axios from "@lib/axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormRadio from "@/shared/forms/form-radio";
import FullButton from "@/shared/forms/form-fullButton";
import FullButtonCheckout from "@/shared/forms/form-fullButtonCheckout";
import ContentWrapper from "@/shared/content-wrapper";
import HeaderContent from "@/shared/header-content";
import ScrollContent from "@/shared/scroll-content";
import FixedContent from "@/shared/fixed-content";
import CardForm from "@/shared/stripe/card-form";
import ServerErrors from "@/shared/forms/server-errors";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentOptions from "@/shared/payment-options";
import StripeHelpers, { prepareStripeCard } from "@lib/stripe-helpers";
import mediaQuery from "@lib/mediaQueries";
import { currency, getCardDetails } from "@lib/helpers";
import Loader from "@/shared/loader";

const CheckoutAddCredit = ({
  axiosHeaders,
  posSlug,
  location,
  project,
  ticket,
  onSubmit,
  onError,
  onComplete,
  onPaymentFailed,
  isCardNeeded,
  setPaymentMethod,
  offer,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { prepareStripeCard, sendPayment, purchaseGiftCard } = StripeHelpers({
    axiosHeaders,
    project,
    ticket,
  });
  const [nextDisabled, setNextDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Next");
  const [buttonColor, setButtonColor] = useState("--color-primary");
  const [submit, setSubmit] = useState(false);
  const difference = offer
    ? ticket.bill_amount -
      Number(project?.balance) -
      offer?.claim_details?.balance
    : ticket.bill_amount - Number(project?.balance);
  const calculateSavings = (paid, received) => {
    return Math.round((received / paid) * 100 - 100);
  };
  useEffect(() => {
    if (!submit) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [submit]);

  return (
    <Formik
      initialValues={{
        giftCardOptionId: 0,
        paymentMethod: "",
      }}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setFieldError, setFieldTouched },
      ) => {
        onSubmit();
        setSubmitting(true);
        setSubmit(true);

        const { card, cardId, cardErrors } = await prepareStripeCard({
          values,
          setFieldTouched,
          setFieldError,
          field: "paymentMethod",
          cardElement: elements.getElement(CardElement),
        });

        // We've had an error of some kind
        if (!cardId || cardErrors.length > 0) {
          /*document.querySelector("#scroll-content").scrollTop = Math.pow(
            10,
            10
          );*/

          setSubmitting(false);
          setSubmit(false);
          onPaymentFailed(cardErrors);
          return;
        }

        // No gift card, just cover the balance
        if (values.giftCardOptionId == 0) {
          // Set payment method if they're not purchasing a gift card
          setSubmitting(false);
          setSubmit(false);
          setPaymentMethod(getCardDetails(cardId, project, card));
          return onComplete(false);
        }

        // We're purchasing a gift card
        const { payment, paymentErrors } = await purchaseGiftCard({
          cardId,
          optionId: values.giftCardOptionId,
        });
        if (!paymentErrors) {
          setSubmitting(false);
          setSubmit(false);
          return onComplete(true);
        } else {
          setSubmitting(false);
          setSubmit(false);
          setFieldTouched("paymentMethod");
          onPaymentFailed(paymentErrors);
          return;
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        isValid,
        form,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentWrapper>
            <HeaderContent
              displayCurrentCredit
              project={project}
              ticket={ticket}
              offer={offer}
            >
              <h1>Add Credit</h1>
            </HeaderContent>
            <ScrollContent>
              <p
                className={css`
                  padding-top: 1rem;
                `}
              >
                Looks like your bill ({currency(ticket.bill_amount)}) is higher
                than your remaining credit. You’ll need an additional{" "}
                <strong>{currency(difference)}</strong> added to your account:
              </p>
              <ServerErrors name="general" />
              <Field
                name="giftCardOptionId"
                value={0}
                title={
                  <span
                    className={css`
                      font-size: 0.85rem;
                      ${mediaQuery.md} {
                        font-size: 1rem;
                      }
                    `}
                  >
                    Just cover my bill today{" "}
                    <strong>(Add {currency(difference)})</strong>
                  </span>
                }
                component={FormRadio}
                checked={values.giftCardOptionId == 0}
                onClick={() => {
                  setButtonText("Next");
                  setButtonColor("--color-primary");
                }}
              />
              {project.gift_card_options
                .sort(
                  (a, b) =>
                    Number(a.amount) - Number(b.amount) ||
                    Number(a.credit_to_give) - Number(b.credit_to_give),
                )
                .filter((g) => !g.hidden)
                .map((g, i) => {
                  const label = (
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 0.85rem;
                        ${mediaQuery.md} {
                          font-size: 1rem;
                        }
                      `}
                    >
                      <span>
                        Pay ${Number(g.amount)},{" "}
                        <strong
                          className={css`
                            white-space: nowrap;
                          `}
                        >
                          Get ${g.credit_to_give}
                        </strong>
                      </span>
                      <em
                        className={css`
                          display: inline-block;
                          background-color: var(--color-green);
                          color: white;
                          font-style: normal;
                          padding: 0.7em;
                          border-radius: 1.7em;
                          line-height: 1;
                          text-align: center;
                          margin: -5px 0px -5px 5px;
                          font-size: 0.7rem;
                          font-weight: bold;
                        `}
                      >
                        {calculateSavings(
                          Number(g.amount),
                          Number(g.credit_to_give),
                        )}
                        % Bonus
                      </em>
                    </div>
                  );
                  return (
                    <Field
                      key={i}
                      name="giftCardOptionId"
                      value={g.id}
                      title={label}
                      component={FormRadio}
                      checked={values.giftCardOptionId == g.id}
                      onClick={() => {
                        setButtonText("Purchase");
                        setButtonColor("--color-green");
                      }}
                    />
                  );
                })}
              <h4>Payment method</h4>
              <Field
                name="paymentMethod"
                component={PaymentOptions}
                project={project}
                ticket={ticket}
                onChange={(val) => {
                  setFieldValue("paymentMethod", val);
                }}
              />
              <div
                className="loaderContainer"
                className={css`
                  display: ${!!submit ? "block" : "none"};
                `}
              >
                <Loader
                  className={css`
                    margin: 0 auto;
                    margin-top: 40px;
                    margin-bottom: 20px;
                  `}
                />
                <h4
                  className={css`
                    text-align: center;
                  `}
                >
                  Running your card, please wait
                </h4>
              </div>
            </ScrollContent>
            <FixedContent>
              <FullButtonCheckout
                type="submit"
                disabled={!stripe || nextDisabled || submit}
                color={buttonColor}
              >
                {buttonText}
              </FullButtonCheckout>
            </FixedContent>
          </ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

CheckoutAddCredit.propTypes = {
  axiosHeaders: PropTypes.object,
  posSlug: PropTypes.string,
  location: PropTypes.string,
  project: PropTypes.object,
  ticket: PropTypes.object,
  onComplete: PropTypes.func,
};

CheckoutAddCredit.defaultProps = {
  onSubmit: () => {},
  onError: () => {},
  onComplete: () => {},
};

export default CheckoutAddCredit;
