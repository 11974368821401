import React from "react";

interface TipConfirmationModalProps {
  isOpen: boolean;
  tipAmount: number;
  tipCalculationBase: number;
  autoGrat: number;
  closeModal: () => void;
  continuePayment: () => void;
}

const TipConfirmationModal: React.FC<TipConfirmationModalProps> = ({
  isOpen,
  tipAmount,
  tipCalculationBase,
  autoGrat,
  closeModal,
  continuePayment,
}) => {
  if (!isOpen) return null;
  const tipPercentage = Math.round(
    ((tipAmount + autoGrat) / tipCalculationBase) * 100,
  );

  return (
    <div className="tip-confirmation-modal__overlay">
      <div className="tip-confirmation-modal">
        <div className="tip-confirmation-modal__image" />
        <h2 className="tip-confirmation-modal__title">Tip Confirmation</h2>
        <p className="tip-confirmation-modal__message">
          You've entered a tip of ${tipAmount.toFixed(2)} which is{" "}
          {tipPercentage}% of your total bill
          {autoGrat > 0 ? " including any auto-gratuity" : ""}. If this is
          intended, we appreciate your generosity, but please confirm that this
          is the correct tip you intended to include.
        </p>
        <button
          className="tip-confirmation-modal__button review-transaction"
          onClick={closeModal}
        >
          Review Transaction
        </button>
        <button
          className="tip-confirmation-modal__button"
          onClick={() => {
            closeModal();
            continuePayment();
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default TipConfirmationModal;
