import React, { useId } from "react";
import { cleanClassNames } from "../../../helpers/classNames";
import "./style.scss";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string | null;
  showLabel?: boolean;
  isRequired?: boolean;
};

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  error = null,
  showLabel = true,
  disabled = false,
  className = "",
  isRequired = false,
  ...rest
}) => {
  const id = useId();

  return (
    <div className={cleanClassNames("ui-input", className)}>
      {showLabel && (
        <label htmlFor={id} className="ui-input__label">
          {label}
          <sup>{isRequired ? "*" : ""}</sup>
        </label>
      )}
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        aria-describedby={error ? `${id}-error` : undefined}
        aria-invalid={!!error}
        className={`ui-input__field ${error ? "ui-input__field--error" : ""}`}
        {...rest}
      />
      {error && (
        <span
          id={`${id}-error`}
          className="ui-input__error-message"
          role="alert"
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
