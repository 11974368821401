import React from "react";
import emailLogo from "../../../assets/images/icons/email_icon.svg";
import { getDateTime, getOrdinal } from "../../helpers/formatDateTime";

const ApprovedView = ({ setSentState, startDate, sendTime }) => {
  const dateUse = startDate.split("T")[0] + "T12:00:00";
  return (
    <div className="email__review--section email__review--approved--section">
      <img className="email__review--approved--logo" src={emailLogo} />
      <div>
        <div className="email__review--listheader">Campaign Approved! 👍</div>
        <div className="email__review--subtext email__review--listtext">
          This campaign is scheduled to start sending <br />
          {getDateTime(dateUse, true)}.
        </div>
      </div>
    </div>
  );
};

export default ApprovedView;
